import React from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/img/icons/close.svg';
import { ReactComponent as EmptyHexagonGrey } from 'src/assets/img/icons/hexagon-empty-grey.svg';
import { ReactComponent as EmptyHexagon } from 'src/assets/img/icons/hexagon-empty.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/img/icons/hexagon-success.svg';
import { ReactComponent as WarningGrayIcon } from 'src/assets/img/icons/hexagon-warning-gray.svg';
import { ReactComponent as WarningIcon } from 'src/assets/img/icons/hexagon-warning.svg';
import { useModal } from '../../contexts/ModalContext';

export function TransactionStatusModal({
  step = 1,
  onModalClose,
  onBack,
  type = 'Deposit',
}) {
  const { closeModal } = useModal();

  const handleClose = () => {
    if (onModalClose) {
      onModalClose();
    }
    closeModal();
  };

  const showStepTwo = step === 1;
  const showStepThree = step === 1 || step === 2;

  return (
    <div className="fixed top-0 left-0 z-20 w-full h-full animate-fadeIn bg-grey-black43">
      <div className="absolute bg-texture bg-cover bg-white dark:bg-grey-dark lg:bg-none w-full lg:w-auto h-full lg:h-auto pt-[81px] lg:pt-[40px] pb-[24px] px-[24px] top-0 lg:top-[171px] left-0 lg:left-1/2 lg:-translate-x-1/2 lg:rounded-[8px]">
        <h3 className="text-[32px] text-center text-grey-dark dark:text-white">
          Confirming the Transaction
        </h3>
        <button
          onClick={handleClose}
          className="w-[12px] h-[12px] absolute top-[49px] lg:top-[22px] right-[30px] flex justify-center items-center bg-none border-none"
        >
          <CloseIcon className="stroke-grey-black dark:stroke-grey-deep" />
        </button>
        <div className="flex flex-col mt-[29px] lg:w-[505px]">
          {step === 1 && (
            <div className="flex gap-[12px] items-center w-full max-w-[444px] h-[54px] bg-yellow-lightest-20 rounded-[8px] mx-auto px-[14px]">
              <WarningIcon className="w-[38px] h-[42px]" />
              <div className="text-yellow-dark">
                <p className="font-medium text-[16px] leading-[17.6px]">
                  Approve transaction in your wallet
                </p>
                <p className="text-[12px] leading-[15px]">
                  Please Approve Transaction from connected wallet.
                </p>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="flex gap-[12px] items-center w-full max-w-[444px] h-[54px] bg-grey-lightest-15 rounded-[8px] mx-auto px-[14px]">
              <WarningGrayIcon className="w-[38px] h-[42px]" />
              <div className="text-grey-deep">
                <p className="font-medium text-[16px] leading-[17.6px]">
                  Transaction is in progress
                </p>
                <p className="text-[12px] leading-[15px] max-w-[362px]">
                  It could take up to 10 minutes, depending on network load. Do
                  not close or refresh this page.
                </p>
              </div>
            </div>
          )}
          <div
            className={`flex justify-between ${showStepThree && 'mt-[32px]'}`}
          >
            <SuccessItem title={`Pending ${type}`} />
            {showStepTwo ? (
              <div className="flex flex-col gap-[8px] items-center w-[162px]">
                <div className="relative">
                  <EmptyHexagon className="w-[38px] h-[42px] fill-grey-dark dark:fill-grey-dark" />
                  <p className="absolute top-[12px] left-[9px] font-medium text-[16px] leading-[17.6px] text-yellow-dark">
                    02
                  </p>
                </div>
                <p className="font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
                  Confirming
                </p>
              </div>
            ) : (
              <SuccessItem title="Confirming" />
            )}
            {showStepThree ? (
              <div className="flex flex-col gap-[8px] items-center w-[162px]">
                <div className="relative">
                  <EmptyHexagonGrey className="w-[38px] h-[42px] fill-white dark:fill-grey-dark" />
                  <p className="absolute top-[12px] left-[9px] font-medium text-[16px] leading-[17.6px] text-grey-deep">
                    03
                  </p>
                </div>
                <p className="font-medium text-[18px] leading-[26.28px] text-grey-deep">
                  Sending
                </p>
              </div>
            ) : (
              <SuccessItem title="Sending" />
            )}
          </div>
          {showStepThree && (
            <div className="flex justify-center">
              <p
                onClick={onBack}
                className="font-medium text-[16px] leading-[19.2px] text-yellow-dark mt-[32px] w-fit cursor-pointer"
              >
                Go back
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SuccessItem({ title }) {
  return (
    <div className="flex flex-col gap-[8px] items-center w-[162px]">
      <SuccessIcon className="w-[38px] h-[42px]" />
      <p className="font-medium text-[18px] leading-[26.28px] text-green-lighter1">
        {title}
      </p>
    </div>
  );
}
