export function PoolBadge({ title = "", icon }) {
  return (
    <div className="flex items-center h-[23px] gap-[4px] px-[8px] rounded-[4px] bg-grey-lighter9 dark:bg-grey-darker3">
      <p className="font-normal text-[14px] leading-[1.1] text-grey-deep">
        {title}
      </p>
      {icon}
    </div>
  );
}
