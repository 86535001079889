import { ReactComponent as HexagonTickSvg } from "src/assets/img/icons/hexagon-tick.svg";
import { ReactComponent as ProductMenuIcon } from "src/assets/img/icons/product-menu-icon.svg";
import axios from "axios";
import { useState } from "react";
import {WalletInput} from "../WalletInput";
import {useWallet} from 'src/contexts/WalletContext';
import {Input} from "../lib";
import {isValidEmail} from 'src/utils/helpers';
import {ClipLoader} from "react-spinners";
import { ReactComponent as WarningSvg } from "src/assets/img/icons/warning.svg";

const tips = [
  "Bonus points for the upcoming airdrop",
  "<b>3 Referral Links</b> to share for more rewards",
  "Latest product news and releases",
  "Exclusive DeFi research",
];

export function JoinModal2({ onSuccess }) {
  const [isLoading, setIsLoading] = useState(false);
  const { walletAddress } = useWallet();

  const storedWallet = localStorage.getItem("wait_list_wallet")
  const storedEmail = localStorage.getItem("wait_list_email")

  const [walletToJoin, setWalletToJoin] = useState(walletAddress || storedWallet);
  const [email, setEmail] = useState(storedEmail);
  const [walletError, setWalletError] = useState(false);

  const referralCode = window.localStorage.getItem('refCode')

  const emailError = !!email && !isValidEmail(email)
  const formError = !email || emailError || !walletToJoin || walletError

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const {data} = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/waitlist/join`, {
      email,
      walletAddress: walletToJoin,
      referralCode
    })
    const storedEmail = data['email'] || ''
    const storedWallet = data['walletAddress'] || ''
    storedEmail
      ? localStorage.setItem("wait_list_email", storedEmail)
      : localStorage.removeItem("wait_list_email")

    storedWallet
      ? localStorage.setItem("wait_list_wallet", storedWallet)
      : localStorage.removeItem("wait_list_wallet")

    setIsLoading(false);

    onSuccess?.();
  };

  return (
    <div className="fixed top-0 left-0 z-20 w-full h-full lg:overflow-y-auto bg-grey-black43 animate-fadeIn">
      <div className="max-w-[533px] h-full lg:h-auto overflow-y-auto bg-white dark:bg-grey-dark dark:text-white pt-[49px] lg:pt-[44px] pb-[32px] px-[24px] lg:px-[32px] lg:mt-[100px] lg:mb-[100px] lg:mx-auto lg:rounded-[8px]">
        <div className="flex flex-col gap-[24px]">
          <h2 className="text-[30px] leading-[1.1] text-grey-darkest dark:text-white">
            Join The Mainnet Waitlist
          </h2>
          <p className="font-normal text-[16px] leading-[1.2] tracking-[-0.5px] text-grey-deep">
            Enter the waitlist for the upcoming mainnet launch and receive:
          </p>
          <div className="flex flex-col gap-[8px]">
            {tips.map((tip) => (
              <div className="flex items-center gap-[8px]">
                <HexagonTickSvg className={`stroke-white w-[16px] h-[16px]`} />
                <p
                  className="text-[16px] leading-[1.2] tracking-[-0.5px] text-grey-dark dark:text-white"
                  dangerouslySetInnerHTML={{
                    __html: tip,
                  }}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-center gap-[8px]">
            <WalletInput
                defaultValue={walletToJoin}
                onChange={(v) => {
                  setWalletToJoin(v);
                }}
                onError={msg => setWalletError(!!msg)}
                buttonLabel="Edit"
                placeholder="Enter your wallet address"
                className={`max-w-full`}
            />
            <Input
                type="email"
                value={email}
                placeholder="Enter your email"
                autoComplete="off"
                onChange={handleEmailChange}
                className="dark:bg-transparent dark:text-white"
            />
            {
              emailError &&
                <div className="flex gap-[5.46px] mt-[8px] justify-center items-center font-normal
                  text-red-lighter uppercase text-[12px] leading-[1.25]"
                >
                  <WarningSvg/>
                  <p>
                    Please input the correct email
                  </p>
                </div>
            }
            <div className={`mt-[25px] flex flex-row justify-center`}>
              <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={formError || isLoading}
                  className={`w-[290px] bg-yellow-dark hover:bg-yellow-deep border-yellow-light text-grey-dark border-[2px] disabled:opacity-90 ${
                      formError
                          ? "disabled:bg-grey-lightest disabled:border-grey-deep disabled:text-grey-deep"
                          : "disabled:bg-transparent disabled:border-red-light disabled:text-red-light"
                  } rounded-[8px] pt-[11px] pb-[13px] pl-[17.5px] pr-[20.5px] transition-all duration-300`}
              >
                {isLoading ? (
                    <ClipLoader color="#1E1E1E" size={20}/>
                ) : (
                    <span className="font-bold text-[16px] leading-[1.1] font-caption">
                    Continue
                  </span>
                )}
              </button>
            </div>

            <div className="flex items-center justify-center gap-[8px] mt-[-6px] mb-[-8px]">
              <ProductMenuIcon/>
              <p className="font-normal text-[12px] leading-[1.25] text-grey-brown dark:text-grey-deep ml-[-8.2px]">
                You will see your portfolio after this step.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
