import { createPortal } from "react-dom";
import { ReactComponent as DiscordSvg } from "src/assets/img/icons/discord.svg";
import {LINK_DISCORD} from '../constants/links'

const BugReportButton = () => {
  const portalRoot = document.getElementsByTagName("body")[0];

  return createPortal(
    <a
      className="group fixed right-[20px] bottom-[20px] flex justify-center items-center p-[4px] gap-[0px] hover:pr-[12px] rounded-full bg-[#5865F2] transition-all duration-150 ease"
      href={LINK_DISCORD}
      target="_blank"
    >
      <DiscordSvg className="w-[38px] h-[38px] fill-white dark:fill-grey-deep" />
      <span className="font-body font-normal text-[16px] leading-[1.2] tracking-[-0.5px] text-white max-w-0 group-hover:max-w-[300px] transition-all duration-300 overflow-hidden whitespace-nowrap">
        Join Discord
      </span>
    </a>,
    portalRoot
  );
};

export default BugReportButton;
