export const ratingScaleRedefine = [
    {title: 'Critical', description: 'Reject this transaction.', color: 'text-red-light'},
    {title: 'High', description: 'Funds potentially at risk, consider rejecting.', color: 'text-red-light'},
    {title: 'High', description: 'Funds potentially at risk, consider rejecting.', color: 'text-red-light'},
    {title: 'High', description: 'Funds potentially at risk, consider rejecting.', color: 'text-red-light'},
    {title: 'Medium', description: 'Issues found, review before proceeding.', color: 'text-yellow-dark'},
    {title: 'Medium', description: 'Issues found, review before proceeding.', color: 'text-yellow-dark'},
    {title: 'Medium', description: 'Issues found, review before proceeding.', color: 'text-yellow-dark'},
    {title: 'Low', description: 'Minor issues found, review before proceeding.', color: 'text-green-lighter1'},
    {title: 'Low', description: 'Minor issues found, review before proceeding.', color: 'text-green-lighter1'},
    {title: 'Low', description: 'Minor issues found, review before proceeding.', color: 'text-green-lighter1'},
    {title: 'No Issues', description: 'Our system did not identify any risks.', color: 'text-green-lighter1'},
]

