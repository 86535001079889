import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Optimism } from 'src/assets/img/optimism.svg';
import {
  default as UsdcIcon,
} from 'src/assets/img/tokens/usdc.png';
import ChainsImg from 'src/assets/img/chains-polygon-bnb.png'
import { TooltipContainer } from 'src/components/Tooltip';
import { Button } from 'src/components/lib';
import { ModalType, WalletType } from 'src/constants/enums';
import {
  ETHAddress,
  optimismChainId,
  optimismConfig, USDCAddress, USDTAddress,
} from 'src/constants/optimism';
import { useModal } from 'src/contexts/ModalContext';
import { useWallet } from 'src/contexts/WalletContext';
import { numberToLocaleString } from 'src/utils/helpers';
import { metaMask } from '../../../connectors';
import { assetsData } from '../../../constants/allocationChart';
import {CrosschainAssets} from "../Crosschain/components/CrosschainAssets";
import {networkConfigs} from "../../../constants/crosschain";

export function DepositWithdrawSection({
  totalValue,
  profitAndLoss,
  assetsList,
  timeLeftToWithdraw,
  isOptimism,
  networkId,
  isBalance
}) {
  const { walletAddress, chainId, setWalletAddress } = useWallet();
  const { account } = useWeb3React();

  const { showModal } = useModal();

  const networkConfig = networkConfigs[networkId]

  const network = isOptimism ? chainId : networkId
  const config = isOptimism ? optimismConfig : networkConfig

  const setProvider = (value) => {
    sessionStorage.setItem('connected', 'true');
    sessionStorage.setItem('provider', value);
  };

  const removeProvider = () => {
    sessionStorage.removeItem('connected');
    sessionStorage.removeItem('provider');
  };

  const openDepositModal = useCallback(async () => {
    if(!walletAddress || !chainId ) {
      showModal(ModalType.ConnectModal)
      return
    }
    if (walletAddress || chainId || chainId !== network) {
      try {
        await metaMask.activate(config);
        setProvider(WalletType.Metamask);
        if (account) {
          setWalletAddress(account);
        }
        showModal(ModalType.DepositModal);
      } catch (err) {
        removeProvider();
      }
    } else {
      showModal(ModalType.DepositModal);
    }
  }, [walletAddress, chainId, account]);

  const openWithdrawalModal = useCallback(async () => {
    if(!walletAddress || !chainId ) {
      showModal(ModalType.ConnectModal)
      return
    }
    if (walletAddress || chainId || chainId !== network) {
      try {
        await metaMask.activate(config);
        setProvider(WalletType.Metamask);
        if (account) {
          setWalletAddress(account);
        }
        showModal(ModalType.WithdrawModal);
      } catch (err) {
        removeProvider();
      }
    } else {
      showModal(ModalType.WithdrawModal);
    }
  }, [walletAddress, chainId, account, network, config]);

  const assetListNotEmpty = assetsList && !!assetsList?.length;
  const renderAssetsList = assetListNotEmpty && assetsList?.length <= 4;
  const renderAssetsListWithTooltip =
    assetListNotEmpty && assetsList?.length > 4;

  const assetTooltipContent = (list) => {
    return (
      <div className="flex flex-col gap-[8px] text-[12px]">
        {list?.map((item) => (
          <div key={item.asset} className="flex items-center gap-[4px]">
            <AssetComponent asset={item} />
            <p className="text-[12px] text-grey-black dark:text-white">
              {assetsData[item?.asset]?.symbol}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const disabledWithdrawal = timeLeftToWithdraw !== 0;

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toFixed(0);

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const userBalance = isBalance ? totalValue.toFixed(2) : numberToLocaleString(totalValue)

  return (
    <div className="w-full shadow-xxl rounded-[24px] relative mt-[14px] p-[16px] dark:bg-grey-black">
      <div className="flex justify-between">
        <div className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
          <p>Your Balance</p>
            <p className="font-caption text-[32px] leading-[35.2px] text-grey-dark dark:text-white mt-[4px] mb-[6px]">
              {userBalance}
            </p>
          <p>Profit/Loss</p>
          {isOptimism && (
            <p className="text-[14px] leading-[15px] mt-[4px]">
              <p style={{display: 'contents'}}>${profitAndLoss.roiUsd}</p> (
              <span
                style={{color: profitAndLoss.roiPercent >= 0 ? 'green' : 'red'}}
              >
                {profitAndLoss.roiPercent >= 0 ? '+' : ''}
                {profitAndLoss.roiPercent}%
              </span>
              )
            </p>
          )}
          {!isOptimism && (
            <p className="text-[14px] leading-[15px] mt-[4px]">-- --</p>
          )}
        </div>

        <div className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
          {isOptimism && <p>Chain</p>}
          {!isOptimism && <p>Chains</p>}
          {isOptimism && (
            <div className="flex mt-[4px] mb-[6px]">
              <Optimism/>
              <p className="font-medium text-[16px] leading-[17.6px] ml-[8px] text-grey-dark dark:text-white">
                Optimism
              </p>
            </div>
          )}
          {!isOptimism && (
            <div className="flex mt-[4px] mb-[6px]">
              <img className="w-[34px] h-[20px]" src={ChainsImg} alt="chains"/>
            </div>
          )}
          <p>Deposit Assets</p>
          {isOptimism && (
            <TooltipContainer
            tooltipContent="Assets that can be deposited into this vault."
            tooltipClassName="!left-[-75px] top-[40px] !text-grey-dark"
            >
              <div className="flex gap-[4px] mt-[8px]">
                {renderAssetsList &&
                  assetsList?.map((item) => (
                    <AssetComponent key={item.asset} asset={item}/>
                  ))}
                {renderAssetsListWithTooltip &&
                  assetsList
                    ?.slice(0, 3)
                    ?.map((item) => (
                      <AssetComponent key={item.asset} asset={item}/>
                    ))}
                {renderAssetsListWithTooltip && (
                  <TooltipContainer
                    tooltipContent={assetTooltipContent(assetsList?.slice(3))}
                    tooltipClassName="!left-[-130px] !text-grey-dark"
                  >
                    <div
                      className="flex justify-center items-center w-[24px] h-[24px] rounded-full border-[1px] border-solid border-grey-black dark:border-white bg-yellow-dark text-[9px] text-grey-black dark:text-white cursor-pointer">
                      +{assetsList?.length - 3}
                    </div>
                  </TooltipContainer>
                )}
              </div>
            </TooltipContainer>
          )}
          {!isOptimism && <CrosschainAssets/>}
        </div>
      </div>

      <div className="flex mt-[24px] gap-[8px]">
        <Button
          type={3}
          className="w-full max-w-[272px]"
          onClick={openDepositModal}
        >
          Deposit
        </Button>
        <TooltipContainer
          tooltipContent={`Time left to withdrawal: ${formatTime(
            timeLeftToWithdraw
          )}`}
          tooltipClassName="!left-[-40px] !top-[65px]"
          className="w-full max-w-[158px]"
        >
          <Button
            type={17}
            className="w-full max-w-[158px] disabled:border-grey-deep disabled:text-grey-deep disabled:hover:text-grey-deep dark:disabled:border-grey-deep dark:disabled:text-grey-deep dark:disabled:hover:text-grey-deep"
            disabled={disabledWithdrawal}
            onClick={openWithdrawalModal}
          >
            Withdrawal
          </Button>
        </TooltipContainer>
      </div>
    </div>
  );
}

const AssetComponent = ({ asset }) => {
  const [src, setSrc] = useState('');

  const getImage = async () => {
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/image/token?address=${asset?.asset?.toLowerCase()}`
    );
    if (data) {
      setSrc(data);
    } else {
      const img = assetsData[asset?.asset]?.icon;
      if (img) {
        setSrc(img);
      }
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  const assetAddress = asset?.asset?.toLowerCase()

  const isValid = assetAddress === USDCAddress?.toLowerCase() || assetAddress === USDTAddress?.toLowerCase() || assetAddress === ETHAddress.toLowerCase()

  if (!isValid) {
    return null;
  }

  return <img className="w-[24px] h-[24px]" src={src} alt="icon" />;
};
