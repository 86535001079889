import React from 'react';
import { ReactComponent as Entry } from 'src/assets/img/icons/entry.svg';
import { ReactComponent as EntryDark } from 'src/assets/img/icons/entry-dark.svg';
import { ReactComponent as Performance } from 'src/assets/img/icons/performance.svg';
import { ReactComponent as PerformanceDark } from 'src/assets/img/icons/performance-dark.svg';
import { ReactComponent as Withdrawal } from 'src/assets/img/icons/withdrawal.svg';
import { ReactComponent as WithdrawalDark } from 'src/assets/img/icons/withdrawal-dark.svg';
import { ReactComponent as Management } from 'src/assets/img/icons/management.svg';
import { ReactComponent as ManagementDark } from 'src/assets/img/icons/management-dark.svg';

export function FeesSection({ feesData, isOptimism }) {
  const entryFee = feesData?.entry !== undefined ? `${feesData?.entry}%` : '--';

  const managementFee = feesData?.manager !== undefined ? `${feesData?.manager}%` : '--';

  // const withdrawalFee =
  //   feesData?.withdrawal !== undefined ? `${feesData?.withdrawal}%` : '--';
  const performanceFee =
    feesData?.performance !== undefined ? `${feesData?.performance}%` : '--';

  return (
    <div className="mt-[14px]">
      <div className="flex justify-between items-center">
        <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
          Fees
        </p>
        {isOptimism && (
          <a
            href="https://docs.oneclick.fi/1cc/vaults/optimism-yield-index-vault/fee-structure"
            target="_blank"
            className="text-[12px] leading-[15px] text-yellow-dark hover:text-yellow-deep"
            rel="noreferrer"
          >
            Explore Fees Structure
          </a>
        )}
      </div>
      <div className="hidden md:flex justify-between mt-[6px]">
        <div className="flex gap-[2px] items-center ml-[-6px]">
          <Entry className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
          <EntryDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
          <div>
            <p className="text-[12px] leading-[15px] text-grey-deep">Entry</p>
            <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
              {isOptimism ?entryFee : '0'}
            </p>
          </div>
        </div>
        <div className="flex gap-[2px] items-center">
          <Withdrawal className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
          <WithdrawalDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
          <div>
            <p className="text-[12px] leading-[15px] text-grey-deep">Exit</p>
            <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
              0%
            </p>
          </div>
        </div>
        <div className="flex gap-[2px] items-center ml-[-6px]">
          <Management className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
          <ManagementDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
          <div>
            <p className="text-[12px] leading-[15px] text-grey-deep">Management</p>
            <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
              {isOptimism ? managementFee : '0'}
            </p>
          </div>
        </div>
        <div className="flex gap-[2px] items-center">
          <Performance className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
          <PerformanceDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
          <div>
            <p className="text-[12px] leading-[15px] text-grey-deep">
              Performance
            </p>
            <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
              {isOptimism ? performanceFee : '0'}
            </p>
          </div>
        </div>
      </div>

      <div className="flex md:hidden justify-between mt-[4px]">
        <div className="flex flex-col gap-[16px]">
          <div className="flex gap-[2px] items-center ml-[-6px]">
            <Entry className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
            <EntryDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
            <div>
              <p className="text-[12px] leading-[15px] text-grey-deep">Entry</p>
              <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
                {entryFee}
              </p>
            </div>
          </div>
          <div className="flex gap-[2px] items-center ml-[-6px]">
            <Management className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
            <ManagementDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
            <div>
              <p className="text-[12px] leading-[15px] text-grey-deep">Management</p>
              <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
                {managementFee}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[16px]">
          <div className="flex gap-[2px] items-center">
            <Withdrawal className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
            <WithdrawalDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
            <div>
              <p className="text-[12px] leading-[15px] text-grey-deep">Exit</p>
              <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
                0%
              </p>
            </div>
          </div>
          <div className="flex gap-[2px] items-center">
            <Performance className="dark:hidden w-[39px] h-[36px] mb-[-4px]" />
            <PerformanceDark className="hidden dark:block w-[39px] h-[36px] mb-[-4px]" />
            <div>
              <p className="text-[12px] leading-[15px] text-grey-deep">
                Performance
              </p>
              <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
                {performanceFee}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
