import { ReactComponent as LogoSvg } from "src/assets/img/logo.svg";
import { ReactComponent as LogoSvg2 } from "src/assets/img/logo2.svg";
import { ModalType } from "src/constants/enums";
import { useModal } from "src/contexts/ModalContext";
import {useLocation, useNavigate} from "react-router-dom";

export function Logo({ className, ...props }) {
  const { showModal } = useModal();
  const location = useLocation()
  const navigate = useNavigate()

  const handelClick = () => {
    if (location.pathname === "/") {
        showModal(ModalType.RestartModal);
    } else {
        navigate('/')
    }
  }

  return (
    <>
      <LogoSvg
        onClick={handelClick}
        className={`cursor-pointer dark:hidden ${className}`}
        {...props}
      />
      <LogoSvg2
        onClick={handelClick}
        className={`cursor-pointer hidden dark:block ${className}`}
        {...props}
      />
    </>
  );
}
