import React, {useEffect, useState} from 'react';
import {generateIcon, shortAddress} from "src/utils/helpers";
import axios from "axios";
import {optimismScanLink} from "src/constants/optimism";

export function TransactionsTableMobile({ data }) {
  const shouldRender = data && data?.length
  return (
    <div className="w-full flex flex-col gap-[20px] mt-[14px]">
      {!!shouldRender && data?.map((item) => <TxInfoBlock key={item?.txHash} itemData={item} />)}
    </div>
  );
}

function TxInfoBlock ({ itemData }) {
  const time = new Date(itemData?.timestamp * 1000)

  const [outcomeImage, setOutcomeImage] = useState('')
  const [incomeImage, setIncomeImage] = useState('')

  const incomeData = itemData?.income?.[0]
  const outcomeData = itemData?.outcome?.[0]

  const getImage = async () => {
    const {data: outcome} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/image/token?address=${itemData?.outcome?.[0]?.asset?.toLowerCase()}`)
    const {data: income} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/image/token?address=${itemData?.income?.[0]?.asset?.toLowerCase()}`)
    if(outcome) {
      setOutcomeImage(outcome)
    } else {
      const generatedIcon = generateIcon(itemData?.outcome?.[0]?.assetName?.toLowerCase())
      setOutcomeImage(generatedIcon)
    }
    if(income) {
      setIncomeImage(income)
    } else {
      const generatedIcon = generateIcon(itemData?.income?.[0]?.assetName?.toLowerCase())
      setIncomeImage(generatedIcon)
    }
  }

  const txLink = `${optimismScanLink}tx/${itemData?.txHash}`;

  useEffect(() => {
    getImage()
  }, [itemData])

  return (
    <div className="w-full flex flex-col gap-[18px]">

      <div className="flex justify-between text-grey-black dark:text-white">
        <div className="flex flex-col gap-[8px]">
          <p className="text-grey-deep font-normal text-[12px]">Address</p>
          <p className="font-caption font-medium text-[14px] leading-[15.4px] underline">
            <a className="cursor-pointer" href={txLink} target="_blank" rel="noreferrer">
              {shortAddress(itemData?.txHash, 6, 6)}
            </a>
          </p>
        </div>
        <div className="flex flex-col gap-[8px] text-right">
          <p className="text-grey-deep font-normal text-[12px]">Tx Type</p>
          <div className="font-normal text-[12px] leading-[15px]">
            <p className="min-w-[120px]">
              {itemData?.displayType}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center text-grey-black dark:text-white">
        <div className="flex flex-col  gap-[8px]">
          <p className="text-grey-deep font-normal text-[12px]">Outgoing</p>
          <div className="font-normal text-[12px] leading-[15px]">
            {outcomeData?.assetName && (
              <div className="flex flex-row items-center gap-[4px]">
                <img className="w-[16px] h-[16px]" src={outcomeImage} alt=""/>
                <p>{outcomeData?.displayAmount}</p>
                <p className="">{outcomeData?.assetName}</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-[8px] text-right">
          <p className="text-grey-deep font-normal text-[12px]">Incoming</p>
          <div className="font-normal text-[12px] leading-[15px]">
            {!!incomeData?.assetName && (
              <div className="flex flex-row items-center gap-[4px]">
                <img className="w-[16px] h-[16px]" src={incomeImage} alt=""/>
                <p>{incomeData?.displayAmount}</p>
                <p className="">{incomeData?.assetName}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-[8px]">
          <p className="text-grey-deep font-normal text-[12px]">Time</p>
          <div className="font-normal text-[12px] leading-[15px] text-yellow-dark">{time?.toDateString()}</div>
        </div>
      </div>
    </div>
  )
}
