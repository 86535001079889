/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { Rate } from 'src/components/Rate';
import { RateSlider } from 'src/components/RateSlider';
import { Button } from 'src/components/lib';
import { useWallet } from 'src/contexts/WalletContext';
import { useGeneratePortfolio } from 'src/hooks/useGeneratePortfolio';
import { useProgress } from 'src/hooks/useProgress';
import { Header } from 'src/layout/Header';
import { Loading } from '../Loading';
import { Result2 } from '../Result2';
import {descriptionsUploadPortfolio} from "src/constants/descriptionsUploadPortfolio";
import {useModal} from 'src/contexts/ModalContext';
import {ModalType} from 'src/constants/enums';
import {JoinModal2} from 'src/components/modals/JoinModal2';

export function Result({
  latestPortfolio,
  riskScore,
  setRiskScore,
  riskTrendings,
  skipQuestion,
  isDemo = false,
}) {
  const [result2, setResult2] = useState(!!latestPortfolio);
  const {walletAddress} = useWallet();
  const [showingTooltip, setShowingTooltip] = useState(false);
  const [descNumber, setDescNumber] = useState(0)
  const [desc, setDesc] = useState(descriptionsUploadPortfolio[0])
  const {showModal, modalType} = useModal()

  useEffect(() => {
    const countDescription = descriptionsUploadPortfolio.length

    const t = setTimeout(() => {
      const newDescNumber = descNumber === 0
          ? Math.floor(Math.random() * countDescription)
          : (descNumber < (countDescription - 1) ? descNumber + 1 : 1)

      setDescNumber(newDescNumber)
      setDesc(null)
    }, 5000);

    return () => clearTimeout(t)
  }, [descNumber]);
  useEffect(() => {
    if (desc === null) {
      setDesc(descriptionsUploadPortfolio[descNumber])
    }
  }, [desc, descNumber]);

  const timeoutRef = useRef();
  useEffect(() => {
    if (!result2) {
      timeoutRef.current = setTimeout(() => {
        setShowingTooltip(true);
      }, 1000);
    } else {
      clearTimeout(timeoutRef.current);
      setShowingTooltip(false);
    }
    return () => {
      clearTimeout(timeoutRef.current);
      setShowingTooltip(false);
    };
  }, [result2]);

  const handleRateChange = (value) => {
    clearTimeout(timeoutRef.current);
    setShowingTooltip(false);
    setRiskScore(value);
  };

  const {
    portfolio,
    error,
    generatePortfolio,
    resetPortfolio,
    isLoading: isLoadingPortfolio,
  } = useGeneratePortfolio({
    walletAddress,
    riskScore,
  });

  const {
    portfolio: demoPortfolio,
    resetPortfolio: resetDemoPortfolio,
    isLoading: isLoadingDemo,
  } = useGeneratePortfolio({
    walletAddress: '',
    riskScore,
  });

  const {
    progress: generationProgress,
    start: startGeneration,
    started: generationStarted,
    isCompleted: generationCompleted,
  } = useProgress({waitFor: portfolio});

  console.log('===> generation progress', generationProgress);

  const riskCategory =
      riskScore <= 3
          ? 'low'
          : riskScore <= 5.5
              ? 'moderate'
              : riskScore <= 7.5
                  ? 'above average'
                  : 'high';

  const range = {
    low: [1, 3],
    moderate: [3.5, 5.5],
    'above average': [6, 7.5],
    high: [8, 10],
  };

  const handleGoBack = () => {
    resetPortfolio();
    resetDemoPortfolio();
    setResult2(false);
  };

  const handleClick = () => {
      resetPortfolio();
      setResult2(true);
      generatePortfolio();
      if (!localStorage.getItem("wait_list_email")
        || !localStorage.getItem("wait_list_wallet")
      ) {
        showModal(ModalType.JoinModal2)
      }
  };

  useEffect(() => {
    if (result2 && !latestPortfolio) startGeneration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result2, latestPortfolio]);

  const renderedModal = modalType === ModalType.JoinModal2
      ? (<JoinModal2 onSuccess={() => showModal(ModalType.None)}/>)
      : null

  if (result2 && (
      (generationCompleted && (portfolio || demoPortfolio))
      || (!isLoadingPortfolio && !isLoadingDemo && latestPortfolio)
  )) {
    return (
        <>
          {renderedModal}
          <Header/>
          <Result2
              isDemo={isDemo}
              viewDemo={!portfolio && !latestPortfolio}
              analysis={{
                risk_score: riskScore,
                ...(portfolio
                    ? portfolio
                    : demoPortfolio
                        ? demoPortfolio
                        : latestPortfolio),
              }}
              goBack={handleGoBack}
              className={renderedModal ? `blur` : ''}
          />
        </>
    )
  }

  return  (
      <>
        {renderedModal}
        <div
            className="text-center max-w-[725px] mx-auto px-[24px] pt-[81px] lg:pt-[110px] pb-[20px] lg:pb-[80px] animate-fadeIn dark:text-white">
          {generationStarted && (!generationCompleted || modalType !== ModalType.None) ? (
              <Loading
                  progress={generationProgress}
                  title="Generating portfolio..."
                  subtitle={desc?.subtitle}
                  description={desc?.description}
                  error={error}
                  retryOnError={false}
              />
          ) : (
              <>
                <Rate score={riskScore} size="large" className="mx-auto"/>
                <h2 className="font-caption font-medium text-[32px] lg:text-[44px] leading-[1.1] mt-[32px] lg:mt-[48px]">
                  {skipQuestion ? 'Choose Your Risk Score' : 'Your Risk Profile'}
                </h2>
                <p className="text-[16px] lg:text-[24px] font-light leading-[1.2] tracking-[-0.5px] mt-[24px]">
                  Your risk score is {riskScore}. This is considered{' '}
                  <span className="font-bold text-yellow-dark">{riskCategory}</span>{' '}
                  risk. {Math.round(riskTrendings[riskCategory] * 100)}% of all One
                  Click users also have a score in a range from{' '}
                  {range[riskCategory][0]} to {range[riskCategory][1]}
                </p>
                <RateSlider
                    value={riskScore}
                    onChange={handleRateChange}
                    showTooltip={showingTooltip}
                    disabled={isLoadingPortfolio || isLoadingDemo}
                    className="w-full lg:w-[491px] mx-auto mt-[92.65px] lg:mt-[102.65px]"
                />
                <Button
                    type={3}
                    className="max-w-[237px] h-[53px] mx-auto mt-[158px] text-[17.4884px] font-medium"
                    onClick={handleClick}
                    disabled={result2 && !portfolio && !error}
                >
                  {result2 && !portfolio && !error ? (
                      <>
                        Generating
                        <ClipLoader color="#1E1E1E" size={20}/>
                      </>
                  ) : (
                      'Generate Portfolio'
                  )}
                </Button>
              </>
          )}
          {/* {error && (
            <ErrorBox
              error={error}
              scanning
              reset={handleViewDemoPortfolio}
              className="mt-[20px]"
            />
          )} */}
        </div>
      </>
  );
}
