import React, {useState} from 'react';
import {DeFiChart} from "src/components/charts/DeFiChart";
import {ChartEmptyState} from "./ChartEmptyState";

export function AllocationChart({ allocationChartData = [] }) {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleHoverElement = (elementIndex) => {
    setHoveredIndex(Math.floor(elementIndex / 2));
  };

  const handleBlurElement = () => {
    setHoveredIndex(-1);
  };

  const shouldRenderAssets = !!allocationChartData && !!allocationChartData?.datasets?.[0]?.data?.length && !!allocationChartData?.labels?.length
  const assets = shouldRenderAssets && allocationChartData?.datasets?.[0]?.data
  const bgColors = shouldRenderAssets && allocationChartData?.datasets?.[0]?.backgroundColor

  return (
    <div className="w-full flex flex-col md:flex-row gap-[24px] mt-[16px]">
      {(!allocationChartData || !shouldRenderAssets) && (
        <ChartEmptyState/>
      )}
      {!!allocationChartData && !!shouldRenderAssets && (
        <div className="w-[305px] h-[305px] shadow-xxl bg-white dark:bg-grey-white5 rounded-full lg:ml-[20px]" >
            <DeFiChart
              radius='92%'
              data={allocationChartData}
              hoveredElementIndex={Math.max(hoveredIndex * 2, -1)}
              onHover={handleHoverElement}
              onBlur={handleBlurElement}
              imageSize={30}
              gap={0.008}
            />
        </div>
      )}
      {!!allocationChartData && shouldRenderAssets && (
        <div className="w-full max-w-[426px] min-w-[180px]">
          <div className="flex justify-between border-b-[1px] border-b-solid border-b-grey-lightest-040 pb-[8px] px-[16px] font-medium text-[14px] leading-[15px] text-grey-deep">
            <p>Asset</p>
            <p className="w-[55px]">Share</p>
          </div>
          <div className="flex flex-col mt-[12px]">

            {shouldRenderAssets && assets?.map((item, index) => {
              const label = allocationChartData?.labels?.[index]
              return (
                <>
                  {!!item && (
                    <div
                      className={`flex justify-between font-medium text-[14px] leading-[15px] py-[7px] px-[4px] ${
                        index === hoveredIndex ? "shadow-md backdrop-blur-xs cursor-pointer" : ""
                      } ${ hoveredIndex !== -1 && index !== hoveredIndex
                          ? "opacity-50"
                          : ""
                      }`}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(-1)}
                    >
                      <div className="flex gap-[4px] items-center">
                        <div className="w-[13px] h-[13px] rounded-[50%] bg-yellow-dark"
                             style={{backgroundColor: bgColors[index]}}/>
                        <p className="text-grey-deep">{label}</p>
                      </div>
                      <p className="font-caption text-greya-black dark:text-white min-w-[70px] text-left">{item}%</p>
                    </div>
                  )}
                </>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
}
