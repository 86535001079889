import { ErrorBox } from "src/components/lib";
import { ProgressBar } from "src/components/ProgressBar";
import { useMobile } from "src/hooks/useMobile";

export function Loading({
  title,
  subtitle,
  description,
  progress,
  error,
  goToDemo,
  className,
  retryOnError = true,
}) {
  const isMobile = useMobile();

  return (
    <div className="pt-[81px] px-[24px] lg:pt-[237px] animate-fadeIn dark:text-white">
      <div className={`text-center mx-auto ${className}`}>
        <h2
          className="text-[32px] lg:text-[44px] mx-auto"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {subtitle && (
            <p
              className="text-[16px] lg:text-[24px] leading-[1.2] font-body mt-[24px] mb-[0px] mx-auto font-normal lg:font-light tracking-[-0.5px] animate-delayedFadeIn opacity-100"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
        )}
        {description && (
            <p
              className={`text-[16px] lg:text-[24px] leading-[1.2] font-body mx-auto font-normal lg:font-light tracking-[-0.5px] ${subtitle ? 'mt-[10px] animate-delayedFadeIn opacity-100' : 'mt-[24px]'}`}
              dangerouslySetInnerHTML={{ __html: description }}
            />
        )}
        <ProgressBar
          progress={progress}
          width={isMobile ? 327 : 500}
          height={50}
          iconWidth={76.67}
          className="mt-[48px] lg:mt-[36px] mx-auto"
        />
        {error && (
          <ErrorBox
            error={error}
            scanning
            reset={goToDemo}
            retry={retryOnError}
            className="mt-[24px]"
          />
        )}
      </div>
    </div>
  );
}
