import React from 'react';

export function CrosschainVaultStrategy() {
  return (
    <div className="w-full max-w-[907px]">
      <h3 className="font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
        Vault Strategy
      </h3>
      <p className="font-medium text-[14px] leading-[16.8px] text-grey-deep mt-[8px]">
        Strategy Overview: This strategy is designed to give you a balanced investment in USDT across two different blockchains: Polygon and Binance Smart Chain within One Click.
      </p>
      <ol className="font-medium text-[14px] leading-[16.8px] text-grey-deep list-decimal ml-[20px]">
        <li className="pl-[6px]">
          Initial Asset Allocation: The vault will maintain a balanced allocation among the supported blockchains say 50% USDT on Polygon and 50% on Binance Smart Chain.
        </li>
        <li className="pl-[6px]">
          Rebalancing: When the asset allocation deviates significantly from the target, the vault will rebalance allocation between different blockchains for new users.
        </li>
        <li className="pl-[6px]">
          Yield Farming: The vault will participate in yield farming opportunities on Stargate stablecoins pools.
        </li>
        <li className="pl-[6px]">
          Staying Safe: We're playing it safe. As long as the technology (Smart Contracts) we use is secure, the risk to your investment is minimal.
        </li>
        <li className="pl-[6px]">
          Regular Review: Continuously monitor and adjust the strategy based on market conditions, security audits, and the performance of the underlying assets.
        </li>
      </ol>
    </div>
  );
}
