import { Web3ReactProvider } from "@web3-react/core";
import { BrowserRouter } from "react-router-dom";
import { WalletProvider } from "./contexts/WalletContext";
import { DiscordProvider } from "./contexts/DiscordContext";
import { ModalProvider } from "./contexts/ModalContext";
import { ToastProvider } from "./contexts/ToastContext";

import {
  metaMask,
  metaMaskHooks,
  trustWallet,
  trustWalletHooks,
  walletConnect,
  walletConnectHooks,
} from "./connectors";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {SolanaProvider} from "./contexts/SolanaContext";

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [trustWallet, trustWalletHooks],
];

const apolloClient = new ApolloClient({
  uri: 'https://api-v2.dhedge.org/graphql',
  cache: new InMemoryCache(),
})

export function AppProviders({ children }) {

  return (
    <Web3ReactProvider connectors={connectors}>
      <ApolloProvider client={apolloClient}>
        <SolanaProvider>
          <BrowserRouter>
            <WalletProvider>
              <DiscordProvider>
                <ModalProvider>
                  <ToastProvider>
                    {/* <TurnstileProvider>{children}</TurnstileProvider> */}
                    {children}
                  </ToastProvider>
                </ModalProvider>
              </DiscordProvider>
            </WalletProvider>
          </BrowserRouter>
        </SolanaProvider>
      </ApolloProvider>
    </Web3ReactProvider>
  );
}
