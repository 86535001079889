import React from 'react';
import { Pagination } from 'src/components/Pagination';
import { TooltipContainer } from 'src/components/Tooltip';
import { TransactionsTable } from './TransactionsTable';
import {TransactionsTableMobile} from "./TransactionTablemobile";

export function Transactions({ data, page, changePage, totalPages, mobileChangePage, mobileTotalPages, mobilePage, mobileData, txCount }) {
  return (
    <div className="w-full mt-[32px] px-[24px] lg:px-[64px]">
      <div className="flex justify-between items-center">
        <TooltipContainer
          tooltipContent="Transaction in DeFi Vaults: Executing operations within a DeFi vault, such as deposits, withdrawals, or strategy changes."
          tooltipClassName="!left-[-40px] !text-grey-dark"
        >
          <p className="font-caption font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
            Transactions
          </p>
        </TooltipContainer>
        <p className="font-normal text-[14px] leading-[15px] text-grey-deep uppercase">
          {txCount} Records
        </p>
      </div>
      <div className="hidden md:flex flex-col">
        <TransactionsTable data={data} />
        <div className="flex justify-center w-full mt-[20px]">
          <Pagination page={page} setPage={changePage} totalPages={totalPages} />
        </div>
      </div>
      <div className="flex md:hidden flex-col">
        <TransactionsTableMobile data={mobileData} />
        <div className="flex justify-center w-full mt-[20px]">
          <Pagination page={mobilePage} setPage={mobileChangePage} totalPages={mobileTotalPages} />
        </div>
      </div>
    </div>
  );
}
