import { useCallback, useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useMobile } from "src/hooks/useMobile";
import { increaseOpacity, isDarkMode } from "src/utils/helpers";

export function DeFiChart({
  data,
  hoveredElementIndex,
  onHover,
  onBlur,
  cutout = "55%",
  radius = "90%",
  borderRadius = 8.24,
  borderWidth = 2,
  imageSize = 36,
  mobileImageSize = 24,
  gap = 0.01,
  angle = 0,
  disableAnimation = false,
  borderColor = "#E9B55C",
  darkenOnHover = false,
  style = {},
}) {
  const [doughnutKey, setDoughtnutKey] = useState(0);
  const chartRef = useRef();

  const gapSize = data.datasets[0].data.reduce((sum, e) => sum + e) * gap;
  const images = data.images?.reduce((o, e) => o.concat([e, ""]), []);
  const chainImages = data.chainImages?.reduce((o, e) => o.concat([e, ""]), []);
  const logoImage = data.logoImage;

  const hasHoverEffect = onHover && onBlur;

  const isMobile = useMobile();

  const handleAfterDraw = useCallback(
    (chart) => {
      // draw protocol logos
      const ctx = chart.ctx;
      chart["$datalabels"]["_labels"].forEach((item) => {
        try {
          const index = item["$context"]["dataIndex"];

          if (index % 2) return;

          const arcElement = item["_el"];
          ctx.save();

          ctx.beginPath();
          ctx.moveTo(arcElement.x, arcElement.y);

          ctx.arc(
            arcElement.x,
            arcElement.y,
            arcElement.outerRadius,
            arcElement.startAngle,
            arcElement.endAngle
          );
          ctx.moveTo(arcElement.x, arcElement.y);
          ctx.closePath();
          ctx.clip();

          // if (hasHoverEffect) {
          //   if (hoveredElementIndex !== -1 && index !== hoveredElementIndex) {
          //     ctx.filter = "grayscale(1)";
          //   } else if (hoveredElementIndex !== -1) {
          //     // color overlay
          //     // ctx.globalCompositeOperation = "source-atop";
          //     // ctx.fillStyle = "#E9B55C70";
          //     // ctx.fillRect(0, 0, 500, 500);
          //   }
          // }
          const rect = item["$layout"]["_box"]["_rect"];
          const [width, height] = isMobile
              ? [mobileImageSize, mobileImageSize]
              : [imageSize, imageSize];

          if (images && images[index]) {
            const imagesList = Array.isArray(images[index]) ? images[index] : [images[index]]
            ctx.translate(rect.x + rect.w / 2, rect.y + rect.h / 2);
            ctx.rotate(-angle * (Math.PI / 180));
            switch (imagesList.length) {
              case 1:
                imagesList.map(() => {
                  ctx.drawImage(
                      imagesList[0],
                      -width / 2,
                      -height / 2,
                      width,
                      height
                  );
                })
                break
              default:
                const ratio = 0.1;
                const imageRatio = ratio * (Math.ceil((imagesList.length - 1) / 2));
                const newWidth = width * (1 - imageRatio)
                const newHeight = newWidth * height / width
                const iconCircleRadius = newWidth / 3 + (newWidth / 2 * ratio * imagesList.length) ;

                const angleDelta = 360 / imagesList.length
                let angle = angleDelta / 2

                const mainOffsetX = newWidth / 2;
                const mainOffsetY = newHeight / 2;

                imagesList.map((image) => {
                  if (image === null) {
                    return
                  }
                  const x = iconCircleRadius * Math.sin(angle * Math.PI / 180)
                  const y = iconCircleRadius * Math.cos(angle * Math.PI / 180)
                  angle += angleDelta
                  const offsetX = -mainOffsetX - x
                  const offsetY = -mainOffsetY - y
                  ctx.drawImage(
                      image,
                      offsetX,
                      offsetY,
                      newWidth,
                      newHeight
                  );
                })
            }
          }

          if (chainImages && chainImages[index]) {
            const chainWidth = width * 0.4;
            const chainHeight = height * 0.4;
            const chainLeft = rect.x + rect.w / 2 + width * 0.1;
            const chainTop = rect.y + rect.h / 2 + height * 0.1;
            ctx.drawImage(
              chainImages[index],
              chainLeft,
              chainTop,
              chainWidth,
              chainHeight
            );
            ctx.beginPath();
            ctx.arc(
              chainLeft + chainWidth / 2,
              chainTop + chainHeight / 2,
              chainWidth * 0.5,
              0,
              2 * Math.PI
            );
            ctx.lineWidth = 1;
            ctx.strokeStyle = "white";
            ctx.fillStyle = "transparent";
            ctx.fill();
            ctx.stroke();
          }
          ctx.restore();
        } catch (e) {
          console.log("error drawing logo icon on chart");
        }
      });

      if (logoImage) {
        // draw main logo at the center of the chart
        const centerX = chart.width / 2;
        const centerY = chart.height / 2;
        const width = chart.width * 0.302;
        const height = chart.height * 0.302;
        const x = centerX - width / 2;
        const y = centerY - height / 2;
        ctx.drawImage(logoImage, x, y, width, height);
      }
    },
    [
      angle,
      chainImages,
      imageSize,
      images,
      isMobile,
      logoImage,
      mobileImageSize,
    ]
  );

  useEffect(() => {
    setDoughtnutKey((doughnutKey) => doughnutKey + 1);
  }, [data]);

  return (
    <Doughnut
      ref={chartRef}
      key={doughnutKey}
      data={{
        labels: data.labels.reduce((o, e) => o.concat([e, ""]), []),
        datasets: [
          {
            label: data.datasets[0].label,
            data: data.datasets[0].data.reduce(
              (o, e) => o.concat([e, gapSize]),
              []
            ),
            backgroundColor: data.datasets[0].backgroundColor
              .reduce((o, e) => o.concat([e, "rgba(0,0,0,0)"]), [])
              .map((color, i) => {
                if (!hasHoverEffect) return color;
                if (!darkenOnHover)
                  return hoveredElementIndex === -1 ||
                    i === hoveredElementIndex ||
                    i % 2
                    ? color
                    : !isDarkMode()
                    ? "#00000011"
                    : "#ffffffaa";
                else
                  return hoveredElementIndex === i
                    ? increaseOpacity(color)
                    : color;
              }),
            // hoverBackgroundColor: darkenOnHover
            //   ? data.datasets[0].backgroundColor
            //       .reduce((o, e) => o.concat([e, "rgba(0,0,0,0)"]), [])
            //       .map((color) => {
            //         if (color.length !== 9) return color;
            //         return increaseOpacity(color);
            //       })
            //   : undefined,
            borderColor: data.datasets[0].borderColor
              ?.reduce(
                (o) =>
                  o.concat([
                    borderColor.length === 9 ? borderColor : borderColor + "80",
                    "#00000000",
                  ]),
                []
              )
              .map((color, i) => {
                if (!hasHoverEffect) return color;
                return hoveredElementIndex === -1 ||
                  i === hoveredElementIndex ||
                  i % 2
                  ? hoveredElementIndex === i
                    ? borderColor.length === 9
                      ? borderColor
                      : borderColor + "33"
                    : color
                  : `#00000000`;
              }),
            borderWidth: data.datasets[0].borderColor
              ?.reduce((o) => o.concat([borderWidth, 0]), [])
              .map((width, i) => {
                if (!hasHoverEffect) return width;
                return hoveredElementIndex === -1
                  ? borderWidth
                  : hoveredElementIndex === i
                  ? borderWidth * 5
                  : 0;
              }),
          },
        ],
      }}
      options={{
        borderWidth,
        borderRadius,
        cutout,
        radius,
        animation: disableAnimation ? false : undefined,
        onHover: (event, elements) => {
          if (!elements?.length) onBlur?.();
          else onHover?.(elements[0].index);
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: () => "",
          },
          tooltip: {
            enabled: () => false,
          },
          title: false,
          legend: false,
        },
      }}
      plugins={[
        {
          afterDraw: handleAfterDraw,
        },
      ]}
      onMouseLeave={onBlur}
      style={style}
    />
  );
}
