import { format, parseISO } from 'date-fns';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ReactComponent as OrangeIcon } from 'src/assets/img/icons/hexagon-orange.svg';
import { useOptimismValues } from '../../../contexts/OptimismContext';
import {
  formatTvlForChart,
  numberToLocaleString,
} from '../../../utils/helpers';
import { ChartEmptyState } from './ChartEmptyState';
import { ChartTabs } from './ChartTabs';
import { ChartTooltip } from './ChartTooltip';

const ONE_THOUSAND = 1000;

export function TVLChart() {
  const { tvlData, tvlPeriod, setTvlPeriod, tvlPercentageChangeData } =
    useOptimismValues();

  const chartData = formatTvlForChart(tvlData);
  const formatYAxis = (number) => `${number / ONE_THOUSAND}K`;
  const formatXAxis = (dateValue) => {
    const date = parseISO(dateValue);
    if (date.getDate()) {
      return format(date, 'd LLL');
    } else {
      return '';
    }
  };
  const tooltipFormatter = (payload) => {
    return `$${numberToLocaleString(Number(payload[0]?.value?.toFixed(2)), 0)}`;
  };

  return (
    <>
      {!chartData?.length && <ChartEmptyState />}
      {!!chartData?.length && (
        <div className="max-w-[470px] lg:max-w-[unset] hidden md:block">
          <ChartTabs
            data={tvlPercentageChangeData}
            activeTab={tvlPeriod}
            setActiveTab={setTvlPeriod}
          />
        </div>
      )}
      {!!chartData?.length && (
        <div className="max-w-[470px] lg:max-w-[unset] w-full mt-[10px] text-[14px] font-normal leading-[15px] text-grey-deep">
          <ResponsiveContainer width="102%" height={370} style={{marginLeft:'-25px'}}>
            <AreaChart data={chartData}>
              <defs>
                <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#E3AA18" stopOpacity={0.4} />
                  <stop offset="75%" stopColor="#E3AA18" stopOpacity={0.02} />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                stroke="#E3AA1890"
                fill="url(#color)"
                type="stepAfter"
              />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tickCount={7}
                tickFormatter={formatXAxis}
              />
              <YAxis
                dataKey="value"
                axisLine={false}
                tickLine={false}
                tickCount={9}
                tickFormatter={formatYAxis}
              />
              <Tooltip
                content={
                  <ChartTooltip
                    title="TVL"
                    formatter={tooltipFormatter}
                    Icon={OrangeIcon}
                  />
                }
              />
              <CartesianGrid vertical={false} opacity={0.2} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
      {!!chartData?.length && (
        <div className="block md:hidden">
          <ChartTabs
            data={tvlPercentageChangeData}
            activeTab={tvlPeriod}
            setActiveTab={setTvlPeriod}
          />
        </div>
      )}
    </>
  );
}
