import React, { useState } from 'react';
import { PeriodTabsState } from '../../../constants/enums';
import { TabItem } from '../StableVault/TabItem';

const PeriodTabs = [
  { title: '1D', value: PeriodTabsState.oneDay },
  { title: '7D', value: PeriodTabsState.sevenDays },
  { title: '1M', value: PeriodTabsState.oneMonth },
  { title: '3M', value: PeriodTabsState.threeMonths },
  { title: '1Y', value: PeriodTabsState.oneYear },
  { title: 'ALL', value: PeriodTabsState.all },
];

export function ChartTabs({ data, activeTab, setActiveTab }) {
  return (
    <>
      <div className="hidden md:flex flex-col justify-between items-center mt-[16px] lg:flex-row">
        <div className="flex flex-wrap w-full lg:flex-nowrap">
          {data.map(({ period, value }) => (
            <div
              key={period}
              className="w-full max-w-[93px] font-medium text-[14px]"
            >
              <p className="leading-[15.4px] text-grey-deep">{period}</p>
              {value.isUp === null ? (
                <p className="font-caption leading-[20.44px] text-grey-deep ">
                  0%
                </p>
              ) : (
                <>
                  {value.isUp ? (
                    <p className="font-caption leading-[20.44px] text-green-lighter1">
                      +{value?.value}%
                    </p>
                  ) : (
                    <p className="font-caption leading-[20.44px] text-red-light">
                      {value?.value}%
                    </p>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-between md:justify-normal bg-grey-lightest-15 dark:bg-grey-darker5 p-1 w-full md:w-fit mt-[24px] rounded-[8px] font-bold text-[14px] leading-[16.8px] text-grey-dark dark:text-grey-deep lg:mt-[0]">
          {PeriodTabs.map(({ title, value }) => (
            <TabItem
              key={value}
              isActive={activeTab === value}
              title={title}
              setActive={setActiveTab}
              value={value}
            />
          ))}
        </div>
      </div>
      <div className="flex md:hidden flex-col justify-between items-center mt-[16px] lg:flex-row">
        <div className="flex justify-between md:justify-normal bg-grey-lightest-15 dark:bg-grey-darker5 p-1 w-full md:w-fit rounded-[8px] font-bold text-[14px] leading-[16.8px] text-grey-dark dark:text-grey-deep lg:mt-[0]">
          {PeriodTabs.map(({ title, value }) => (
            <TabItem
              key={value}
              isActive={activeTab === value}
              title={title}
              setActive={setActiveTab}
              value={value}
            />
          ))}
        </div>
        <div className="flex flex-wrap w-full lg:flex-nowrap mt-[24px]">
          {data.map(({ period, value }) => (
            <div
              key={period}
              className="w-full max-w-[93px] font-medium text-[14px]"
            >
              <p className="leading-[15.4px] text-grey-deep">{period}</p>
              {value?.isUp ? (
                <p className="font-caption leading-[20.44px] text-green-lighter1">
                  +{value?.value}%
                </p>
              ) : (
                <p className="font-caption leading-[20.44px] text-red-light">
                  {value?.value}%
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
