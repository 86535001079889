import {Contract, ethers} from 'ethers';
import {useCallback, useEffect, useState} from 'react';
import { ModalType } from '../constants/enums';
import { erc20abi } from '../constants/erc20abi';
import { useModal } from '../contexts/ModalContext';
import { useWallet } from '../contexts/WalletContext';
import {crosschainVaultAbi} from "../constants/crosschainVaultAbi";
import {getContractAddress} from "../utils/helpers";
import {balanceData, lpAddresses, networkConfigs, tokenDecimals, zeroBalance} from "../constants/crosschain";
import {lpTokenAbi} from "../constants/lpTokenAbi";

export function useCrosschainVault() {
  const { provider, walletAddress, chainId } = useWallet();
  const { showModal } = useModal();
  
  const [userBalance, setUserBalance] = useState(0)
  const [refetch, setRefetch] = useState(false)

  const depositToCrosschain = useCallback(
    async (
      selectedToken,
      amount,
      changeTxStatus,
      setIsTxSuccess,
      setSuccessDepositTxHash,
    ) => {
      const contractAddress = getContractAddress(chainId)
      const crosschainVault = new Contract(contractAddress, crosschainVaultAbi, provider?.getSigner());

      const token = new Contract(
        selectedToken?.tokenAddress,
        erc20abi,
        provider?.getSigner()
      );

      try {
        const allowance = await token.allowance(walletAddress, contractAddress);

        if (parseFloat(allowance) < +amount) {
          changeTxStatus(1);
          const tx = await token?.approve(contractAddress, amount);
          await tx.wait();
        }
      } catch {
        setIsTxSuccess(false);
        showModal(ModalType.DepositResultModal);
      }

      try {
        const depositFeeInfo = await crosschainVault.quoteDepositFees(walletAddress, selectedToken?.tokenAddress, amount)
        const depositFee = depositFeeInfo[0]
        const tx = await crosschainVault?.deposit(selectedToken?.tokenAddress, amount, {value: depositFee});
        changeTxStatus(2);
        const resp = await tx.wait();
        setSuccessDepositTxHash(resp?.transactionHash);
        changeTxStatus(3);
        setIsTxSuccess(true);
        setRefetch(prev => !prev)
        showModal(ModalType.DepositResultModal);
      } catch (err) {
        setIsTxSuccess(false);
        showModal(ModalType.DepositResultModal);
      }
    },
    [walletAddress, provider, chainId]
  );

  const withdrawFromCrosschain = useCallback(
    async (
      selectedToken,
      amount,
      changeTxStatus,
      setIsTxSuccess,
      setSuccessWithdrawTxHash,
    ) => {
      const contractAddress = getContractAddress(chainId)
      const crosschainVault = new Contract(contractAddress, crosschainVaultAbi, provider?.getSigner());

      try {
        const tx = await crosschainVault?.withdraw(selectedToken?.tokenAddress, amount);
        changeTxStatus(2);
        await tx.wait();
        const resp = await tx.wait();
        setSuccessWithdrawTxHash(resp?.transactionHash);
        changeTxStatus(3);
        setIsTxSuccess(true);
        setRefetch(prev => !prev)
        showModal(ModalType.WithdrawalResultModal);
      } catch (err) {
        setIsTxSuccess(false);
        showModal(ModalType.WithdrawalResultModal);
      }
    },
    [provider, walletAddress, chainId]
  );

  const getLpAmount = useCallback( async (selectedTokenAddress) => {
    const contractAddress = getContractAddress(chainId)
    const crosschainVault = new Contract(contractAddress, crosschainVaultAbi, provider?.getSigner());

    const lpAddress = lpAddresses[chainId][selectedTokenAddress]
    let lpContract

    if (lpAddress) {
      lpContract = new Contract(lpAddress, lpTokenAbi, provider?.getSigner());
    }

    if(!lpAddress) {
      return {formattedTokenAmount: zeroBalance, formattedLpAmount: zeroBalance}
    }

    const decimals = tokenDecimals[selectedTokenAddress]

    try {
      const lpAmount = await crosschainVault.stargateLP(walletAddress, selectedTokenAddress)
      const tokenAmount = await lpContract.amountLPtoLD(lpAmount?.toString())

      const formattedLpAmount = ethers.utils.formatUnits(lpAmount, 6)
      const formattedTokenAmount = ethers.utils.formatUnits(tokenAmount, decimals)

      return {formattedTokenAmount, formattedLpAmount}
    } catch (err) {
      return {formattedTokenAmount: zeroBalance, formattedLpAmount: zeroBalance}
    }
  }, [provider, walletAddress, chainId])

  const getUserBalance = useCallback( async () => {
    if (!walletAddress) {
      return '0'
    }

    let balance = 0
    const promises = balanceData.map(async ({vaultContract, lpTokenAddress, decimals, tokenAddress, rpc}) => {
      const vault = new Contract(
        vaultContract,
        crosschainVaultAbi,
        new ethers.providers.JsonRpcProvider(rpc)
      );

      const lpContract = new Contract(
        lpTokenAddress,
        lpTokenAbi,
        new ethers.providers.JsonRpcProvider(rpc)
      );

      try {
        const lpAmount = await vault.stargateLP(walletAddress, tokenAddress)
        const tokenAmount = await lpContract.amountLPtoLD(lpAmount?.toString())
        const formattedTokenAmount = ethers.utils.formatUnits(tokenAmount, decimals)
        balance += parseFloat(formattedTokenAmount)
      } catch (err) {
        console.log(err)
      }
    })

    await Promise.all(promises)
    setUserBalance(balance)
  }, [walletAddress])

  useEffect(() => {
    if(!walletAddress) {
      setUserBalance(0)
    }
    getUserBalance()
  }, [walletAddress, getUserBalance, refetch])

  return {
    depositToCrosschain,
    withdrawFromCrosschain,
    getLpAmount,
    getUserBalance,
    userBalance
  };
}
