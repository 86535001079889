/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { ReactComponent as ClockSVG } from "src/assets/img/icons/clock.svg";
import { Button, PageContainer } from "src/components/lib";
import {JoinModal, ThanksModal} from 'src/components/modals'
import { ModalType } from "src/constants/enums";
import { useModal } from "src/contexts/ModalContext";
import { Footer } from "src/layout/Footer";
import { Header } from "src/layout/Header";

export function ComingSoon() {
  const navigate = useNavigate();
  const { showModal, modalType } = useModal();

  const handleGoHome = (e) => {
    e.preventDefault();
    navigate("/");
  };
  const handleJoinWaitlist = () => {
    showModal(ModalType.JoinModal);
  };

  return (
    <>
      <PageContainer>
        <Header />
        <div className="relative flex items-center justify-center min-w-full min-h-[420px] lg:w-[739.49px] lg:min-w-min lg:min-h-[586.57px] lg:mt-[68px] mx-auto">
          <div className="text-center max-w-[335px]">
            <ClockSVG className="mx-auto" />
            <h2 className="text-[32px] lg:text-[44px] leading-[1.1] text-grey-dark dark:text-white mt-[9px]">
              Coming soon!
            </h2>
            <p className="text-normal text-[16px] leading-[1.2] tracking-[-0.5px] text-grey-dark dark:text-white mt-[8px]">
              The page for this pool is under development and will be added
              shortly. Stay tuned for updates
            </p>
            <Button
              type={3}
              className="mt-[20px] h-[50px] mx-auto"
              onClick={handleJoinWaitlist}
            >
              Join Waitlist
            </Button>
            <a
              href="/"
              className="block font-caption text-[14px] leading-[1.1] font-medium text-yellow-main hover:text-yellow-deep transition-all duration-300 mt-[9px]"
              onClick={handleGoHome}
            >
              Go Home
            </a>
          </div>
          <img
            src="/images/fancy/Logo-1.png"
            alt="Fancy Logo 1"
            className="absolute left-[45%] top-0 scale-[0.7] lg:scale-100 -translate-x-1/2"
          />
          <img
            src="/images/fancy/Logo-2.png"
            alt="Fancy Logo 2"
            className="absolute left-0 top-[30%] lg:top-[40%] scale-[0.5] lg:scale-100 -translate-y-1/2"
          />
          <img
            src="/images/fancy/Logo-3.png"
            alt="Fancy Logo 3"
            className="absolute left-[-10%] lg:left-0 bottom-0 lg:bottom-[10%] scale-[0.5] lg:scale-100"
          />
          <img
            src="/images/fancy/Logo-4.png"
            alt="Fancy Logo 4"
            className="absolute left-[55%] bottom-[-5%] lg:bottom-0 scale-[0.5] lg:scale-100 lg:-translate-x-1/2"
          />
          <img
            src="/images/fancy/Logo-5.png"
            alt="Fancy Logo 5"
            className="absolute right-0 bottom-[5%] lg:bottom-[20%] scale-[0.5] lg:scale-100 lg:-translate-x-1/2"
          />
          <img
            src="/images/fancy/Logo-6.png"
            alt="Fancy Logo 6"
            className="absolute right-0 top-[20%] scale-[0.5] lg:scale-100 lg:-translate-x-1/2"
          />
        </div>
        <div className="mt-auto pt-[24px] lg:pt-[72px]">
          <Footer />
        </div>
      </PageContainer>
      {modalType === ModalType.JoinModal && <JoinModal />}
      {modalType === ModalType.ThanksModal && <ThanksModal />}
    </>
  );
}
