import {Contract, ethers} from "ethers";
import {optimismRpc} from "../constants/optimism";
import {erc20abi} from "../constants/erc20abi";
import {useCallback} from "react";

export function useToken() {
  const getTokenSymbol = useCallback(async (tokenAddress) => {
    const token = new Contract(
      tokenAddress,
      erc20abi,
      new ethers.providers.JsonRpcProvider(optimismRpc)
    )
    const symbol = await token?.symbol()

    return symbol || ''
  }, [])

  return {
    getTokenSymbol
  }
}