import { Contract, ethers } from 'ethers';
import { useCallback } from 'react';
import { ModalType } from '../constants/enums';
import { erc20abi } from '../constants/erc20abi';
import { optimismPoolAddress, routerAddress } from '../constants/optimism';
import { routerABI } from '../constants/routerABI';
import { useModal } from '../contexts/ModalContext';
import { useWallet } from '../contexts/WalletContext';

export function useRouterContract() {
  const { provider, walletAddress } = useWallet();
  const { showModal } = useModal();

  const router = new Contract(routerAddress, routerABI, provider?.getSigner());

  const depositToPool = useCallback(
    async (
      selectedToken,
      amount,
      changeTxStatus,
      setIsTxSuccess,
      setSuccessDepositTxHash,
      refetchTotalValue,
      poolAddress,
    ) => {
      const token = new Contract(
        selectedToken?.tokenAddress,
        erc20abi,
        provider?.getSigner()
      );

      if (selectedToken.tokenSymbol !== 'ETH') {
        try {
          const allowance = await token.allowance(walletAddress, routerAddress);

          if (parseFloat(allowance) < +amount) {
            changeTxStatus(1);
            const tx = await token?.approve(routerAddress, amount);
            await tx.wait();
          }
        } catch {
          setIsTxSuccess(false);
          showModal(ModalType.DepositResultModal);
        }
      }

      if (selectedToken?.tokenSymbol === 'ETH') {
        try {
          const tx = await router?.depositNative(
            poolAddress,
            selectedToken?.tokenAddress,
            0,
            {
              value: amount.sub(ethers.utils.parseEther('0.0001')).toString(),
            }
          );
          changeTxStatus(2);
          await tx.wait();
          const resp = await tx.wait();
          setSuccessDepositTxHash(resp?.transactionHash);
          changeTxStatus(3);
          setIsTxSuccess(true);
          refetchTotalValue();
          showModal(ModalType.DepositResultModal);
        } catch (err) {
          setIsTxSuccess(false);
          showModal(ModalType.DepositResultModal);
        }
      } else {
        try {
          const tx = await router?.deposit(
            poolAddress,
            selectedToken?.tokenAddress,
            amount,
            selectedToken?.tokenAddress,
            0
          );
          changeTxStatus(2);
          const resp = await tx.wait();
          setSuccessDepositTxHash(resp?.transactionHash);
          changeTxStatus(3);
          setIsTxSuccess(true);
          refetchTotalValue();
          showModal(ModalType.DepositResultModal);
        } catch (err) {
          setIsTxSuccess(false);
          showModal(ModalType.DepositResultModal);
        }
      }
    },
    [router, provider]
  );

  const withdrawFromPool = useCallback(
    async (
      selectedToken,
      amount,
      changeTxStatus,
      setIsTxSuccess,
      setSuccessWithdrawTxHash,
      refetchTotalValue,
      poolAddress,
    ) => {
      const lpToken = new Contract(
        poolAddress,
        erc20abi,
        provider?.getSigner()
      )

      try {
        const allowance = await lpToken.allowance(walletAddress, routerAddress);

        if (parseFloat(allowance) < +amount) {
          changeTxStatus(1);
          const tx = await lpToken?.approve(routerAddress, amount);
          await tx.wait();
        }
      } catch {
        setIsTxSuccess(false);
        showModal(ModalType.WithdrawalResultModal);
      }

      try {
        const estimation = await router.estimateGas.withdraw(
          poolAddress,
          amount,
          selectedToken?.tokenAddress,
          0
        );
        const gasLimit = parseInt(estimation.toString()) * 1.5;

        const tx = await router?.withdraw(
          poolAddress,
          amount,
          selectedToken?.tokenAddress,
          0,
          // { gasLimit: Math.trunc(gasLimit) }
        );
        changeTxStatus(2);
        await tx.wait();
        const resp = await tx.wait();
        setSuccessWithdrawTxHash(resp?.transactionHash);
        changeTxStatus(3);
        refetchTotalValue();
        setIsTxSuccess(true);
        showModal(ModalType.WithdrawalResultModal);
      } catch (err) {
        setIsTxSuccess(false);
        showModal(ModalType.WithdrawalResultModal);
      }
    },
    [router, provider]
  );

  return {
    depositToPool,
    withdrawFromPool,
  };
}
