import React from 'react'
import {ReactComponent as ArrowRight} from 'src/assets/img/icons/right-arrow.svg'
import {ReactComponent as ArrowLeft} from 'src/assets/img/icons/left-arrow.svg'
import {generateNumbersArray} from "src/utils/helpers";

export const Pagination = ({ totalPages = 0, page, setPage }) => {
  const pageDown = () => {
    if (page - 1 <= 0) return
    setPage(page - 1)
  }
  const pageUp = () => {
    if (page + 1 > totalPages) return
    setPage(page + 1)
  }

  const renderArrows = !!totalPages && totalPages !== 1
  const lessThanSixPages = !!totalPages && totalPages <= 5
  const activeFirstPage = !!totalPages && totalPages > 5 && page === 1
  const notTheLastPages =
    !!totalPages && totalPages > 5 && page !== 1 && page + 3 < totalPages
  const lastPages =
    !!totalPages && totalPages > 5 && page !== 1 && page + 3 >= totalPages
  const renderPage = page + 3 === totalPages

  if(totalPages < 2) return null

  return (
    <div className="flex gap-[5px]">
      {renderArrows && (
        <PaginationButton onClick={pageDown}>
         <ArrowLeft className="fill-black dark:fill-yellow-dark dark:group-hover:fill-black"/>
        </PaginationButton>
      )}

      {lessThanSixPages &&
        generateNumbersArray(totalPages).map((number) => (
          <PaginationItem
            key={number}
            active={page === number}
            pageNumber={number}
            onClick={setPage}
            currentPage={page}
          />
        ))}

      {activeFirstPage &&
        generateNumbersArray(3).map((number) => (
          <PaginationItem
            key={number}
            active={page === number}
            pageNumber={number}
            onClick={setPage}
            currentPage={page}
          />
        ))}
      {activeFirstPage && (
        <>
          <PaginationButton>...</PaginationButton>
          <PaginationItem
            active={false}
            onClick={setPage}
            pageNumber={totalPages}
            currentPage={page}
          />
        </>
      )}

      {notTheLastPages && (
        <>
          <PaginationItem
            active={page === page - 1}
            pageNumber={page - 1}
            onClick={setPage}
            currentPage={page}
          />
          <PaginationItem
            active={true}
            pageNumber={page}
            onClick={setPage}
            currentPage={page}
          />
          <PaginationItem
            active={page === page + 1}
            pageNumber={page + 1}
            onClick={setPage}
            currentPage={page}
          />
        </>
      )}
      {notTheLastPages && (
        <>
          {renderPage ? (
            <PaginationItem
              active={page === page + 2}
              onClick={setPage}
              pageNumber={page + 2}
              currentPage={page}
            />
          ) : (
            <PaginationButton>...</PaginationButton>
          )}

          <PaginationItem
            active={false}
            onClick={setPage}
            pageNumber={totalPages}
            currentPage={page}
          />
        </>
      )}

      {lastPages && (
        <>
          <PaginationItem
            active={page === totalPages - 4}
            pageNumber={totalPages - 4}
            onClick={setPage}
            currentPage={page}
          />
          <PaginationItem
            active={page === totalPages - 3}
            pageNumber={totalPages - 3}
            onClick={setPage}
            currentPage={page}
          />
          <PaginationItem
            active={page === totalPages - 2}
            pageNumber={totalPages - 2}
            onClick={setPage}
            currentPage={page}
          />
          <PaginationItem
            active={page === totalPages - 1}
            pageNumber={totalPages - 1}
            onClick={setPage}
            currentPage={page}
          />
          <PaginationItem
            active={page === totalPages}
            pageNumber={totalPages}
            onClick={setPage}
            currentPage={page}
          />
        </>
      )}

      {renderArrows && (
        <PaginationButton onClick={pageUp}>
          <ArrowRight className="fill-black dark:fill-yellow-dark dark:group-hover:fill-black"/>
        </PaginationButton>
      )}
    </div>
  )
}

const PaginationItem = ({ active, pageNumber, onClick, currentPage }) => {
  const changePage = () => {
    if (currentPage === pageNumber) return
    onClick(pageNumber)
  }

  return (
    <PaginationButton onClick={changePage} active={active}>
      {pageNumber}
    </PaginationButton>
  )
}

const PaginationButton = ({children, onClick, active}) => {
  return (
    <button onClick={onClick} className={`group flex justify-center items-center w-[32px] h-[32px] border-[1px] border-solid border-grey-border dark:border-grey-darker3 rounded-[5px] text-grey-black dark:text-yellow-dark dark:hover:text-grey-black transition-all duration-300 hover:bg-grey-border dark:hover:bg-yellow-dark ${
      active ? "bg-yellow-dark dark:!text-grey-black " : ""
    }`}>
      {children}
    </button>
  )
}

