import React from 'react';

export function VaultStrategy() {
  return (
    <div className="w-full max-w-[907px]">
      <h3 className="font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
        Vault Summary:
      </h3>
      <p className="font-medium text-[14px] leading-[16.8px] text-grey-deep mt-[8px]">
        At the core of the vault strategy is a commitment to the dual objectives of yield maximization and risk mitigation:
      </p>
      <ol className="font-medium text-[14px] leading-[16.8px] text-grey-deep list-decimal ml-[20px]">
        <li className="pl-[6px]">
          <b>Goal:</b> The target of this vault is to provide balanced exposure to the
          best-performing stablecoin farming pools on the Optimism network.{' '}
        </li>
        <li className="pl-[6px]">
          <b>Yield Source:</b> This vault generates yield by allocating
          assets into some of the top-performing liquidity pools on decentralized exchanges,
          staking pools, lending pools, and hedging strategies on the Optimism network.
        </li>
        <li className="pl-[6px]">
          <b>Protocol Allocation:</b> This vault includes farms from established protocols within
          the Optimism ecosystem, such as Uniswap, Aave, Arrakis, Velodrome, and Toros.
          Asset Exposure: Asset inclusion criteria is focused primarily on stablecoins like USDT and USDC.e
        </li>
        <li className="pl-[6px]">
          <b>Asset Exposure:</b> Asset inclusion criteria is focused primarily on stablecoins like USDT and USDC.e
        </li>
        <li className="pl-[6px]">
          <b>Risk Assessment:</b> Out of thousands of available pools on the Optimism network,
          vault stringent selection criteria filter them to less than 50. From there,
          the focus is on maintaining stablecoin balance, protocol diversification,
          and yield maximization. For more information on the portfolio construction methodology, refer to the{' '}
          <a
            href="https://docs.oneclick.fi/1cc/vaults/methodology"
            target="_blank"
            rel="noreferrer"
            className="text-yellow-dark hover:text-yellow-deep"
          >
            Methodology Documentation
          </a>
          .
        </li>
        <li className="pl-[6px]">
          <b>Monitoring And Rebalancing:</b> The vault is monitored in real-time and
          rebalanced periodically to address yield changes and maintain strategic
          asset and protocol allocation, ensuring the portfolio remains aligned with its objective.
        </li>
      </ol>
    </div>
  );
}
