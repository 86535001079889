import React, {useMemo} from 'react';
import {
  HUNDRED_PERCENTS,
} from 'src/constants/optimism';
import {CapacityComponent} from "../../components/CapacityComponent";
import {EXPECTED_CROSSCHAIN_CAPACITY} from "../../../../constants/crosschain";

export function CrosschainCapacity({ capacity }) {
  const currentCapacity = useMemo( () => {
    return (capacity * HUNDRED_PERCENTS) / EXPECTED_CROSSCHAIN_CAPACITY
  }, [capacity])

  return (
    <CapacityComponent
      currentCapacity={currentCapacity}
      expectedCapacity={EXPECTED_CROSSCHAIN_CAPACITY}
    />
  );
}
