import { Contract, ethers } from 'ethers';
import { useCallback } from 'react';
import {optimismPoolAddress, optimismRpc, optimismStablePoolAddress} from '../constants/optimism'
import { optimismVaultABI } from '../constants/optimismVaultABI';

export function useOptimismStableContract() {
  const optimism = new Contract(
    optimismStablePoolAddress,
    optimismVaultABI,
    new ethers.providers.JsonRpcProvider(optimismRpc)
  );

  const getOptimismFees = useCallback(async () => {
    try {
      // return tuple from getFundSummary()
      // struct FundSummary {
      //   string name;                        // [0]
      //   uint256 totalSupply;                // [1]
      //   uint256 totalFundValue;             // [2]
      //   address manager;                    // [3]
      //   string managerName;                 // [4]
      //   uint256 creationTime;               // [5]
      //   bool privatePool;                   // [6]
      //   uint256 performanceFeeNumerator;    // [7]
      //   uint256 managerFeeNumerator;        // [8]
      //   uint256 managerFeeDenominator;      // [9]
      //   uint256 exitFeeNumerator;           // [10]
      //   uint256 exitFeeDenominator;         // [11]
      //   uint256 entryFeeNumerator;          // [12]
      // }
      const fees = await optimism?.getFundSummary();
      const performanceFeeNumerator = parseInt(fees?.[7]?.toString());
      const exitFeeNumerator = parseInt(0);
      const exitFeeDenominator = parseInt(fees?.[11]?.toString());
      const entryFeeNumerator = parseInt(fees?.[12]?.toString());
      const managerFeeNumerator = parseInt(fees?.[8]?.toString());
      const managerFeeDenominator = parseInt(fees?.[9]?.toString());
      const defaultDenominator = 10000;

      const performancePercent =
        (performanceFeeNumerator / defaultDenominator) * 100;
      const withdrawalPercent = (exitFeeNumerator / exitFeeDenominator) * 100;
      const entryPercent = (entryFeeNumerator / defaultDenominator) * 100;
      const managerFeePercent = (managerFeeNumerator / managerFeeDenominator) * 100

      return {
        performance: performancePercent,
        withdrawal: withdrawalPercent,
        entry: entryPercent,
        manager: managerFeePercent
      };
    } catch (err) {
      console.error(err);
    }
  }, [optimism]);

  const getTimeToWithdrawal = useCallback(
    async (walletAddress) => {
      try {
        const time = await optimism?.getExitRemainingCooldown(walletAddress);
        return parseInt(time?.toString());
      } catch (err) {
        console.error(err);
      }
    },
    [optimism]
  );

  return {
    getOptimismFees,
    getTimeToWithdrawal,
  };
}
