import {useCallback, useEffect, useState} from "react";
import {Contract, ethers} from "ethers";
import {crosschainVaultAbi} from "../constants/crosschainVaultAbi";
import {
  addresses, bibanceRpc, crosschainValutAddressBNB,
  crosschainValutAddressPolygon,
  lpAddresses,
  networkChainIds,
  polygonRpc,
  tokenDecimals
} from "../constants/crosschain";
import {JsonRpcProvider} from "@ethersproject/providers";
import {lpTokenAbi} from "../constants/lpTokenAbi";

export function useCrosschainCapacity() {
  const [capacity, setCapacity] = useState(0)

  const getCapacity = useCallback(async () => {
    const usdtDecimalsPolygon = tokenDecimals[addresses.poligon.usdt]
    const usdtDecimalsbinance = tokenDecimals[addresses.binance.usdt]

    const crosschainVaultPolygon = new Contract(
      crosschainValutAddressPolygon,
      crosschainVaultAbi,
      new JsonRpcProvider(polygonRpc)
    );

    const crosschainVaultBinance = new Contract(
      crosschainValutAddressBNB,
      crosschainVaultAbi,
      new JsonRpcProvider(bibanceRpc)
    );

    const lpContractPolygon = new Contract(
      lpAddresses[networkChainIds.poligon][addresses.poligon.usdt],
      lpTokenAbi,
      new JsonRpcProvider(polygonRpc)
    )

    const lpContractBinance = new Contract(
      lpAddresses[networkChainIds.binance][addresses.binance.usdt],
      lpTokenAbi,
      new JsonRpcProvider(bibanceRpc)
    )

    try {
      const totalPolygonLps = await crosschainVaultPolygon.totalStargateLP(addresses.poligon.usdt)
      const totalBinanceLps = await crosschainVaultBinance.totalStargateLP(addresses.binance.usdt)

      const usdtAmountPolygon = await lpContractPolygon.amountLPtoLD(totalPolygonLps?.toString())
      const usdtAmountBinabce = await lpContractBinance.amountLPtoLD(totalBinanceLps?.toString())

      const formattedAmountPolygon = ethers.utils.formatUnits(usdtAmountPolygon, usdtDecimalsPolygon)
      const formattedAmountBinance = ethers.utils.formatUnits(usdtAmountBinabce, usdtDecimalsbinance)

      const polygonAmount = parseFloat(formattedAmountPolygon).toFixed(3)
      const binanceAmount = parseFloat(formattedAmountBinance).toFixed(3)

      const sum =  parseFloat(polygonAmount) + parseFloat(binanceAmount)
      const result = parseFloat(sum.toFixed(2))

      setCapacity(result)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    getCapacity()
  }, [])

  return {
    capacity
  }

}