import {useEffect, useState} from 'react'
import { ReactComponent as CloseIcon } from "src/assets/img/icons/close.svg";
import { ReactComponent as DiscordIcon } from "src/assets/img/icons/discord-alt.svg";
import { ReactComponent as WarningSvg } from "src/assets/img/icons/warning.svg";
import { useDiscord } from "src/contexts/DiscordContext";
import { useModal } from "src/contexts/ModalContext";
import { useWallet } from "src/contexts/WalletContext";
import { useJoinWaitlist } from "src/hooks/useJoinWaitlist";
import { isMobileDevice, isValidEmail } from "src/utils/helpers";
import web3 from "web3";
import { WalletInput } from "../WalletInput";
import { Button, Input } from "../lib";
import {ModalType} from 'src/constants/enums'

export function JoinModal({ onSuccess, onClose }) {
  const { walletAddress } = useWallet();
  const {
    discordUser,
    isLoading: isLoadingDiscord,
    connectDiscord,
    retryDiscord,
  } = useDiscord();
  const { showModal, closeModal } = useModal();
  const { joinWaitlist, isLoading } = useJoinWaitlist();

  const storedWallet = localStorage.getItem("wait_list_wallet")
  const storedEmail = localStorage.getItem("wait_list_email")
  const discordId = localStorage.getItem("wait_list_discord");

  const [walletToJoin, setWalletToJoin] = useState(walletAddress || storedWallet);
  const [email, setEmail] = useState(storedEmail);
  const referralCode = window.localStorage.getItem('refCode')

  useEffect(() => {
    if (discordId) {
      showModal(ModalType.ThanksModal);
    }
  }, [discordId])

  if (discordId) {
    return null
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClick = () => {
    joinWaitlist({
      email,
      walletAddress: walletToJoin,
      discordUsername: discordUser.username,
      discordId: discordUser.id,
      discordEmail: discordUser.email,
      referralCode,
      onSuccess,
    });
  };

  const handleClose = () => {
    if (onClose) onClose();
    else {
      if (isMobileDevice()) window.close();
      else closeModal();
    }
  };

  return (
    <div className="fixed top-0 left-0 z-20 w-full h-full bg-white overflow-y-auto md:bg-grey-black43 animate-fadeIn">
      <div className="md:max-w-[646px] md:bg-none dark:bg-grey-dark dark:text-white md:mt-[64px] md:mx-auto">
        <div className="bg-white md:rounded-[8px] md:pt-[20px] md:pb-[25px] md:px-[32px] pt-[20px] pb-[18px] px-[24px]">
          <div className="flex h-[24px] items-center">
            <button
              onClick={handleClose}
              className="w-[12px] h-[12px] ml-auto bg-none border-none"
            >
              <CloseIcon className="stroke-grey-black dark:stroke-grey-deep" />
            </button>
          </div>
          <h3 className="font-normal text-[12px] leading-[15px] text-[#E9BE5C]">
            BOOST YOUR WAITLIST SPOT
          </h3>
          <h3 className="mt-[9.5px] font-medium md:text-[32px] text-[20px] md:leading-[35.2px] leading-[22.8px] text-[#1E1E1E]">
            This Feature is Coming Soon!
          </h3>
        </div>
        <div className="flex flex-col md:flex-row md:gap-[16px] gap-[40px] md:mt-[8px] bg-[#5865F2] rounded-[8px] md:py-[14px] md:px-[32px] py-[20px] px-[24px] bg-[url('assets/img/discord.png')]">
          <div className="order-1">
            <h3 className="font-medium text-[26px] leading-[28.6px] text-white">
              Join the Discord & earn 20-50% more points
            </h3>
            <h3 className="font-body font-light text-[12px] leading-[15px] text-left text-white pt-[5px]">
              Join
              <a
                href="https://discord.gg/nJREqWnCyd"
                target="_blank"
                rel="noreferrer"
                className="pl-[4px] pr-[4px] text-[#E9BE5C] font-normal underline"
              >
                 our Discord
              </a>
              and <span className="font-normal">claim your #Cliquer role</span> to gain extra points, and boost your spot on the waiting list.
              You will get extra points for reacting to messages in #announcement channel.
              Members who stay in Discord longer receive more points.
            </h3>
          </div>
          <div className="md:flex md:items-center order-2 md:min-w-[215px] relative flex-wrap">
            {discordUser ? (
              <>
                <div className="flex items-center justify-center px-[10px] py-[6px] rounded-[8px] w-full border-white border-[2px] font-caption font-bold leading-[1.25] text-white">
                  {
                    <img
                      className="rounded-full w-[40px] h-[40px]"
                      src={
                        !discordUser.imageUrl.match(/null\.png/)
                          ? discordUser.imageUrl
                          : 'https://cdn.discordapp.com/embed/avatars/0.png'
                      }
                      alt="Avatar"
                    />
                  }
                  <span className="ml-[4px] text-ellipsis overflow-hidden">{discordUser.username}</span>
                </div>
                {discordUser && !discordUser.joined && (
                  <div className="text-red-light mt-[8px] leading-[1.5] text-center w-full flex-wrap">
                    <p>
                      Please join to our{" "}
                      <a
                        href="https://discord.gg/nJREqWnCyd"
                        target="_blank"
                        rel="noreferrer"
                        className="underline whitespace-nowrap"
                      >
                        Discord server
                      </a>{" "}
                      first
                    </p>
                    <p>
                      Joined?{" "}
                      <a
                        className="underline cursor-pointer"
                        onClick={retryDiscord}
                      >
                        Retry
                      </a>
                    </p>
                  </div>
                )}
              </>
            ) : (
              <Button type={9} className="mt-[4px]" onClick={connectDiscord}>
                <DiscordIcon />
                {isLoadingDiscord ? "Connecting..." : "Connect Discord"}
              </Button>
            )}
          </div>
        </div>
        <div className="md:mt-[8px] bg-white md:rounded-[8px] md:pt-[14px] md:pl-[32px] md:pb-[14px] md:pr-[32px] pt-[18px] pl-[24px] pr-[24px]">
          <div className="flex-col md:flex-row flex gap-[10px]">
            <div className="w-full relative">
              <label htmlFor="wallet">Your Wallet Address:</label>
              <WalletInput
                id="wallet"
                placeholder="Your Wallet Address"
                defaultValue={walletToJoin}
                onChange={(v) => {
                  setWalletToJoin(v);
                }}
                disabled={!!storedWallet}
                buttonLabel="Edit"
                className={`max-w-full pt-[6px] py-[6px]`}
                errorMessage="Sorry, your wallet address is not supported"
              />
            </div>
            <div className="w-full">
              <label htmlFor="email">Your Email:</label>
              <Input
                id="email"
                type="email"
                value={email}
                placeholder="Your Email"
                autoComplete="off"
                onChange={handleEmailChange}
                disabled={!!storedEmail}
                className={`dark:bg-transparent dark:text-white w-full mt-[6px] ${email && !isValidEmail(email) ? 'border-red-lighter focus:border-red-lighter' : ''}`}
              />
              {
                email && !isValidEmail(email) &&
                <div
                  className="flex gap-[5.46px] mt-[8px] justify-start items-center font-normal text-red-lighter uppercase text-[12px] leading-[1.25]">
                  <WarningSvg/>
                  <p>
                    Email is not valid
                  </p>
                </div>
              }
            </div>
          </div>
          <Button
            type={19}
            className="mt-[54px] md:mt-[10px] mx-auto text-grey-dark"
            disabled={
              !discordUser?.joined ||
              isLoading ||
              !isValidEmail(email) ||
              (walletToJoin && !web3.utils.isAddress(walletToJoin)) ||
              !discordUser
            }
            onClick={handleClick}
          >
            {isLoading ? "Submitting ..." : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  )
}
