import React from 'react';
import {DepositWithdrawSection} from "./DepositWithdrawSection";
import {TitleSection} from "./TitleSection";
import {FeesSection} from "./FeesSection";
import {useOptimismValues} from "../../../contexts/OptimismContext";
import {OptimismCapacity} from "../VaultOptimism/components/OptimismCapacity";
import {CrosschainCapacity} from "../Crosschain/components/CrosschainCapacity";

export function OptimismInfo({
 feesData,
 assetsList,
 totalValue,
 profitAndLoss,
 timeLeftToWithdraw,
 isOptimism,
 sttrategyText,
 tvl,
 networkId,
 isBalance,
 title,
}) {
  const {tvlValue} = useOptimismValues()

  return (
    <div className="w-full max-w-[470px]">
      <TitleSection title={title} tvlValue={tvl !== undefined ? tvl : tvlValue} isOptimism={isOptimism}/>
      <div className="w-full mt-[14px]">
        <p className="text-[14px] leading-[16.8px] text-grey-deep">
          {sttrategyText}
        </p>
      </div>
      <DepositWithdrawSection
        totalValue={totalValue}
        profitAndLoss={profitAndLoss}
        assetsList={assetsList}
        timeLeftToWithdraw={timeLeftToWithdraw}
        isOptimism={isOptimism}
        networkId={networkId}
        isBalance={isBalance}
      />
      {isOptimism && <OptimismCapacity tvlValue={tvl !== undefined ? tvl : tvlValue}/>}
      {!isOptimism && <CrosschainCapacity capacity={tvl}/>}
      <FeesSection feesData={feesData} isOptimism={isOptimism}/>
    </div>
  )
}
