import axios from "axios";
import { useEffect, useState } from "react";

export function usePoolData({ poolId }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setError("");
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/pool/info/${poolId}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.message);
      });
  }, [poolId]);

  return {
    data,
    isLoading,
    error,
  };
}
