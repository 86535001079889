import React, {useEffect, useState} from 'react';
import {generateIcon, shortAddress} from "src/utils/helpers";
import axios from "axios";
import {optimismScanLink} from "src/constants/optimism";

export function TransactionsTable({ data }) {
  const shouldRender = data && data?.length
  return (
    <table className="w-full mt-[14px]">
      <tr className="font-normal text-grey-deep text-[12px] leading-[15px] border-b-2 border-solid border-grey-lightest-040">
        <th className="text-left pb-[8px]">Address</th>
        <th className="text-left pb-[8px]">Outgoing</th>
        <th className="text-left pb-[8px]">Incoming</th>
        <th className="pb-[8px]">Tx Type</th>
        <th className="text-right pb-[8px]">Time</th>
      </tr>
      {!!shouldRender && data?.map((item) => <TableRow key={item?.txHash} rowData={item} />)}
    </table>
  );
}

function TableRow ({ rowData }) {
  const time = new Date(rowData?.timestamp * 1000)
  
  const [outcomeImage, setOutcomeImage] = useState('')
  const [incomeImage, setIncomeImage] = useState('')

  const incomeData = rowData?.income?.[0]
  const outcomeData = rowData?.outcome?.[0]

  const getImage = async () => {
    const {data: outcome} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/image/token?address=${rowData?.outcome?.[0]?.asset?.toLowerCase()}`)
    const {data: income} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/image/token?address=${rowData?.income?.[0]?.asset?.toLowerCase()}`)
    if(outcome) {
      setOutcomeImage(outcome)
    } else {
      const generatedIcon = generateIcon(rowData?.outcome?.[0]?.assetName?.toLowerCase())
      setOutcomeImage(generatedIcon)
    }
    if(income) {
      setIncomeImage(income)
    } else {
      const generatedIcon = generateIcon(rowData?.income?.[0]?.assetName?.toLowerCase())
      setIncomeImage(generatedIcon)
    }
  }

  const txLink = `${optimismScanLink}tx/${rowData?.txHash}`;

  useEffect(() => {
    getImage()
  }, [rowData])

  return (
    <tr className="text-grey-black dark:text-white">
      <td className="font-caption font-medium text-[14px] leading-[15.4px] pt-[10px] underline">
        <a className="cursor-pointer" href={txLink} target="_blank" rel="noreferrer">
          {shortAddress(rowData?.txHash, 4)}
        </a>
      </td>
      <td className="font-normal text-[12px] leading-[15px] pt-[10px]">
        {outcomeData?.assetName && (
          <div className="flex flex-col md:flex-row items-center gap-[4px]">
            <img className="w-[16px] h-[16px]" src={outcomeImage} alt=""/>
            <p>{outcomeData?.displayAmount}</p>
            <p className="">{outcomeData?.assetName}</p>
          </div>
        )}
      </td>
      <td className="font-normal text-[12px] leading-[15px] pt-[10px]">
        {!!incomeData?.assetName && (
          <div className="flex flex-col md:flex-row items-center gap-[4px]">
            <img className="w-[16px] h-[16px]" src={incomeImage} alt=""/>
            <p>{incomeData?.displayAmount}</p>
            <p className="">{incomeData?.assetName}</p>
          </div>
        )}
      </td>
      <td className="text-center font-normal text-[12px] leading-[15px] pt-[10px]">
        <p className="min-w-[120px]">
          {rowData?.displayType}
        </p>
      </td>
      <td className="text-right font-normal text-[12px] leading-[15px] text-yellow-dark pt-[10px]">{time?.toDateString()}</td>
    </tr>
  )
}
