import React from 'react';
import USDTIcon from 'src/assets/img/tokens/usdt.png'

export function CrosschainAssets() {
  return (
    <div className="flex gap-[4px] mt-[8px]">
      <img className="w-[24px] h-[24px]" src={USDTIcon} alt="icon" />
    </div>
  );
}
