import React from 'react';
import { ReactComponent as Logo } from "src/assets/img/icons/logo.svg";

export function ChartEmptyState() {
  return (
    <div className="flex justify-center items-center w-full h-[335px]">
      <div className="flex flex-col gap-[8px] items-center">
        <Logo className="w-[62px] h-[57px]" />
        <p className="font-caption font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">No Data Yet</p>
        <p className="font-normal text-[14px] leading-[16.8px] text-grey-deep">You will see information here once there is activity</p>
      </div>
    </div>
  );
}
