import { RiskScoreChart } from "src/components/charts/RiskScoreChart";
import {ratingScaleRedefine} from "src/constants/ratingScaleRedefine";

export function RatingToolTip({
  entity = "pool",
  provider,
  link,
  score
}) {
  const isRedefine = provider.name === "Redefine";
  const redefineParams = isRedefine ? ratingScaleRedefine[score] : null;
  if (isRedefine && !redefineParams) {
    return null
  }

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex items-center">
        <img
          src={`/images/indexers/Logo-${provider.logoId}.png`}
          alt="Indexer"
          className="w-[20px] h-[20px] border-[0.59px] border-grey-lightest border-opacity-50 rounded-full object-cover"
        />
        <p className="text-[10px] ml-[2px] leading-[1.46] text-grey-dark dark:text-white">
          {provider.name}
        </p>
        {
            isRedefine &&
            <p className={`mt-[10px] h-[31px] ml-auto ${redefineParams.color}`}>
              {redefineParams.title}
            </p>
        }
        {
          !isRedefine &&
            <RiskScoreChart
                bgNone
                score={score}
                max={provider.max}
                small
                scoreClassName={score.toString().length > 4 ? "!text-[8.2px]" : "!text-[11.6px]"}
                className="w-[31px] h-[31px] ml-auto"
                provider={provider}
            />
        }
      </div>
      {isRedefine &&
        <p className="font-normal text-[12px] leading-[1.25]">
          This {entity} was rated by Redefine as "{redefineParams.title}".
          According to Redefine, it means: "{redefineParams.description}"
        </p>
      }
      {!isRedefine &&
        <p className="font-normal text-[12px] leading-[1.25]">
          This {entity} was rated by {provider.name} and received a score of{" "}
          {score}/{provider.max}
        </p>
      }
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="font-normal text-[12px] leading-[1.25] text-yellow-dark hover:text-yellow-deep underline"
        >
          Assessment link
        </a>
      )}
    </div>
  );
}
