import { format } from "date-fns";
import { useMemo, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useMobile } from "src/hooks/useMobile";
import { HexagonTickIcon } from "../icons/HexagonTickIcon";

// const periods = ["1D", "7D", "1M", "3M", "1Y", "ALL"];
const periods = ["3M", "1Y", "ALL"];

const PoolInfoBox = ({ label, value, delta, increased }) => {
  return (
    <div className="pl-[10px] pr-[14px] py-[12.5px]">
      <p className="text-[14px] leading-[15px] text-grey-deep">{label}</p>
      <p className="font-caption text-[14px] leading-[1.46] text-grey-dark dark:text-white">
        {value}
      </p>
      {/* <div className="flex items-center gap-[2.63px]">
        {increased ? (
          <UpArrowSvg className={`w-[10px] fill-green-lighter1`} />
        ) : (
          <DownArrowSvg className={`w-[10px] fill-red-light`} />
        )}
        <p
          className={`text-[14px] font-normal leading-[15px] ${
            increased ? "text-green-lighter1" : "text-red-light"
          }`}
        >
          {delta}
        </p>
      </div> */}
    </div>
  );
};

export function PoolChart({ data, poolInfo, className }) {
  const [period, setPeriod] = useState(periods[0]);
  const [selectedFields, setSelectedFields] = useState([
    "base_apy",
    "reward_apy",
    "tvl",
  ]); // base_apy, reward_apy, tvl

  const isMobile = useMobile();

  const { chartData, apyMax } = useMemo(() => {
    if (!data) return { chartData: null, apyMax: null };

    const now = new Date();
    const days = {
      "1D": 1,
      "7D": 7,
      "1M": 30,
      "3M": 90,
      "1Y": 365,
      ALL: -1,
    }[period];
    const selectedData =
      days === -1
        ? data
        : data.filter(
            (item) =>
              now.valueOf() - new Date(item.timestamp).valueOf() <=
              days * 24 * 60 * 60 * 1000
          );

    const labels = selectedData.map((item) =>
      format(new Date(item.timestamp), "MMM dd")
    );

    const _selectedFields = selectedFields.length
      ? selectedFields
      : ["base_apy", "reward_apy", "tvl"];

    const datasets = [];
    if (_selectedFields.includes("base_apy"))
      datasets.push({
        label: "Base APY",
        data: selectedData.map((item) => item.apyBase),
        backgroundColor: "#E9BE5C",
        borderRadius: Number.MAX_VALUE,
        // borderSkipped: "top",
        type: "bar",
        yAxisID: "y",
        maxBarThickness: 10,
      });
    if (_selectedFields.includes("reward_apy"))
      datasets.push({
        label: "Reward APY",
        data: selectedData.map((item) => item.apyReward) || 0,
        backgroundColor: "#32CC86",
        borderRadius: Number.MAX_VALUE,
        borderSkipped: "bottom",
        type: "bar",
        yAxisID: "y",
        maxBarThickness: 10,
      });
    if (_selectedFields.includes("tvl"))
      datasets.push({
        label: "TVL",
        data: selectedData.map((item) => item.tvlUsd),
        borderColor: "#1682FE4D",
        borderWidth: 1,
        backgroundColor: "#428DE421",
        fill: true,
        type: "line",
        pointRadius: 0,
        tension: 0.8,
        yAxisID: "y1",
      });

    const chartData = {
      labels,
      datasets: datasets,
    };

    const apyMax = Math.max(
      ...selectedData.map((item) => item.apyBase + (item.apyReward || 0))
    );

    return { chartData, apyMax };
  }, [data, period, selectedFields]);

  const handleFieldSelect = (field) => {
    setSelectedFields((prev) => {
      const index = prev.indexOf(field);
      if (index === -1) {
        return [...prev, field];
      } else {
        if (prev.length === 1) return ["base_apy", "reward_apy", "tvl"];
        else return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
    });
  };

  if (!data) return null;

  return (
    <div className={`flex flex-col animate-fadeIn ${className}`}>
      <div className="flex flex-col gap-[8px] lg:flex-row items-center justify-between order-2 lg:order-1">
        <div className="flex items-center flex-wrap order-2 lg:order-1 lg:flex-nowrap">
          <PoolInfoBox
            label={"TVL"}
            value={`$${Number(poolInfo.tvlUsd).toLocaleString()}`}
            delta={"12.47%"}
            increased={true}
          />
          <PoolInfoBox
            label={"Last 24h APY"}
            value={`${Number(poolInfo.apy).toFixed(2)}%`}
            delta={"2.47%"}
            increased={true}
          />
          {/* <PoolInfoBox
            label={"Depositors"}
            value={1619}
            delta={23}
            increased={true}
          />
          <PoolInfoBox
            label={"Average deposit"}
            value={"$574.32"}
            delta={"2.08%"}
            increased={true}
          />
          <PoolInfoBox
            label={"Transactions"}
            value={"11,518"}
            delta={87}
            increased={false}
          />
          <PoolInfoBox
            label={"Revenue"}
            value={"$3,439,805"}
            delta={"2.08%"}
            increased={true}
          /> */}
        </div>
        <div className="flex items-center order-1 lg:order-2 w-full lg:w-auto rounded-[8px] px-[6px] py-[4px] bg-grey-lightest-20 dark:bg-grey-darker5">
          {periods.map((item) => (
            <div
              key={item}
              className={`flex-1 font-bold text-[18px] text-grey-dark text-center dark:text-grey-deep leading-[1.2] tracking-[-0.5px] p-[8px] rounded-[8px] transition-all duration-300 cursor-pointer hover:bg-white hover:bg-opacity-70 dark:hover:bg-grey-dark ${
                item === period
                  ? "bg-white dark:bg-grey-black dark:text-yellow-dark"
                  : ""
              }`}
              onClick={() => setPeriod(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col lg:px-[12px] py-[14px] order-1 lg:order-2">
        <div className="flex items-center justify-center lg:justify-start gap-[16px] order-2 lg:order-1">
          <button
            className="flex items-center gap-[6px]"
            onClick={() => handleFieldSelect("base_apy")}
          >
            {selectedFields.includes("base_apy") ? (
              <HexagonTickIcon />
            ) : (
              <HexagonTickIcon
                bgColor="#00000000"
                borderColor="#A7A7A7"
                color="#00000000"
              />
            )}
            <p className="font-normal text-[14px] leading-[15px] text-grey-dark dark:text-white">
              Base APY
            </p>
          </button>
          <button
            className="flex items-center gap-[6px]"
            onClick={() => handleFieldSelect("reward_apy")}
          >
            {selectedFields.includes("reward_apy") ? (
              <HexagonTickIcon bgColor="#32CC86" borderColor="#32CC86" />
            ) : (
              <HexagonTickIcon
                bgColor="#00000000"
                borderColor="#A7A7A7"
                color="#00000000"
              />
            )}
            <p className="font-normal text-[14px] leading-[15px] text-grey-dark dark:text-white">
              Reward APY
            </p>
          </button>
          <button
            className="flex items-center gap-[6px]"
            onClick={() => handleFieldSelect("tvl")}
          >
            {selectedFields.includes("tvl") ? (
              <HexagonTickIcon bgColor="#1682FE" borderColor="#1682FE" />
            ) : (
              <HexagonTickIcon
                bgColor="#00000000"
                borderColor="#A7A7A7"
                color="#00000000"
              />
            )}
            <p className="font-normal text-[14px] leading-[15px] text-grey-dark dark:text-white">
              TVL
            </p>
          </button>
        </div>
        <Chart
          height={isMobile ? 393 : 100}
          options={{
            responsive: true,
            scales: {
              x: {
                stacked: true,
                border: { dash: [2, 3], color: "#00000000" },
                ticks: {
                  maxRotation: 0,
                  autoSkipPadding: 80,
                  color: "#A7A7A7",
                  padding: 16,
                },
                grid: {
                  drawTicks: false,
                  color: "#88888855",
                },
              },
              y: {
                border: { dash: [2, 3], color: "#00000000" },
                stacked: true,
                grid: {
                  drawTicks: false,
                  color: "#88888855",
                },
                beginAtZero: true,
                ticks: {
                  color: "#A7A7A7",
                  format: {
                    style: "unit",
                    unit: "percent",
                  },
                },
                suggestedMax: apyMax * 1.5,
              },
              y1: {
                border: { color: "#00000000" },
                display: isMobile ? false : true,
                stacked: true,
                grid: {
                  display: false,
                },
                position: "right",
                beginAtZero: true,
                ticks: {
                  color: "#A7A7A7",
                  format: {
                    style: "currency",
                    currency: "USD",
                  },
                },
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
          }}
          data={chartData}
          className="lg:mt-[30px] order-1 lg:order-2"
        />
      </div>
    </div>
  );
}
