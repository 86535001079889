import { useCallback, useState } from 'react'

export function useDropdown() {
  const [isOpen, setIsOpen] = useState(false)

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
  }, [])

  const openDropdown = useCallback(() => {
    setIsOpen(true)
  }, [])

  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  return {
    isOpen,
    closeDropdown,
    openDropdown,
    toggleDropdown,
  }
}
