import React from 'react';

export function VaultStrategy() {
  return (
    <div className="w-full max-w-[907px]">
      <h3 className="font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
        Vault Strategy
      </h3>
      <p className="font-medium text-[14px] leading-[16.8px] text-grey-deep mt-[8px]">
        At the core of our vault strategy is a commitment to the dual objectives
        of yield maximization and risk mitigation:
      </p>
      <ol className="font-medium text-[14px] leading-[16.8px] text-grey-deep list-decimal ml-[20px]">
        <li className="pl-[6px]">
          Goal: The target of the Optimism Yield Index is to provide a balanced
          exposure to the best-performing protocols, pools, and assets on the
          Optimism network. The index aims to maximize earning yield on the
          assets within it through various DeFi protocols on Optimism and is not
          a simple buy-and-hold strategy.{' '}
        </li>
        <li className="pl-[6px]">
          Pool Allocation: This index generates yield by allocating capital into
          some of the top-performing liquidity pools on decentralized exchanges,
          staking pools, and hedging strategies on the Optimism network.
        </li>
        <li className="pl-[6px]">
          Protocol Allocation: This index includes pools from established
          protocols within the Optimism ecosystem, such as Uniswap, Aave,
          Arrakis, Velodrome, and Toros.
        </li>
        <li className="pl-[6px]">
          Asset Exposure: Index asset inclusion criteria is focused primarily on
          the blue-chip coins such as ETH and BTC, stablecoins like USDT and
          USDC.e, and some of the biggest altcoins like OP, WETH, LINK, and
          others.
        </li>
        <li className="pl-[6px]">
          Risk Assessment: Out of thousands of available pools on the Optimism
          network, index stringent selection criteria filter them to less than
          50. From there, the focus is on maintaining stablecoin balance,
          protocol diversification, and yield maximization. This targeted
          approach significantly mitigates risk, ensuring a well-rounded
          portfolio in the dynamic Optimism ecosystem. For more information on
          the portfolio construction methodology, refer to the{' '}
          <a
            href="https://docs.oneclick.fi/1cc/vaults/methodology"
            target="_blank"
            rel="noreferrer"
            className="text-yellow-dark hover:text-yellow-deep"
          >
            Methodology Documentation
          </a>
          .
        </li>
        <li className="pl-[6px]">
          Monitoring And Rebalancing: The index is monitored in real-time and
          rebalanced periodically to promptly address market shifts and maintain
          strategic asset allocation, ensuring the portfolio remains aligned
          with its objective.
        </li>
      </ol>
    </div>
  );
}
