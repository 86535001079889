import axios from "axios";
import { useEffect, useState } from "react";

export function usePoolChartData({ poolId }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!poolId) return;
    setIsLoading(true);
    setError("");
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/pool/chart/${poolId}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e.message);
      });
  }, [poolId]);

  return {
    data,
    isLoading,
    error,
  };
}
