import { useEffect, useState } from "react";
import { calculateAverageColor } from "src/utils/helpers";

export function useYieldData({ poolData, poolInfo }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!poolData) return;
    if (!poolData?.asset_image_ids) return;

    const imageUrls = [
      ...poolData.asset_image_ids.map(
        (imageId) => `/images/assets/Logo-${imageId}.png`
      ),
    ];

    if (poolInfo?.rewardTokens) {
      imageUrls.push(
        ...poolInfo.rewardTokens.map(
          ({ logo }, index) => `/images/assets/Logo-${index + 1}.png`
        )
        // ...poolInfo.rewardTokens.map(({ logo }) => logo)
      );
    }

    setIsLoading(true);
    Promise.all(
      imageUrls.map((imageUrl) => calculateAverageColor(imageUrl))
    ).then((colors) => {
      const value = imageUrls.map((imageUrl, index) => ({
        imageUrl: imageUrl,
        color: colors[index],
        assetSymbol:
          index < poolData.asset_symbols.split(",").length
            ? poolData.asset_symbols.split(",")[index]
            : poolInfo?.rewardTokens?.[
                index - poolData.asset_symbols.split(",").length
              ]?.symbol,
      }));
      setData(value);
      setIsLoading(false);
    });
  }, [poolData, poolInfo]);

  return { data, isLoading };
}
