import { useQuery } from '@apollo/client';
import { Dhedge, Network, ethers } from '@dhedge/v2-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageContainer } from '../../../components/lib';
import { ConnectModal, DisconnectModal } from '../../../components/modals';
import { OptimismModal } from '../../../components/modals/OptimismModal';
import { TransactionResultModal } from '../../../components/modals/TransactionResultModal';
import { TransactionStatusModal } from '../../../components/modals/TransactionStatusModal';
import { assetsData } from '../../../constants/allocationChart';
import { ModalType } from '../../../constants/enums';
import { erc20abi } from '../../../constants/erc20abi';
import {
  optimismStabledepositTokenData,
  DAIAddress,
  DaiWethId,
  optimismChainId,
  optimismStablePoolAddress,
  optimismRpc, SNXAddress,
  transactionsLimit,
  transactionsLimitMobile, ULPAddress, USDCAddress, UsdcOpId, USDTAddress,
  walletPrivateKey, WETHAddress, EXPECTED_CAPACITY, zapperStableUrl, optimismStableDepositTokensAddresses,
} from '../../../constants/optimism'
import { useModal } from '../../../contexts/ModalContext';
import {
  OptimismProvider,
} from '../../../contexts/OptimismContext';
import { useWallet } from '../../../contexts/WalletContext';
import useNetworkSelector from '../../../hooks/useNetworkSelector';
import { useOptimismContract } from '../../../hooks/useOptimismContract';
import { usePagination } from '../../../hooks/usePagination';
import { useRouterContract } from '../../../hooks/useRouterContract';
import { Footer } from '../../../layout/Footer';
import { Header } from '../../../layout/Header';
import {
  formatFilteredAssetsForChart,
  formatFilteredPoolsForChart,
  formatProfitAndLoss,
  formatTotalValue, generateAsset,
  toFixed,
} from '../../../utils/helpers';
import { TokenSelectModal } from '../../SolanaBridgePage/components/TokenSelectModal';
import {
  PROFIT_AND_LOSS_QUERY,
  TOTAL_VALUE_QUERY,
  TRANSACTIONS_HISTORY_QUERY,
} from '../query';
import { ChartsSection } from '../components/ChartsSection';
import { OptimismInfo } from '../components/OptimismInfo';
import { SecurityComponent } from '../components/SecurityComponent';
import { Transactions } from '../components/Transactions';
import { VaultStrategy } from './components/VaultStrategy';
import {useToken} from "../../../hooks/useToken";
import axios from "axios";
import {OptimismStableProvider} from '../../../contexts/OptimismStableContext'
import {OptimismStableInfo} from '../components/OptimismStableInfo'
import {useOptimismStableContract} from '../../../hooks/useOptimismStableContract'

export function VaultOptimismStable() {
  const { provider, walletAddress, disconnect } = useWallet();
  const { modalType, closeModal, showModal } = useModal();
  const { getOptimismFees, getTimeToWithdrawal } = useOptimismStableContract();
  const { depositToPool, withdrawFromPool } = useRouterContract();

  const [tokenBalance, setTokenBalance] = useState('0');
  const [depositAmount, setDepositAmount] = useState('');
  const [chainIndex, setChainIndex] = useState(0);
  const [depositTokenIndex, setDepositTokenIndex] = useState(0);
  const [showingDepositTokenSelectModal, setShowingDepositTokenSelectModal] =
    useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [withdrawalTokenIndex, setWithdrawalTokenIndex] = useState(0);
  const [
    showingWithdrawalTokenSelectModal,
    setShowingWithdrawalTokenSelectModal,
  ] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(1);
  const [transactionWithdrawalStatus, setTransactionWithdrawalStatus] =
    useState(1);
  const [dhedgeSDK, setDhedgeSDK] = useState(null);
  const [feesData, setFeesData] = useState(null);
  const [depositAssetsList, setDepositAssetsList] = useState(null);
  const [allocationAssetsChartData, setAllocationAssetsChartData] =
    useState(null);
  const [allocationPoolsChartData, setAllocationPoolsChartData] =
    useState(null);
  const [isTxSuccess, setIsTxSuccess] = useState(false);
  const [successDepositTxHash, setSuccessDepositTxHash] = useState('');
  const [successWithdrawTxHash, setSuccessWithdrawTxHash] = useState('');
  const [lpTokenBalance, setLpTokenBalance] = useState('0');
  const [timeLeftToWithdraw, setTimeLeftToWithdraw] = useState(0);

  const [termsAgreement, setTermsAgreement] = useState(false)
  const [riskAgreement, setRiskAgreement] = useState(false)

  const {getTokenSymbol} = useToken()

  const getPoolInfo = useCallback(async () => {
    if (!dhedgeSDK) return;
    const pool = await dhedgeSDK?.loadPool(optimismStablePoolAddress);
    let assets = await pool?.getComposition();
    if (!assets || !assets?.length) {
      setDepositAssetsList([]);
      setAllocationPoolsChartData([]);
      setAllocationAssetsChartData([]);
    }

    const filteredDepositAssets = assets.filter((item) => item?.isDeposit && optimismStableDepositTokensAddresses.includes(item.asset));
    setDepositAssetsList(filteredDepositAssets);

    const ulpAsset = assets?.find(item => item?.asset?.toLowerCase() === ULPAddress?.toLowerCase())

    if (ulpAsset) {
      try {
        const { data } = await axios.get(zapperStableUrl)
        const UsdcOpBalance = data?.balances?.[optimismStablePoolAddress]?.products?.[0]?.assets?.[0]
        const DaiWethBalance = data?.balances?.[optimismStablePoolAddress]?.products?.[0]?.assets?.[1]

        if (UsdcOpBalance && DaiWethBalance) {
          const formattedUsdcOpBalance = ethers.utils.parseEther(UsdcOpBalance?.balanceUSD?.toString())
          const formattedDaiWethBalance = ethers.utils.parseEther(DaiWethBalance?.balanceUSD?.toString())

          const rate = ethers.utils.parseEther('1')

          const UsdcOpAsset = generateAsset(UsdcOpId, formattedUsdcOpBalance, rate)
          const DaiWethAsset = generateAsset(DaiWethId, formattedDaiWethBalance, rate)
          const DaiAsset = generateAsset(DAIAddress, rate, rate)

          assets = assets?.filter(item => item?.asset?.toLowerCase() !== ULPAddress?.toLowerCase())
          assets.push(UsdcOpAsset)
          assets.push(DaiWethAsset)
          assets.push(DaiAsset)
        }
      } catch (err) {
        console.error(err)
      }
    }

    const sortedAssets = assets.sort((a, b) => {
      const aDecimals = assetsData[a?.asset]?.decimals || 18
      const bDecimals = assetsData[b?.asset]?.decimals || 18
      const aValue = a?.balance
        .mul(a?.rate)
        .div(ethers.BigNumber.from(10).pow(aDecimals + 18));
      const bValue = b?.balance
        .mul(b?.rate)
        .div(ethers.BigNumber.from(10).pow(bDecimals + 18));
      return bValue.sub(aValue).toNumber();
    });

    const filteredPoolsData = sortedAssets.filter((item) => {
     return item?.balance.gt(0)
    });

    const filteredAssetsData = sortedAssets.filter((item) => {
      const assetInfo = assetsData[item?.asset]
      if (!assetInfo) {
        return false
      }

      if (
        item?.asset === USDTAddress ||
        item?.asset === SNXAddress ||
        item?.asset === USDCAddress ||
        item?.asset === WETHAddress
      ) {
        return true
      }

      return item?.balance.gt(0)
    });

    const promises = filteredPoolsData.map(async (item) => {
        const itemData = assetsData[item?.asset]
        if (!itemData) {
          const symbol = await getTokenSymbol(item.asset)
          return {
            ...item, symbol: symbol || ''
          }
        } else {
          return item
        }
    })

    const formattedPoolData = await Promise.all(promises)

    const formattedFilteredPoolsData =
      formatFilteredPoolsForChart(formattedPoolData);
    const formattedFilteredAssetsData =
      formatFilteredAssetsForChart(filteredAssetsData);
    setAllocationPoolsChartData(formattedFilteredPoolsData);
    setAllocationAssetsChartData(formattedFilteredAssetsData);
  }, [dhedgeSDK]);

  const { data, loading } = useQuery(TRANSACTIONS_HISTORY_QUERY, {
    variables: {
      filter: {
        timeFrom: 0,
        fundAddresses: [optimismStablePoolAddress],
      },
      limit: 1000
    },
  });

 const txCount = useMemo(() => {
   if(data?.tradeEvents && data?.tradeEvents?.length) {
     return data?.tradeEvents?.length
   }
   return 0
 }, [data])

  const { renderList, totalPages, page, changePage } = usePagination(
    data?.tradeEvents,
    loading,
    transactionsLimit
  );

  const { renderList: mobileRenderList, totalPages: mobileTotalPages, page: mobilePage, changePage: mobileChangePage } = usePagination(
    data?.tradeEvents,
    loading,
    transactionsLimitMobile
  );

  const { data: totalValueData, refetch } = useQuery(TOTAL_VALUE_QUERY, {
    variables: { investorAddress: walletAddress },
  });

  console.log(totalValueData, 'totalValueData')
  const { data: profitAndLossData } = useQuery(PROFIT_AND_LOSS_QUERY, {
    variables: { investorAddress: walletAddress },
  });

  const totalValue = useMemo(() => {
    return formatTotalValue(totalValueData, optimismStablePoolAddress);
  }, [totalValueData]);

  const profitAndLoss = useMemo(() => {
    return formatProfitAndLoss(profitAndLossData, optimismStablePoolAddress);
  }, [profitAndLossData]);

  const { getNetworkId, setWalletNetwork } = useNetworkSelector();

  const handleDisconnect = () => {
    disconnect();
    closeModal();
  };

  const handleCloseSelectTokenModal = () => {
    setShowingDepositTokenSelectModal(false);
    showModal(ModalType.DepositModal);
  };

  const handleCloseWithdrawalSelectTokenModal = () => {
    setShowingWithdrawalTokenSelectModal(false);
    showModal(ModalType.WithdrawModal);
  };

  const getFeesData = async () => {
    const data = await getOptimismFees();
    if (data) {
      setFeesData(data);
    }
  };

  const fetchTokenBalance = useCallback(
    async (chainIndex, tokenIndex, tokenAddress) => {
      if (!walletAddress || !provider) {
        return;
      }

      let erc20Contract;
      const tokenInfo = optimismStabledepositTokenData[chainIndex].tokens[tokenIndex];

      if (tokenAddress) {
        erc20Contract = new ethers.Contract(
          optimismStablePoolAddress,
          erc20abi,
          provider
        );
      } else {
        if (tokenInfo?.tokenSymbol === 'ETH') {
          const ethBalance = await window.ethereum.request({
            method: 'eth_getBalance',
            params: [walletAddress],
          });
          const formattedBalance = ethers.utils.formatEther(ethBalance);
          setTokenBalance(formattedBalance);
          return;
        } else {
          erc20Contract = new ethers.Contract(
            tokenInfo.tokenAddress,
            erc20abi,
            provider
          );
        }
      }
      try {
        const balance = await erc20Contract.balanceOf(walletAddress);
        let walletBalance;
        if (tokenAddress) {
          walletBalance = ethers.utils.formatEther(balance);
        } else {
          walletBalance = ethers.utils.formatUnits(balance, tokenInfo.decimals);
        }
        if (tokenAddress) {
          setLpTokenBalance(toFixed(walletBalance, 6));
        } else {
          setTokenBalance(toFixed(walletBalance, 6));
        }
      } catch (err) {
        console.error(err);
      }
    },
    [walletAddress, provider]
  );

  const handleDepositTokenSelect = async (chainIndex, tokenIndex) => {
    // const networkId = await getNetworkId();
    const networkId = optimismChainId;
    fetchTokenBalance(chainIndex, tokenIndex);
    if (networkId !== optimismStabledepositTokenData[chainIndex].chainId) {
      const status = await setWalletNetwork(
        optimismStabledepositTokenData[chainIndex].chainId
      );
      if (status) {
        setChainIndex(chainIndex);
        setDepositTokenIndex(tokenIndex);
        handleCloseSelectTokenModal();
      }
    } else {
      setChainIndex(chainIndex);
      setDepositTokenIndex(tokenIndex);
      handleCloseSelectTokenModal();
    }
  };

  const handleWithdrawalTokenSelect = async (chainIndex, tokenIndex) => {
    // const networkId = await getNetworkId();
    const networkId = optimismChainId;
    if (networkId !== optimismStabledepositTokenData[chainIndex].chainId) {
      const status = await setWalletNetwork(
        optimismStabledepositTokenData[chainIndex].chainId
      );
      if (status) {
        setChainIndex(chainIndex);
        setWithdrawalTokenIndex(tokenIndex);
        handleCloseWithdrawalSelectTokenModal();
      }
    } else {
      setChainIndex(chainIndex);
      setWithdrawalTokenIndex(tokenIndex);
      handleCloseWithdrawalSelectTokenModal();
    }
  };

  const openSelectTokenModal = () => {
    closeModal();
    setShowingDepositTokenSelectModal(true);
  };

  const openWithdrawalSelectTokenModal = () => {
    closeModal();
    setShowingWithdrawalTokenSelectModal(true);
  };

  const onOpenDepositModal = () => {
    fetchTokenBalance(chainIndex, depositTokenIndex, '');
  };

  const onOpenWithdrawalModal = () => {
    fetchTokenBalance(chainIndex, null, optimismStablePoolAddress);
  };

  const resetTransactionStatus = () => {
    setTransactionStatus(1);
  };

  const resetWithdrawalTransactionStatus = () => {
    setTransactionWithdrawalStatus(1);
  };

  const handleDeposit = useCallback(async () => {
    const depositTokenInfo = optimismStabledepositTokenData[0].tokens[depositTokenIndex];
    const depositAmountRaw = ethers.utils.parseUnits(
      depositAmount,
      depositTokenInfo.decimals
    );

    await depositToPool(
      depositTokenInfo,
      depositAmountRaw,
      setTransactionStatus,
      setIsTxSuccess,
      setSuccessDepositTxHash,
      refetch,
      optimismStablePoolAddress,
    );
  }, [depositAmount, depositTokenIndex, depositToPool]);

  const openDepositTransactionStatusModal = useCallback(() => {
    if (!depositAmount && !parseFloat(depositAmount)) {
      return;
    }
    handleDeposit();
    closeModal();
    showModal(ModalType.DepositTransactionStatusModal);
  }, [depositAmount]);

  const convertMaxLPTokenToMaxToken = () => {
    const tokenAddress = optimismStabledepositTokenData[chainIndex].tokens[withdrawalTokenIndex].tokenAddress
    const tokenInfo = depositAssetsList.find((depositAsset)=> depositAsset.asset == tokenAddress)
    const rate = tokenInfo.rate
    const rateFloat = parseFloat(ethers.utils.formatUnits(rate, 18))
    const maxLpTokenInDollar = parseFloat(totalValue)
    const maxToken = maxLpTokenInDollar / rateFloat
    return maxToken.toFixed(6)
  }

  const convertTokenToLp = (tokenAmountInput) => {
    const tokenAmount = parseFloat(tokenAmountInput)
    const maxToken = parseFloat(convertMaxLPTokenToMaxToken())
    const maxLpToken = parseFloat(lpTokenBalance)
    const lpAmount = tokenAmount * maxLpToken / maxToken
    return lpAmount.toFixed(6)
  }

  const handleWithdraw = async () => {
    const depositTokenInfo = optimismStabledepositTokenData[0].tokens[withdrawalTokenIndex];
    const withdrawalLpAmount = convertTokenToLp(withdrawalAmount)
    const amount = ethers.utils.parseUnits(withdrawalLpAmount, 18);
    await withdrawFromPool(
      depositTokenInfo,
      amount,
      setTransactionWithdrawalStatus,
      setIsTxSuccess,
      setSuccessWithdrawTxHash,
      refetch,
      optimismStablePoolAddress,
    );
  };

  const openWithdrawTransactionStatusModal = () => {
    handleWithdraw();
    closeModal();
    showModal(ModalType.WithdrawalTransactionStatusModal);
  };

  const getColdownTime = async (address) => {
    const coldownTime = await getTimeToWithdrawal(address);
    setTimeLeftToWithdraw(coldownTime);
  };

  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(optimismRpc);
    const walletWithProvider = new ethers.Wallet(walletPrivateKey, provider);
    const dhedge = new Dhedge(walletWithProvider, Network.OPTIMISM);
    setDhedgeSDK(dhedge);

    getFeesData();
  }, []);

  useEffect(() => {
    getPoolInfo();
  }, [getPoolInfo]);

  useEffect(() => {
    if (!walletAddress) return;

    if (modalType === ModalType.ConnectModal) {
      closeModal();
    }
  }, [closeModal, walletAddress, modalType]);

  useEffect(() => {
    if (!walletAddress) return;
    getColdownTime(walletAddress);
    setInterval(() => {
      getColdownTime(walletAddress);
    }, 10000);
  }, [walletAddress]);

  return (
    <OptimismStableProvider>
      <PageContainer>
        <Header />
        <div className="flex flex-col gap-[20px] max-w-[470px] lg:max-w-[unset] w-full px-[24px] lg:px-[64px] py-[24px] lg:py-[54px] lg:flex-row mx-auto">
          <OptimismStableInfo
            isOptimism={true}
            feesData={feesData}
            assetsList={depositAssetsList}
            totalValue={totalValue}
            profitAndLoss={profitAndLoss}
            timeLeftToWithdraw={timeLeftToWithdraw}
            title="USD Yield Vault"
            sttrategyText="This vault provides diversified stablecoin farming exposure to the leading high-performing yield-generating protocols on the Optimism network."
          />
          <ChartsSection
            isStable={true}
            allocationPoolsChartData={allocationPoolsChartData}
            allocationAssetsChartData={allocationAssetsChartData}
          />
        </div>
        <div className="flex flex-col gap-[32px] w-full mt-[32px] px-[24px] lg:px-[64px] lg:flex-row ">
          <VaultStrategy />
          <SecurityComponent
            text="
              This vault is powered by vault infrastructure built on top of dHedge protocol.
              The vault infrastructure has been audited by iosiro and four times by CertiK.
              It had no incidents since its release in 2021. Additionally, insurance is available through InsurAce protocol.
            "
          />
        </div>
        {
          !!renderList?.length && (
            <Transactions
              data={renderList}
              txCount={txCount}
              page={page}
              changePage={changePage}
              totalPages={totalPages}
              mobileData={mobileRenderList}
              mobilePage={mobilePage}
              mobileChangePage={mobileChangePage}
              mobileTotalPages={mobileTotalPages}
            />
          )
        }
        <div className="mt-auto pt-[72px]">
          <Footer />
        </div>
      </PageContainer>
      {showingDepositTokenSelectModal && (
        <TokenSelectModal
          title={'Deposit as'}
          data={optimismStabledepositTokenData}
          chainIndex={chainIndex}
          tokenIndex={depositTokenIndex}
          onSelect={handleDepositTokenSelect}
          onClose={handleCloseSelectTokenModal}
          className="lg:mt-[180px]"
        />
      )}
      {showingWithdrawalTokenSelectModal && (
        <TokenSelectModal
          title={'Withdrawal as'}
          data={optimismStabledepositTokenData}
          chainIndex={chainIndex}
          tokenIndex={withdrawalTokenIndex}
          onSelect={handleWithdrawalTokenSelect}
          onClose={handleCloseWithdrawalSelectTokenModal}
          className="lg:mt-[180px]"
        />
      )}
      {modalType === ModalType.ConnectModal && <ConnectModal />}
      {modalType === ModalType.DepositTransactionStatusModal && (
        <TransactionStatusModal
          type="Deposit"
          step={transactionStatus}
          onModalClose={resetTransactionStatus}
          onBack={() => showModal(ModalType.DepositModal)}
        />
      )}
      {modalType === ModalType.WithdrawalTransactionStatusModal && (
        <TransactionStatusModal
          type="Withdraw"
          step={transactionWithdrawalStatus}
          onModalClose={resetWithdrawalTransactionStatus}
          onBack={() => showModal(ModalType.WithdrawModal)}
        />
      )}
      {modalType === ModalType.DepositModal && (
        <OptimismModal
          title="Deposit"
          buttonText="Deposit"
          label="Buy with"
          feeText="Entry Fee"
          feeValue={feesData?.entry}
          depositAMount={depositAmount}
          setDepositAmount={setDepositAmount}
          tokenData={optimismStabledepositTokenData}
          tokenIndex={depositTokenIndex}
          chainIndex={chainIndex}
          tokenBalance={tokenBalance}
          convertMaxLPTokenToMaxToken={convertMaxLPTokenToMaxToken}
          depositAssetsList={depositAssetsList}
          onOpen={onOpenDepositModal}
          openSelectTokenModal={openSelectTokenModal}
          openTransactionStatusModal={openDepositTransactionStatusModal}
          isDeposit={true}
          termsAgreement={termsAgreement}
          riskAgreement={riskAgreement}
          setTermsAgreement={setTermsAgreement}
          setRiskAgreement={setRiskAgreement}
        />
      )}
      {modalType === ModalType.WithdrawModal && (
        <OptimismModal
          title="Withdrawal"
          buttonText="Withdrawal"
          label="Set the amount"
          feeText="Withdrawal Fee"
          depositAMount={withdrawalAmount}
          setDepositAmount={setWithdrawalAmount}
          tokenData={optimismStabledepositTokenData}
          tokenIndex={withdrawalTokenIndex}
          chainIndex={chainIndex}
          openSelectTokenModal={openWithdrawalSelectTokenModal}
          openTransactionStatusModal={openWithdrawTransactionStatusModal}
          tokenBalance={lpTokenBalance}
          totalValue={totalValue}
          convertMaxLPTokenToMaxToken={convertMaxLPTokenToMaxToken}
          depositAssetsList={depositAssetsList}
          onOpen={onOpenWithdrawalModal}
          isDeposit={false}
          // error={true}
        />
      )}
      {modalType === ModalType.DepositResultModal && (
        <TransactionResultModal
          transactionName="Deposit"
          tokenSymbol={
            optimismStabledepositTokenData[chainIndex].tokens[depositTokenIndex].tokenSymbol
          }
          tokenImageId={
            optimismStabledepositTokenData[chainIndex].tokens[depositTokenIndex].tokenImageId
          }
          chainImageName={optimismStabledepositTokenData[chainIndex].chainImageName}
          success={isTxSuccess}
          onBack={() => showModal(ModalType.DepositModal)}
          successTxHash={successDepositTxHash}
          amount={depositAmount}
        />
      )}
      {modalType === ModalType.WithdrawalResultModal && (
        <TransactionResultModal
          transactionName="Withdrawal"
          tokenSymbol={
            optimismStabledepositTokenData[chainIndex].tokens[withdrawalTokenIndex]
              .tokenSymbol
          }
          tokenImageId={
            optimismStabledepositTokenData[chainIndex].tokens[withdrawalTokenIndex]
              .tokenImageId
          }
          chainImageName={optimismStabledepositTokenData[chainIndex].chainImageName}
          success={isTxSuccess}
          onBack={() => showModal(ModalType.WithdrawModal)}
          successTxHash={successWithdrawTxHash}
          amount={withdrawalAmount}
        />
      )}
      {modalType === ModalType.DisconnectModal && (
        <DisconnectModal handleDisconnect={handleDisconnect} />
      )}
    </OptimismStableProvider>
  );
}
