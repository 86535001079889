import { format, parseISO } from 'date-fns';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ReactComponent as GreenIcon } from 'src/assets/img/icons/hexagon-green.svg';
import { useOptimismValues } from '../../../contexts/OptimismContext';
import { formatPerformanceForChart } from '../../../utils/helpers';
import { ChartEmptyState } from './ChartEmptyState';
import { ChartTabs } from './ChartTabs';
import { ChartTooltip } from './ChartTooltip';

export function PerformanceChart() {
  const {
    setPerformancePeriod,
    performancePeriod,
    performanceData,
    performanceApyChangeData,
  } = useOptimismValues();

  const chartData = formatPerformanceForChart(performanceData);
  const formatYAxis = (number) => `${number.toFixed(1)}%`;
  const formatXAxis = (dateValue) => {
    const date = parseISO(dateValue);
    if (date.getDate()) {
      return format(date, 'd LLL');
    } else {
      return '';
    }
  };
  const tooltipFormatter = (payload) => {
    return `${payload[0]?.value?.toFixed(1)}%`;
  };

  return (
    <>
      {!chartData?.length && <ChartEmptyState />}
      {!!chartData?.length && (
        <div className="max-w-[470px] lg:max-w-[unset] hidden md:block">
          <ChartTabs
            data={performanceApyChangeData}
            activeTab={performancePeriod}
            setActiveTab={setPerformancePeriod}
          />
        </div>
      )}
      {!!chartData?.length && (
        <div className="max-w-[470px] lg:max-w-[unset] w-full mt-[10px] text-[14px] font-normal leading-[15px] text-grey-deep">
          <ResponsiveContainer width="100%" height={370} style={{marginLeft:'-15px'}}>
            <AreaChart data={chartData}>
              <defs>
                <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#37CD89" stopOpacity={0.4} />
                  <stop offset="75%" stopColor="#37CD89" stopOpacity={0.02} />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                stroke="#37CD8950"
                fill="url(#color)"
                type="monotone"
              />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tickCount={7}
                tickFormatter={formatXAxis}
              />
              <YAxis
                dataKey="value"
                axisLine={false}
                tickLine={false}
                tickCount={9}
                tickFormatter={formatYAxis}
              />
              <Tooltip
                content={
                  <ChartTooltip
                    title="Change since start"
                    formatter={tooltipFormatter}
                    Icon={GreenIcon}
                  />
                }
              />
              <CartesianGrid vertical={false} opacity={0.2} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
      {!!chartData?.length && (
        <div className="block md:hidden">
          <ChartTabs
            data={performanceApyChangeData}
            activeTab={performancePeriod}
            setActiveTab={setPerformancePeriod}
          />
        </div>
      )}
    </>
  );
}
