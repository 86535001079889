import React, {useState} from 'react';
import {AllocationTabState, ChartTabState} from "../../../../constants/enums";
import {ChartsSectionTabs} from "../../components/ChartsSectionTabs";
import {AllocationChart} from "../../components/AllocationChart";
import {getCrosschainAllocationData} from "../../../../utils/helpers";

const Tabs = [
  { title: 'Allocation', value: ChartTabState.allocation },
]

const AllocationTabs = [
  { title: 'By Pools', value: AllocationTabState.pools} ,
  { title: 'By Assets', value: AllocationTabState.assets },
  { title: 'By Chains', value: AllocationTabState.chains },
]

export function CrosschainChartsSection() {
  const [activeTab] = useState(ChartTabState.allocation)
  const [activeAllocationTab, setActiveAllocationTab] = useState(AllocationTabState.pools)

  const allocationChartData = getCrosschainAllocationData(activeAllocationTab)

  return (
    <div className="w-full max-w-[470px] lg:max-w-[830px]">
      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <p
          className={`py-[7px] px-[8px]  font-bold text-[16px] leading-[16.8px] text-grey-dark dark:text-grey-deep`}
        >
          Allocation
        </p>
        {activeTab === ChartTabState.allocation && (
          <ChartsSectionTabs
            activeTab={activeAllocationTab}
            setActiveTab={setActiveAllocationTab}
            tabs={AllocationTabs}
          />
        )}
      </div>
      {activeTab === ChartTabState.allocation && <AllocationChart allocationChartData={allocationChartData}/>}
    </div>
  );
}
