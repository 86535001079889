import React from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/img/icons/close.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/img/icons/hexagon-error.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/img/icons/hexagon-success.svg';
import {
  optimismPoolAddress,
  optimismScanLink,
} from '../../constants/optimism';
import { useModal } from '../../contexts/ModalContext';
import { AssetWithChain } from '../../pages/SolanaBridgePage/components/AssetWithChain';
import { Button } from '../lib';
import {generateAsset, getContractAddress, toFixed} from "../../utils/helpers";

export function TransactionResultModal({
  transactionName,
  tokenSymbol,
  tokenImageId,
  chainImageName,
  success = true,
  onBack,
  successTxHash,
  amount,
  network,
  networkId,
  scan
}) {
  const { closeModal } = useModal();

  const vaultAddress = getContractAddress(networkId)
  const linkContractAddress = networkId ? vaultAddress : optimismPoolAddress

  const txLink = `${scan ? scan : optimismScanLink}tx/${successTxHash}`;
  const vaultLink = `${scan ? scan : optimismScanLink}address/${linkContractAddress}`;

  const formattedAmount = toFixed(parseFloat(amount), 6)

  return (
    <div className="fixed top-0 left-0 z-20 w-full h-full animate-fadeIn bg-grey-black43">
      <div className="absolute bg-texture bg-cover bg-white dark:bg-grey-dark lg:bg-none w-full lg:w-auto h-full lg:h-auto pt-[81px] lg:pt-[18px] pb-[24px] px-[24px] top-0 lg:top-[171px] left-0 lg:left-1/2 lg:-translate-x-1/2 lg:rounded-[8px]">
        <button
          onClick={closeModal}
          className="w-[12px] h-[12px] absolute top-[49px] lg:top-[22px] right-[30px] flex justify-center items-center bg-none border-none"
        >
          <CloseIcon className="stroke-grey-black dark:stroke-grey-deep" />
        </button>
        <div className="flex flex-col mt-[29px] lg:w-[444px]">
          <div className="w-[80px] mx-auto relative">
            {success && (
              <SuccessIcon className="absolute top-[-4px] left-[-4px] z-50 w-[29px] h-[32px]" />
            )}
            {!success && (
              <ErrorIcon className="absolute top-[-4px] left-[-4px] z-50 w-[29px] h-[32px]" />
            )}
            <AssetWithChain
              tokenImageId={tokenImageId}
              tokenSymbol={tokenSymbol}
              chainImageName={chainImageName}
              size={78}
            />
          </div>
          <p className="font-caption font-medium text-[32px] leading-[35.2px] mt-[8px] text-grey-dark dark:text-white text-center">
            {formattedAmount}
          </p>
          <p className="text-[12px] leading-[15px text-grey-deep text-center">
            from {network || 'Optimism'}
          </p>
          <p className="font-caption font-medium text-[20px] leading-[22.8px] mt-[8px] text-grey-dark dark:text-white text-center">
            {success
              ? `${transactionName} Success`
              : `${transactionName} failed`}
          </p>
          {success ? (
            <div className="w-full flex mt-[24px] gap-[16px]">
              <a
                className="w-full h-fit"
                href={txLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button type={18} className="w-full">
                  View on Blockchain
                </Button>
              </a>
              <Button type={3} className="w-full" onClick={closeModal}>
                Explore Vault
              </Button>
            </div>
          ) : (
            <Button type={3} className="w-full mt-[24px]" onClick={onBack}>
              Go Back
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
