export const EXPECTED_CROSSCHAIN_CAPACITY = 10000;
export const crosschainValutAddressBNB = '0x4eB4c0c23B262f5F0af4a87a9d21eD37A182a3fB'
export const crosschainValutAddressPolygon = '0xc34F2Ee89e1166a40A2e35B5c4445A08929C7254'

export const polygonRpc = "https://1rpc.io/matic"
export const bibanceRpc = "https://bsc.meowrpc.com"
export const networkChainIds = {
  ethereum: 1,
  binance: 56,
  poligon:137,
  arbitrum: 42161,
  avalanche: 43114
}

export const addresses = {
  ethereum: {
    usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    usdt: '0xdAC17F958D2ee523a2206206994597C13D831ec7'
  },
  binance: {
    usdc: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    usdt: '0x55d398326f99059fF775485246999027B3197955'
  },
  poligon: {
    usdc: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    usdt: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
  },
  arbitrum: {
    usdc: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    usdt: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'
  },
  avalanche: {
    usdc: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    usdt: '0xffd50AF15e52e6cF177f5888Dd32966C405A8eCC'
  }
}

export const lpAddresses = {
  [networkChainIds.ethereum]: {
    [addresses.ethereum.usdc]: '0xdf0770dF86a8034b3EFEf0A1Bb3c889B8332FF56',
    [addresses.ethereum.usdt]: '0x38EA452219524Bb87e18dE1C24D3bB59510BD783'
  },
  [networkChainIds.binance]: {
    [addresses.binance.usdc]: '',
    [addresses.binance.usdt]: '0x9aA83081AA06AF7208Dcc7A4cB72C94d057D2cda'
  },
  [networkChainIds.poligon]: {
    [addresses.poligon.usdc]: '0x1205f31718499dBf1fCa446663B532Ef87481fe1',
    [addresses.poligon.usdt]: '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c'
  },
  [networkChainIds.arbitrum]: {
    [addresses.arbitrum.usdc]: '0x892785f33CdeE22A30AEF750F285E18c18040c3e',
    [addresses.arbitrum.usdt]: '0xB6CfcF89a7B22988bfC96632aC2A9D6daB60d641'
  },
  [networkChainIds.avalanche]: {
    [addresses.avalanche.usdc]: '0x1205f31718499dBf1fCa446663B532Ef87481fe1',
    [addresses.avalanche.usdt]: '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c'
  }
}

export const tokenDecimals = {
  [addresses.ethereum.usdc]: 6,
  [addresses.ethereum.usdt]: 6,
  [addresses.binance.usdc]: 18,
  [addresses.binance.usdt]: 18,
  [addresses.poligon.usdc]: 6,
  [addresses.poligon.usdt]: 6,
  [addresses.arbitrum.usdc]: 6,
  [addresses.arbitrum.usdt]: 6,
  [addresses.avalanche.usdc]: 6,
  [addresses.avalanche.usdt]: 18,
}

export const balanceData = [
  {
    networkId: networkChainIds.binance,
    vaultContract: crosschainValutAddressBNB,
    lpTokenAddress: '0x9aA83081AA06AF7208Dcc7A4cB72C94d057D2cda',
    tokenAddress: addresses.binance.usdt,
    decimals: 18,
    rpc: 'https://bsc.drpc.org'
  },
  {
    networkId: networkChainIds.poligon,
    vaultContract: crosschainValutAddressPolygon,
    lpTokenAddress: '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c',
    tokenAddress: addresses.poligon.usdt,
    decimals: 6,
    rpc: 'https://1rpc.io/matic'
  },
]

export const crosschainDepositTokenData = [
  // {
  //   chainName: "Ethereum",
  //   chainSymbol: "ETH",
  //   chainId: networkChainIds.ethereum,
  //   chainImageName: "Ethereum",
  //   tokens: [
  //     {
  //       tokenSymbol: "USDT",
  //       tokenAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  //       tokenImageId: "27",
  //       decimals: 6,
  //     },
  //     {
  //       tokenSymbol: "USDC",
  //       tokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  //       tokenImageId: "15",
  //       decimals: 6,
  //     },
  //   ],
  // },
  {
    chainName: "BNB",
    chainSymbol: "BSC",
    chainId: networkChainIds.binance,
    chainImageName: "BNB Chain",
    tokens: [
      {
        tokenSymbol: "USDT",
        tokenAddress: "0x55d398326f99059fF775485246999027B3197955",
        tokenImageId: "27",
        decimals: 18,
      },
      // {
      //   tokenSymbol: "USDC",
      //   tokenAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      //   tokenImageId: "15",
      //   decimals: 18,
      // },
    ],
  },
  {
    chainName: "Polygon",
    chainSymbol: "POL",
    chainId: networkChainIds.poligon,
    chainImageName: "Polygon",
    tokens: [
      {
        tokenSymbol: "USDT",
        tokenAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        tokenImageId: "27",
        decimals: 6,
      },
      // {
      //   tokenSymbol: "USDC",
      //   tokenAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      //   tokenImageId: "15",
      //   decimals: 6,
      // },
    ],
  },
  // {
  //   chainName: "Arbitrum",
  //   chainSymbol: "ARB",
  //   chainId: networkChainIds.arbitrum,
  //   chainImageName: "Arbitrum",
  //   tokens: [
  //     {
  //       tokenSymbol: "USDT",
  //       tokenAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  //       tokenImageId: "27",
  //       decimals: 6,
  //     },
  //     {
  //       tokenSymbol: "USDC",
  //       tokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
  //       tokenImageId: "15",
  //       decimals: 6,
  //     },
  //   ],
  // },
  // {
  //   chainName: "Avalanche",
  //   chainSymbol: "AVA",
  //   chainId: networkChainIds.avalanche,
  //   chainImageName: "Avalanche",
  //   tokens: [
  //     {
  //       tokenSymbol: "USDT",
  //       tokenAddress: "0xffd50AF15e52e6cF177f5888Dd32966C405A8eCC",
  //       tokenImageId: "27",
  //       decimals: 18,
  //     },
  //     {
  //       tokenSymbol: "USDC",
  //       tokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  //       tokenImageId: "15",
  //       decimals: 6,
  //     },
  //   ],
  // },
];

export const networkConfigs = {
  [networkChainIds.ethereum]: {
    chainId: networkChainIds.ethereum,
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.payload.de'],
    blockExplorerUrls: ['https://etherscan.io/'],
  },
  [networkChainIds.binance]: {
    chainId: networkChainIds.binance,
    chainName: 'BNB Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-pokt.nodies.app'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  [networkChainIds.poligon]: {
    chainId: networkChainIds.poligon,
    chainName: 'BNB Smart Chain Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-pokt.nodies.app'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  [networkChainIds.arbitrum]: {
    chainId: networkChainIds.arbitrum,
    chainName: 'Arbitrum One',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://1rpc.io/arb'],
    blockExplorerUrls: ['https://arbiscan.io/'],
  },
  [networkChainIds.avalanche]: {
    chainId: networkChainIds.avalanche,
    chainName: 'Avalanche C-Chain',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://avalanche.drpc.org'],
    blockExplorerUrls: ['https://avascan.info/'],
  }
}


export const zeroBalance = '0'