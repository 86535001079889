import React from 'react';
import LayerZeroLogo from "src/assets/img/layer-zero-image.png";
import StargateLogo from "src/assets/img/stargate-image.jpeg";
import {crosschainValutAddressBNB} from "../../../../constants/crosschain";
import {shortAddress} from "../../../../utils/helpers";

export function CrosschainSecurityComponent() {
  return (
    <div className="w-full max-w-[373px]">
      <h3 className="font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
        Security
      </h3>
      <p className="font-medium text-[14px] leading-[16.8px] text-grey-deep mt-[8px]">
        The Crosschain Yield Index BETA is proudly open-source, crafted with a steadfast dedication to maintaining the highest security standards. Please note, that usage is at your own discretion and risk.
      </p>
      <div className="flex flex-col gap-[8px] mt-[24px]">
        <p className="font-bold text-[16px] leading-[19.2px] text-grey-dark dark:text-white">
          Powered by
        </p>
        <div className="flex justify-between items-center rounded-[8px] bg-grey-lightest-15 p-[4px] text-[14px]">
          <div className="flex gap-[8px] items-center">
            <img src={LayerZeroLogo} alt="certik" className="w-[28px] h-[28px]" />
            LayerZero
          </div>
        </div>
        <div className="flex justify-between items-center rounded-[8px] bg-grey-lightest-15 p-[4px] text-[14px]">
          <div className="flex gap-[8px] items-center">
            <img src={StargateLogo} alt="certik" className="w-[28px] h-[28px] rounded-full" />
            <a
              className="font-medium leading-[20.44px] text-grey-dark dark:text-white"
              href="https://stargate.finance"
              target="_blank"
              rel="noreferrer"
            >
              Stargate
            </a>
          </div>
          <a
            href={`https://bscscan.com/address/${crosschainValutAddressBNB}`}
            target="_blank"
            className="font-medium leading-[20.44px] ml-[40px] text-grey-dark dark:text-white" rel="noreferrer"
          >
            {shortAddress(crosschainValutAddressBNB, 4,4)}
          </a>
        </div>
      </div>
    </div>
  );
}
