import { useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/fonts";
import { Home } from "./pages/Home";
import { InvitationPage } from "./pages/InvitationPage";
import { JoinPage } from "./pages/JoinPage";
import { PoolPage } from "./pages/PoolPage";
import { ReferralPage } from "./pages/ReferralPage";
import { SolanaBridgePage } from "./pages/SolanaBridgePage";
import { VaultList } from "./pages/Vault/VaultList";
import { VaultOptimism } from "./pages/Vault/VaultOptimism";
import BugReportButton from "./components/BugReportButton";
import {TermsOfUse} from "./pages/TermsOfUse";
import {Crosschain} from "./pages/Vault/Crosschain";
import {DeprecatedVaults} from './pages/Vault/DeprecatedVaults'
import {VaultOptimismStable} from './pages/Vault/VaultOptimismStable'

export default function App() {
  useLayoutEffect(() => {
    if (localStorage.getItem("dark")) {
      document.getElementsByTagName("html")[0].className = "dark";
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ai-scanner" element={<Home isWalletAnalysisMode />} />
        <Route path="/demo" element={<Home isDemo />} />
        <Route path="/airdrop" element={<ReferralPage />} />
        <Route path="/invite/:referralCode" element={<InvitationPage />} />
        <Route path="/yields/:poolId" element={<PoolPage />} />
        <Route path="/join" element={<JoinPage />} />
        <Route path="/solana-bridge" element={<SolanaBridgePage />} />
        <Route path="/swap/*" element={<SolanaBridgePage />} />
        <Route path="/vaults" element={<VaultList />} />
        <Route path="/deprecated-vaults" element={<DeprecatedVaults />} />
        <Route path="/vaults/optimism" element={<VaultOptimism />} />
        <Route path="/vaults/stable" element={<VaultOptimismStable />} />
        <Route path="/vaults/crosschain" element={<Crosschain />} />
        <Route path="/vault-terms-of-use" element={<TermsOfUse />} />
      </Routes>
      <BugReportButton />
    </>
  );
}
