import React from 'react';
import {ModalType} from "src/constants/enums";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useModal} from "src/contexts/ModalContext";
import VaultsImage from "src/assets/img/menu/vaults.png";
import VaultsDarkImage from "src/assets/img/menu/vaults-dark.png";
import PortfolioImage from "src/assets/img/menu/portfolio.png";
import PortfolioDarkImage from "src/assets/img/menu/portfolio-dark.png";
import BridgeImage from "src/assets/img/menu/bridge.png";
import BridgeDarkImage from "src/assets/img/menu/bridge-dark.png";
import ScannerImage from "src/assets/img/menu/scaner.png";
import ScannerDarkImage from "src/assets/img/menu/scaner-dark.png";
import AirdropImage from "src/assets/img/menu/airdrop.png";
import AirdropDarkImage from "src/assets/img/menu/airdrop-dark.png";
import TrackerImage from "src/assets/img/menu/tracker.png";
import TrackerDarkImage from "src/assets/img/menu/tracker-dark.png";
import ExplorerImage from "src/assets/img/menu/explorer.png";
import ExplorerDarkImage from "src/assets/img/menu/explorer-dark.png";
import ArrowBackIcon from "src/assets/img/menu/arrow-back.svg";
import ArrowBackDarkIcon from "src/assets/img/menu/arrow-back-dark.svg";
import CloseIcon from "src/assets/img/menu/close.svg";
import CloseDarkIcon from "src/assets/img/menu/close-dark.svg";
import NewIcon from "src/assets/img/new.svg";


export function MenuWindow({ show, footer = true, onClose}) {
  const navigate = useNavigate();

  const handleWalletScanClick = (e) => {
    e.preventDefault();
    navigate("/ai-scanner");
    onClose()
  };

  const navigateHome = () => {
    navigate('/')
    onClose()
  }

  const footerStyles = 'flex flex-col md:h-auto md:flex-row fixed md:absolute md:left-[-422px] right-0 md:right-auto bottom-0 md:bottom-[68px] w-full md:w-[720px] bg-white shadow-xxl rounded-[24px] z-10 text-grey-dark dark:bg-grey-darker5 dark:text-white transition-all duration-500 before:content-[""] before:absolute before:border-l-[12px] before:border-solid before:border-l-transparent before:border-t-[12px] before:border-t-white dark:before:border-t-grey-darker5 before:border-r-[12px] before:border-r-transparent before:right-[248px] before:bottom-[-11px] before:opacity-[0.5]'
  const headerStyles = `flex flex-col md:h-auto md:flex-row fixed md:absolute left-0 md:left-[-400px] right-0 md:right-auto top-12 w-full md:w-[720px] bg-white shadow-xxl rounded-[24px] z-10 text-grey-dark dark:bg-grey-darker5 dark:text-white transition-all duration-500 before:content-[none] md:before:content-[''] before:absolute before:border-l-[12px] before:border-solid before:border-l-transparent before:border-b-[12px] before:border-b-white dark:before:border-t-grey-darker5 before:border-r-[12px] before:border-r-transparent before:right-[240px] before:top-[-11px] before:opacity-[0.5] ${!footer ? 'dark:before:opacity-[0.1]' : ''}`
  const containerStyles = footer ? footerStyles : headerStyles

  return (
    <>
        <div className={`${
          show
            ? "visible opacity-1 translate-y-[0px]"
            : "invisible opacity-0 translate-y-[-50px]"
        } ${containerStyles}`}
        >
          <div className="flex flex-col md:flex-row py-[16px] md:py-[24px] px-[16px] md:gap-[16px]">
            <div className="w-full md:max-w-[330px]">
              <a href="/airdrops"
                 className="w-full flex items-center gap-[8px] pl-[4px] rounded-[8px] mt-[8px] md:mt-0 cursor-pointer hover:bg-grey-lighter10 dark:hover:bg-grey-darker6 transition-all duration-300 relative">
                <img src={TrackerImage} className="block dark:hidden w-[70px] h-[70px] mt-[16px]" alt=""/>
                <img src={TrackerDarkImage} className="hidden dark:block w-[70px] h-[70px] mt-[16px]" alt=""/>
                <div className="flex flex-col gap-[8px]">
                  <p className="font-caption font-medium text-[20px] leading-[22.8px]">Airdrop Tracker (Beta)</p>
                  <p className="font-normal text-[12px] leading-[15px] tracking-[-0.5px]">
                    Your Airdrop ally: simplifying the hunt for extra crypto rewards
                  </p>
                </div>
              </a>
            </div>

            <div className="w-full md:max-w-[330px]">
              <Link to={'/points'} className="w-full flex items-center gap-[8px] pl-[4px] rounded-[8px] mt-[8px] md:mt-[16px]">
                <img src={AirdropImage} className="block dark:hidden w-[70px] h-[70px]" alt=""/>
                <img src={AirdropDarkImage} className="hidden dark:block w-[70px] h-[70px]" alt=""/>
                <div className="flex flex-col gap-[2px]">
                  <div className="flex justify-center items-center w-[88px] h-[19px] bg-grey-deep20 rounded-[4px]">
                    <p className="font-normal text-[12px] text-grey-deep">Coming Soon</p>
                  </div>
                  <p className="font-caption font-medium text-[20px] text-grey-deep leading-[22.8px]">Points Farming</p>
                  <p className="font-normal text-[12px] text-grey-deep leading-[15px] tracking-[-0.5px] mt-[6px]">
                    Community & early adopter incentives with referral program
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
    </>
  );
}

