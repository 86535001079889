import React from 'react';
import CertikLogo from 'src/assets/img/certik-logo.png';
import IosiroLogo from 'src/assets/img/iosiro-logo.png';

export function SecurityComponent({ text }) {
  return (
    <div className="w-full max-w-[373px]">
      <h3 className="font-medium text-[18px] leading-[26.28px] text-grey-dark dark:text-white">
        Security
      </h3>
      <p className="font-medium text-[14px] leading-[16.8px] text-grey-deep mt-[8px]">
        {text}
      </p>
      <div className="flex flex-col gap-[8px] mt-[24px]">
        <p className="font-bold text-[16px] leading-[19.2px] text-grey-dark dark:text-white">
          Audited by
        </p>
        <div className="flex justify-between items-center rounded-[8px] bg-grey-lightest-15 p-[4px] text-[14px]">
          <div className="flex gap-[8px] items-center">
            <img src={CertikLogo} alt="certik" className="w-[28px] h-[28px]" />
            <p className="font-medium leading-[20.44px] text-grey-dark dark:text-white">
              Certik
            </p>
          </div>
          <p className="leading-[16.8px] text-grey-deep">Dec 1, 2022</p>
        </div>
        <div className="flex justify-between items-center rounded-[8px] bg-grey-lightest-15 p-[4px] text-[14px]">
          <div className="flex gap-[8px] items-center">
            <img src={IosiroLogo} alt="certik" className="w-[28px] h-[28px]" />
            <p className="font-medium leading-[20.44px] text-grey-dark dark:text-white">
              iosiro
            </p>
          </div>
          <p className="leading-[16.8px] text-grey-deep">Mar 12, 2021</p>
        </div>
      </div>
    </div>
  );
}
