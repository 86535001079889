import { gql } from 'graphql-tag'

export const TOTAL_POOL_VALUE_QUERY = gql`
  query PoolTotalValueHistory($fundAddress: String, $period: String) {
    fundTvlHistory(fundAddress: $fundAddress, period: $period) { 
      __typename
      firstInvestmentTimestamp
        history {
          timestamp
          totalPoolValue
        }
    }
}
`

export const PERFORMANCE_QUERY = gql`
 query TokenPriceHistory($fundAddress: String!, $period: String!) {
  tokenPriceHistory(address: $fundAddress, period: $period) {
    history {
      adjustedTokenPrice
      timestamp
      __typename
    }
    __typename
  }
}
`

export const TOTAL_VALUE_QUERY = gql`
 query TotalValue($investorAddress: String!) {
  allFundsByInvestor(investorAddress: $investorAddress) {
    fundAddress
    investmentValue
    investorBalance
    totalValue
  }
}
`

export const PROFIT_AND_LOSS_QUERY = gql`
  query ProfitAndLoss($investorAddress: String!) {
    allFundsByInvestor(investorAddress: $investorAddress) {
      fundAddress
      returnOnInvestment
      roiUsd
    }
  }
`

export const TRANSACTIONS_HISTORY_QUERY = gql`
  query TransactionsHistory($filter: TradeEventInputFilter!, $limit: Int) {
  tradeEvents(filter: $filter, limit: $limit) {
    fundAddress
    displayType
    outcome {
      amount
      asset
      assetName
      displayAmount
    }
    income {
      asset
      amount
      assetName
      displayAmount
    }
    timestamp
    txHash
  }
}
`