import { useMemo, useState } from "react";
import { formatNumberWithSign, isDarkMode } from "src/utils/helpers";
import { TooltipContainer } from "../Tooltip";
import { DeFiChart } from "./DeFiChart";

export function YieldChart({
  apy,
  basePortion,
  nBase,
  nReward,
  yieldData,
  className,
}) {
  if (!basePortion) basePortion = 1;
  const base = apy * basePortion;
  const boost = apy * (1 - basePortion);

  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const chartData = useMemo(() => {
    return {
      labels: ["score", ""],
      images: yieldData.map(({ imageUrl }) => {
        const image = new Image();
        image.src = imageUrl;
        return image;
      }),
      datasets: [
        {
          label: "Risk Score",
          data: yieldData.map((_, index) =>
            index < nBase ? base / nBase : boost / nReward
          ),
          backgroundColor: yieldData.map(({ color }) => color),
          borderColor: yieldData.map(() => "#32CC864D"),
        },
      ],
    };
  }, [base, boost, nBase, nReward, yieldData]);

  const gap = 0.01;

  const angle = useMemo(() => {
    const nTotal = nBase + nReward;
    const _gap = (gap * nTotal) / (1 + gap * nTotal) / nTotal;
    return -(
      180 -
      180 * (((1 - nTotal * _gap) * boost) / apy + _gap * (nReward + 1))
    );
  }, [apy, boost, nBase, nReward]);

  const handleHoverElement = (elementIndex) => {
    setHoveredIndex(Math.floor(elementIndex / 2));
  };

  const handleBlurElement = () => {
    setHoveredIndex(-1);
  };

  return (
    <TooltipContainer
      className={`relative ml-auto ${className || ""}`}
      tooltipClassName="!left-[-100px] !top-[150px]"
      tooltipContent={
        <div className="flex flex-col gap-[8px] cursor-default">
          <p className="font-bold text-[12px] leading-[1.25] text-grey-dark">
            Base APY
          </p>
          {yieldData
            .slice(0, nBase)
            .map(({ imageUrl, color, assetSymbol }, index) => (
              <div
                className={`flex items-center transition-all duration-300 cursor-pointer ${
                  hoveredIndex > -1 && index !== hoveredIndex
                    ? "opacity-30"
                    : ""
                }`}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(-1)}
              >
                <img src={imageUrl} alt="Asset" className="w-[14px] h-[14px]" />
                <p className="font-caption font-normal text-[12px] leading-[1.25] text-grey-dark ml-[2px]">
                  {assetSymbol}
                </p>
                <p className="font-normal text-[12px] leading-[1.25] text-grey-dark ml-auto">
                  {formatNumberWithSign(
                    Number(Number((apy * basePortion) / nBase).toFixed(2))
                  )}
                  %
                </p>
              </div>
            ))}
          {nReward ? (
            <>
              <p className="font-bold text-[12px] leading-[1.25] text-grey-dark">
                Boost APY
              </p>
              {yieldData
                .slice(nBase)
                .map(({ imageUrl, color, assetSymbol }, index) => (
                  <div
                    className={`flex items-center transition-all duration-300 cursor-pointer ${
                      hoveredIndex > -1 && index + nBase !== hoveredIndex
                        ? "opacity-30"
                        : ""
                    }`}
                    onMouseEnter={() => setHoveredIndex(index + nBase)}
                    onMouseLeave={() => setHoveredIndex(-1)}
                  >
                    <img
                      src={imageUrl}
                      alt="Asset"
                      className="w-[14px] h-[14px] blur-[3px]"
                    />
                    <p className="font-caption font-normal text-[12px] leading-[1.25] text-grey-dark ml-[2px]">
                      {assetSymbol}
                    </p>
                    <p className="font-normal text-[12px] leading-[1.25] text-grey-dark ml-auto">
                      {formatNumberWithSign(
                        Number(
                          Number((apy * (1 - basePortion)) / nReward).toFixed(2)
                        )
                      )}
                      %
                    </p>
                  </div>
                ))}
            </>
          ) : null}
          <div className="flex items-center justify-between">
            <p className="font-bold text-[12px] leading-[1.25] text-grey-dark">
              Total APY
            </p>
            <p className="font-bold text-[12px] leading-[1.25] text-green-lighter1">
              {formatNumberWithSign(Number(Number(apy).toFixed(2)))}%
            </p>
          </div>
        </div>
      }
    >
      <div
        className="relative w-[213.79px] h-[213.79px] bg-white dark:bg-grey-white5 rounded-full transition-all duration-300"
        style={{
          boxShadow: "1.83144px 21.0615px 32.9658px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(6.41002px)",
        }}
      >
        <div className="w-full h-full bg-white dark:bg-grey-white5 rounded-full">
          <DeFiChart
            data={chartData}
            cutout="83%"
            radius="97%"
            borderRadius={5}
            imageSize={16}
            mobileImageSize={16}
            borderColor={isDarkMode() ? "#D0D0D04D" : undefined}
            forceBorderColor
            borderWidth={0.54}
            darkenOnHover
            gap={gap}
            angle={angle}
            hoveredElementIndex={Math.max(hoveredIndex * 2, -1)}
            onHover={handleHoverElement}
            onBlur={handleBlurElement}
            style={{
              transform: `rotate(${angle}deg)`,
            }}
          />
        </div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col">
          <p className="font-caption text-[18px] leading-[1.1] tracking-[-6.5%] text-grey-lightest text-center">
            Total APY
          </p>
          <p
            className={`font-caption ${
              apy >= 100 ? "text-[36px]" : "text-[45px]"
            } leading-[1.1] tracking-[-3.5px] text-green-lighter1 drop-shadow-green text-center mt-[-2.5px]`}
          >
            <span className="text-[25px]">+</span>
            {apy?.toFixed(2)}
            <span className="text-[25px] ml-[2px]">%</span>
          </p>

          <div className="flex justify-center items-center gap-[2px] mt-[8px]">
            <p className="font-normal text-[12px] leading-[1.25] text-grey-deep text-center">
              Base:
            </p>
            {yieldData.slice(0, nBase).map(({ imageUrl }) => (
              <img
                src={imageUrl}
                alt="asset"
                className="w-[12px] h-[12px] rounded-full"
              />
            ))}
            <p className="font-normal text-[12px] leading-[1.25] text-grey-deep text-center">
              {base ? base.toFixed(2) : 0}%
            </p>
          </div>
          <div className="flex justify-center items-center gap-[2px] mt-[2px]">
            <p className="font-normal text-[12px] leading-[1.25] text-grey-deep text-center">
              Boost:
            </p>
            {yieldData.slice(nBase, nBase + nReward).map(({ imageUrl }) => (
              <img
                src={imageUrl}
                alt="asset"
                className="w-[12px] h-[12px] rounded-full"
              />
            ))}
            <p className="font-normal text-[12px] leading-[1.25] text-grey-deep text-center">
              {boost ? boost.toFixed(2) : 0}%
            </p>
          </div>
        </div>
      </div>
    </TooltipContainer>
  );
}
