import React, { useState } from 'react';
import { ReactComponent as Line } from 'src/assets/img/icons/vertical-dots.svg';
import { TooltipContainer } from 'src/components/Tooltip';
import {
  EXPECTED_CAPACITY,
  HUNDRED_PERCENTS,
} from '../../../constants/optimism';
import {numberToLocaleString} from "../../../utils/helpers";

export function CapacityComponent({expectedCapacity, currentCapacity}) {
  const [rangeComponentWidth, setRangeComponentWidth] = useState(0);

  const marginLeft = (rangeComponentWidth / HUNDRED_PERCENTS) * currentCapacity;

  const refCallback = (element) => {
    if (!element) return;
    const width = element.getBoundingClientRect().width;
    setRangeComponentWidth(width);
  };

  const formattedCurrentCapacity = currentCapacity?.toFixed(2);
  const displayExpectedCapacity = numberToLocaleString(expectedCapacity)

  return (
    <div className="mt-[12px] flex flex-col gap-[8px]">
      <TooltipContainer
        tooltipContent="Capacity in DeFi: Maximum asset or liquidity a platform can handle. It shows scalability and potential congestion risks."
        tooltipClassName="!left-[-40px]"
        className="w-fit"
      >
        <p className="font-caption w-fit font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
          Capacity
        </p>
      </TooltipContainer>
      <div>
        <div className="flex justify-between">
          <div className="flex flex-col items-center">
            <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
              0$
            </p>
            <Line />
          </div>
          <div className="flex flex-col items-end">
            <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
              ${displayExpectedCapacity}
            </p>
            <Line />
          </div>
        </div>
        <div className="relative h-[6px]">
          <div
            ref={refCallback}
            className="w-full h-full rounded-[20px] bg-grey-lightest mt-[2px] opacity-[0.3]"
          />
          <div
            className="h-full rounded-[20px] bg-yellow-inputRange shadow-sm absolute top-0"
            style={{ width: currentCapacity + '%' }}
          />
        </div>
        <div
          className={'flex flex-col items-start w-fit mt-[2px]'}
          style={{ marginLeft: marginLeft + 'px' }}
        >
          <Line />
          <p
            className="font-medium text-[12px] leading-[13.2px] text-grey-deep"
            style={{ marginLeft: marginLeft > 10 ? '-10px' : '' }}
          >
            {formattedCurrentCapacity}%
          </p>
        </div>
      </div>
    </div>
  );
}
