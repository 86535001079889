import React, {useEffect} from 'react';
import {Header} from "../../../layout/Header";
import {PageContainer} from "../../../components/lib";
import {Footer} from "../../../layout/Footer";
import {ModalType, PeriodTabsState} from "../../../constants/enums";
import {ConnectModal, DisconnectModal} from "../../../components/modals";
import {useWallet} from "../../../contexts/WalletContext";
import {useModal} from "../../../contexts/ModalContext";
import {VaultCards} from "./components/VaultCards";
import {useQuery} from "@apollo/client";
import {PERFORMANCE_QUERY, TOTAL_POOL_VALUE_QUERY} from "../query";
import {
  formatTVLData, getApy,
  numberToLocaleString
} from "../../../utils/helpers";
import {optimismPoolAddress, optimismStablePoolAddress} from '../../../constants/optimism'
import {useCrosschainCapacity} from "../../../hooks/useCrosscahinCapacity";

export function DeprecatedVaults ()  {
  const { walletAddress, disconnect } = useWallet();
  const { modalType, closeModal } = useModal();
  const {capacity: crosschainCapacity} = useCrosschainCapacity()

  const { data: stableTvlData } = useQuery(TOTAL_POOL_VALUE_QUERY, {
    variables: { fundAddress: optimismStablePoolAddress , period: PeriodTabsState.all},
  })

  const { data: optimismTvlData } = useQuery(TOTAL_POOL_VALUE_QUERY, {
    variables: { fundAddress: optimismPoolAddress , period: PeriodTabsState.all},
  })

  const { data: apyDataAll } = useQuery(PERFORMANCE_QUERY, {
    variables: { fundAddress: optimismPoolAddress , period: PeriodTabsState.all},
  })

  const optimismDisplayApy = getApy(
    apyDataAll?.tokenPriceHistory?.history,
    optimismTvlData?.fundTvlHistory?.firstInvestmentTimestamp
  )

  const stableTvlValue = formatTVLData(stableTvlData)
  const optimistTvlValue = formatTVLData(optimismTvlData)
  const formattedTvlValue = numberToLocaleString(optimistTvlValue)
  const formattedCrosschainValue = numberToLocaleString(crosschainCapacity)
  const totaTvl = (stableTvlValue || 0) + (optimistTvlValue || 0)
  const formattedTotalTvl = numberToLocaleString(totaTvl)

  const handleDisconnect = () => {
    disconnect();
    closeModal();
  };

  useEffect(() => {
    if (!walletAddress) return;
    closeModal();
  }, [closeModal, walletAddress]);

  return (
    <>
      <PageContainer>
        <Header />
        <div className="flex justify-between items-center px-[24px] lg:px-[64px] py-[12px] lg:py-[14px]">
          <h3 className="text-[32px] leading-[1.1] text-grey-dark dark:text-white">Farming Vaults</h3>
          <div>
            <p className="font-normal text-[14px] leading-4 text-grey-deep">Total Value Locked</p>
            <p className="font-caption font-medium text-[16px] leading-6 text-grey-dark dark:text-white">${formattedTotalTvl}</p>
          </div>
        </div>
        <VaultCards
          tvlValue={optimistTvlValue}
          formattedTvlValue={formattedTvlValue}
          optimismMonthlyApy={optimismDisplayApy}
          formattedCrosschainTvlValue={formattedCrosschainValue}
          crosschainTvlValue={crosschainCapacity}
        />
        <div className="mt-auto pt-[72px]">
          <Footer />
        </div>
      </PageContainer>
      {modalType === ModalType.ConnectModal && <ConnectModal />}
      {modalType === ModalType.DisconnectModal && (
        <DisconnectModal handleDisconnect={handleDisconnect} />
      )}
    </>
  );
};
