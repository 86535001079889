/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as TrophyIcon } from 'src/assets/img/icons/Trophy.svg';
import { ReactComponent as DownArrowSvg } from 'src/assets/img/icons/down-arrow.svg';
import { ReactComponent as FeeIcon } from 'src/assets/img/icons/fee.svg';
import { ReactComponent as UpArrowSvg } from 'src/assets/img/icons/up-arrow.svg';
import { AssetGroup } from 'src/components/AssetGroup';
import { PoolBox2 } from 'src/components/PoolBox2';
import { TooltipContainer } from 'src/components/Tooltip';
import { PoolChart } from 'src/components/charts/PoolChart';
import { RiskScoreChart } from 'src/components/charts/RiskScoreChart';
import { YieldChart } from 'src/components/charts/YieldChart';
import { Button, PageContainer, PoolBadge } from 'src/components/lib';
import { Collapsable } from 'src/components/lib/Collapsable';
import { FullPageLoader } from 'src/components/lib/FullPageLoader';
import { ConnectModal, DisconnectModal } from 'src/components/modals';
import { ModalType } from 'src/constants/enums';
import { useModal } from 'src/contexts/ModalContext';
import { useWallet } from 'src/contexts/WalletContext';
import { usePoolChartData } from 'src/hooks/usePoolChartData';
import { usePoolData } from 'src/hooks/usePoolData';
import { useSimilarPools } from 'src/hooks/useSimilarPools';
import { useYieldData } from 'src/hooks/useYieldData';
import { Footer } from 'src/layout/Footer';
import { Header } from 'src/layout/Header';
import { getNativeCoinFromChain } from 'src/utils/helpers';
import { ComingSoon } from '../ComingSoon';
import { ChainLogo } from '../Home/sections/Result2/components/ChainLogo';
import { RatingTag } from './components/RatingTag';

export function PoolPage() {
  const [detailedView, setDetailedView] = useState(false);
  const [similarPoolsOffset, setSimilarPoolsOffset] = useState(0);

  const { modalType, closeModal } = useModal();
  const { walletAddress, disconnect } = useWallet();

  const { poolId } = useParams();
  const { data, isLoading: isLoadingPoolData } = usePoolData({ poolId });
  const { data: yieldData, isLoading: isLoadingYieldData } = useYieldData({
    poolData: data?.poolData,
    poolInfo: data?.poolInfo,
  });
  const { data: chartData, isLoading: isLoadingChartData } = usePoolChartData({
    poolId: data?.poolData?.pool_id,
  });
  const { data: similarPools, isLoading: isLoadingSimilarPools } =
    useSimilarPools({
      poolId: poolId,
      initialPools: data?.similarPools,
      offset: similarPoolsOffset,
    });

  const isLoading =
    isLoadingPoolData ||
    isLoadingYieldData ||
    !yieldData ||
    isLoadingChartData ||
    !chartData;

  const badgeElements = !isLoading && (
    <div className="flex gap-[8px]">
      {data.poolData.dex_type && (
        <PoolBadge title={data.poolData.dex_type?.toUpperCase()} />
      )}
      {data.poolData.is_top5_tvl && (
        <PoolBadge title="Top-5 TVL" icon={<TrophyIcon />} />
      )}
    </div>
  );

  const providerLogoIds = !isLoading && [
    ...new Set([
      ...data.poolData.pool_risk_scores.map((item) => item.provider.logoId),
      ...data.poolData.protocol_risk_scores.map((item) => item.provider.logoId),
    ]),
  ];

  const handleExploreMore = () => {
    setSimilarPoolsOffset((prev) => prev + 8);
  };

  const handleDisconnect = () => {
    if (walletAddress) disconnect();
    closeModal();
  };

  useEffect(() => {
    if (isLoading) return;
    setTimeout(() => {
      setDetailedView(true);
    }, 3000);
  }, [isLoading]);

  const basePortion = useMemo(() => {
    if (!chartData) return null;
    const totalSum = chartData
      .slice(-30)
      .reduce((acc, item) => acc + item.apy, 0);
    const baseSum = chartData
      .slice(-30)
      .reduce((acc, item) => acc + item.apyBase, 0);
    return baseSum / totalSum;
  }, [chartData]);

  if (!isLoadingPoolData && (!data?.poolData || !data?.poolInfo))
    return <ComingSoon />;

  const pageTitle = isLoading
    ? 'Loading Pool Page...'
    : `Yield Farming on ${data?.poolData?.protocol_name} ${data?.poolData?.pool_name}`;
  const pageDescription = isLoading
    ? ''
    : `Explore this DeFi yield pool with TVL ${data.poolInfo.tvlUsd} and APY ${data.poolInfo.apy}. Find similar yield farming and staking pools on One Click Crypto.`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta name="og:description" content={pageDescription} />
        <meta property="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <PageContainer>
        {!isLoading && <Header />}
        {isLoading ? (
          <FullPageLoader title="Loading Requested Pool Page" />
        ) : (
          <div className="px-[24px] lg:px-[64px] mt-[16px] lg:mt-[34px] animate-slowFadeIn">
            <div className="flex flex-col lg:flex-row items-center justify-between">
              <div className="flex flex-col lg:flex-row items-start lg:items-center w-full lg:w-auto gap-[23px]">
                <div className="flex w-full lg:w-auto justify-between items-center">
                  <AssetGroup imageIds={data.poolData.asset_image_ids} />
                  <div className="lg:hidden">{badgeElements}</div>
                </div>
                <div className="flex flex-col gap-[4px]">
                  <p className="text-[14px] leading-[15px] font-normal text-grey-deep">
                    {/* Last updated: May 31 2023, 15:55:30 */}
                    Last updated:{' '}
                    {`${formatInTimeZone(
                      data.poolData.last_updated_at,
                      'UTC',
                      'MMM dd yyyy,'
                    )} 00:00:00 UTC`}
                  </p>
                  <h3 className="text-[18px] lg:text-[32px] leading-[1.46] lg:leading-[1.1] text-grey-dark dark:text-white">
                    {data.poolData.pool_name}
                  </h3>
                  <div className="flex flex-col lg:flex-row lg:items-center gap-[16px]">
                    <div className="flex items-center gap-[10px]">
                      <p className="text-[16px] leading-[1.2] tracking-[-0.5px] w-[64px] lg:w-auto font-normal text-grey-deep">
                        Protocol:
                      </p>
                      <div className="flex items-center gap-[8px]">
                        <div
                          className="w-[24px] h-[24px] rounded-full flex items-center justify-center"
                          style={{
                            backgroundColor: data.poolData.protocol_color,
                          }}
                        >
                          <img
                            src={`/images/protocols/Logo-${data.poolData.protocol_image_id}.png`}
                            className="w-[80%] h-[80%]"
                            alt="Pool"
                          />
                        </div>
                        <a
                          href="#"
                          className="text-[16px] leading-[1.1] text-grey-black dark:text-white"
                        >
                          {data.poolData.protocol_name}
                        </a>
                      </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <p className="text-[16px] leading-[1.2] tracking-[-0.5px] w-[64px] lg:w-auto font-normal text-grey-deep">
                        Chain:
                      </p>
                      <div className="flex items-center gap-[8px]">
                        <ChainLogo chain={data.poolData.chain_name} size={24} />
                        <a
                          href="#"
                          className="text-[16px] leading-[1.1] text-grey-black dark:text-white"
                        >
                          {data.poolData.chain_name}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[8px] w-full lg:w-auto mt-[24px] lg:mt-auto">
                <div className="hidden lg:block">{badgeElements}</div>
                <div className="flex gap-[16px] w-full lg:w-auto">
                  <div className="flex flex-col">
                    <p className="font-normal text-[14px] leading-[1.1] text-grey-deep">
                      Total Value Locked
                    </p>
                    <p className="font-caption text-[16px] leading-[1.46] text-grey-dark dark:text-white">
                      ${Number(data.poolInfo.tvlUsd).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-normal text-[14px] leading-[1.1] text-grey-deep">
                      Pool Age
                    </p>
                    <p className="font-caption text-[16px] leading-[1.46] text-grey-dark dark:text-white">
                      {data.poolData.pool_age > -1
                        ? `${data.poolData.pool_age} days`
                        : '---'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex gap-[32px] ${
                detailedView ? 'lg:gap-[117px]' : 'lg:gap-[26px]'
              } justify-center flex-col lg:flex-row mt-[42px] lg:mt-[72px] mx-auto transition-all duration-1000`}
            >
              <div
                className={`flex flex-col lg:flex-row ${
                  detailedView ? 'gap-[25px]' : 'gap-0'
                } w-full lg:w-auto transition-all duration-1000`}
              >
                <div className="flex flex-col gap-[12px] items-center lg:items-start">
                  <h4 className="text-[32px] leading-[1.1] text-grey-dark dark:text-white">
                    Safety Score
                  </h4>
                  <RiskScoreChart
                    score={data.poolData?.average_rating || 0}
                    label={detailedView ? 'Overall rating' : ''}
                    className="w-[213.79px] h-[213.79px] cursor-pointer"
                    providerLogoIds={providerLogoIds}
                    style={{
                      boxShadow:
                        '1.83144px 21.0615px 32.9658px rgba(0, 0, 0, 0.1)',
                      backdropFilter: 'blur(6.41002px)',
                    }}
                  />
                </div>
                {detailedView && (
                  <div className="w-full lg:w-[297px] flex flex-col gap-[16px] mt-[47px] lg:animate-slideRight297">
                    <div className="lg:min-w-[297px] w-[297px]">
                      <h6 className="font-body font-bold text-[18px] leading-[1.2] tracking-[-0.5px] text-grey-dark dark:text-white whitespace-nowrap">
                        Pool Rating
                      </h6>
                      <div className="flex flex-wrap gap-[8px] mt-[14px]">
                        {data.poolData.pool_risk_scores?.map(
                          ({ provider, score, rating, link }, i) => (
                            <RatingTag
                              provider={provider}
                              rating={rating}
                              score={score}
                              link={link}
                              tooltipClassName={i ? '' : 'right-[-110px]'}
                              tooltipBeforeClassName={
                                i ? '' : 'before:right-[110px]'
                              }
                              showProviderName
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="lg:min-w-[297px] w-[297px]">
                      <p className="font-bold text-[12px] leading-[1.25] text-grey-dark dark:text-white whitespace-nowrap">
                        Protocol Rating
                      </p>
                      <Collapsable
                        className="rounded-[8px] p-[4px] mt-[14px] bg-grey-lightest bg-opacity-20 hover:bg-grey-lighter111 hover:bg-opacity-20"
                        renderHeader={({
                          isCollapsed,
                          handleToggleCollapse,
                        }) => (
                          <div
                            className="flex items-center gap-[8px] cursor-pointer"
                            onClick={handleToggleCollapse}
                          >
                            <div
                              className="w-[28px] h-[28px] rounded-full flex items-center justify-center border-[0.82px] border-grey-lightest border-opacity-50"
                              style={{
                                backgroundColor: data.poolData.protocol_color,
                              }}
                            >
                              <img
                                src={`/images/protocols/Logo-${data.poolData.protocol_image_id}.png`}
                                className="w-[80%] h-[80%]"
                                alt="Pool"
                              />
                            </div>
                            <p className="text-[16px] leading-[1.1] text-grey-black dark:text-white">
                              {data.poolData.protocol_name}
                            </p>
                            <TooltipContainer
                              className="ml-auto"
                              tooltipContent="The average weighted score based on all the available score providers"
                            >
                              <RiskScoreChart
                                bgNone
                                score={Number(
                                  Number(
                                    (data.poolData.protocol_risk_scores.reduce(
                                      (acc, item) =>
                                        acc + item.score / item.provider.max,
                                      0
                                    ) /
                                      data.poolData.protocol_risk_scores
                                        .length) *
                                      10
                                  ).toFixed(1)
                                )}
                                max={10}
                                small
                                scoreClassName="!text-[11.6px]"
                                className="w-[31px] h-[31px] ml-auto"
                              />
                            </TooltipContainer>
                            {isCollapsed ? (
                              <DownArrowSvg className="fill-grey-deep mx-[5.49px]" />
                            ) : (
                              <UpArrowSvg className="fill-grey-black mx-[5.49px]" />
                            )}
                          </div>
                        )}
                        renderBody={({ isCollapsed }) => (
                          <div
                            className={`flex flex-wrap gap-[8px] ${
                              isCollapsed
                                ? 'max-h-0 mt-0 overflow-hidden'
                                : 'max-h-[500px] mt-[8px]'
                            } transition-all duration-300`}
                          >
                            {data.poolData.protocol_risk_scores?.map(
                              ({ provider, score, rating, link }, i) => (
                                <RatingTag
                                  entity="protocol"
                                  provider={provider}
                                  rating={rating}
                                  score={score}
                                  link={link}
                                  tooltipClassName={i ? '' : 'right-[-110px]'}
                                  tooltipBeforeClassName={
                                    i ? '' : 'before:right-[110px]'
                                  }
                                />
                              )
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div className="lg:min-w-[297px] w-[297px]">
                      <p className="font-bold text-[12px] leading-[1.25] text-grey-dark dark:text-white whitespace-nowrap">
                        Asset Rating
                      </p>
                      <div className="flex flex-col">
                        {data.poolData.asset_risk_scores?.map(
                          ({ asset_symbol, scores }, index) => (
                            <Collapsable
                              className="rounded-[8px] p-[4px] mt-[14px] bg-grey-lightest bg-opacity-20 hover:bg-grey-lighter111 hover:bg-opacity-20"
                              renderHeader={({
                                isCollapsed,
                                handleToggleCollapse,
                              }) => (
                                <div
                                  className="flex items-center gap-[8px] cursor-pointer"
                                  onClick={handleToggleCollapse}
                                >
                                  <img
                                    src={`/images/assets/Logo-${data.poolData.asset_image_ids[index]}.png`}
                                    alt="Asset"
                                    className={`w-[28px] h-[28px] rounded-full border-2 border-white dark:border-grey-darker3 box-content`}
                                  />
                                  <p className="text-[16px] leading-[1.1] text-grey-black dark:text-white">
                                    {asset_symbol}
                                  </p>
                                  <TooltipContainer
                                    className="ml-auto"
                                    tooltipContent="The average weighted score based on all the available score providers"
                                  >
                                    <RiskScoreChart
                                      bgNone
                                      score={Number(
                                        Number(
                                          (scores.reduce(
                                            (acc, item) =>
                                              acc +
                                              item.score / item.provider.max,
                                            0
                                          ) /
                                            scores.length) *
                                            10
                                        ).toFixed(1)
                                      )}
                                      max={10}
                                      small
                                      scoreClassName="!text-[11.6px]"
                                      className="w-[31px] h-[31px]"
                                    />
                                  </TooltipContainer>
                                  {isCollapsed ? (
                                    <DownArrowSvg className="fill-grey-deep mx-[5.49px]" />
                                  ) : (
                                    <UpArrowSvg className="fill-grey-black mx-[5.49px]" />
                                  )}
                                </div>
                              )}
                              renderBody={({ isCollapsed }) => (
                                <div
                                  className={`flex flex-wrap gap-[8px] ${
                                    isCollapsed
                                      ? 'max-h-0 mt-0 overflow-hidden'
                                      : 'max-h-[500px] mt-[8px]'
                                  } transition-all duration-300`}
                                >
                                  {scores?.map(
                                    ({ provider, score, rating }, i) => (
                                      <RatingTag
                                        entity="asset"
                                        provider={provider}
                                        rating={rating}
                                        score={score}
                                        link={
                                          provider.name === 'Redefine'
                                            ? 'https://redefine.net/contact/'
                                            : undefined
                                        }
                                        tooltipClassName={
                                          i ? '' : 'right-[-110px]'
                                        }
                                        tooltipBeforeClassName={
                                          i ? '' : 'before:right-[110px]'
                                        }
                                      />
                                    )
                                  )}
                                </div>
                              )}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`flex flex-col lg:flex-row items-center lg:h-fit ${
                  detailedView ? 'gap-[25px]' : 'gap-0'
                } w-full lg:w-auto transition-all duration-1000`}
              >
                <div className="flex flex-col gap-[12px]">
                  <h4 className="text-[32px] leading-[1.1] ml-[62px] text-grey-dark dark:text-white">
                    Yield
                  </h4>
                  <YieldChart
                    apy={data.poolInfo.apyMean30d}
                    basePortion={basePortion}
                    nBase={data.poolData.asset_image_ids?.length || 0}
                    nReward={data.poolInfo.rewardTokens?.length || 0}
                    yieldData={yieldData}
                    className="cursor-pointer"
                  />
                </div>
                {detailedView && (
                  <div className="mt-0 lg:mt-[-20px] w-full lg:w-[180px] lg:animate-slideRight161">
                    <p className="font-bold text-[16px] leading-[1.46] text-grey-dark dark:text-white whitespace-nowrap">
                      Fees
                    </p>
                    <div className="flex items-center gap-[4px] mt-[4px]">
                      <FeeIcon className="mt-[4px] ml-[-5px] mr-[-5px]" />
                      <TooltipContainer
                        className="ml-auto cursor-pointer"
                        tooltipContent="Average gas fee interaction with this smart contract based on data for the last 24 hours"
                      >
                        <div>
                          <p className="font-normal text-[12px] leading-[1.25] text-grey-deep">
                            Gas Fee
                          </p>
                          <p className="font-caption text-[14px] leading-[1.46] text-grey-dark dark:text-white whitespace-nowrap">
                            {data.poolData.gas_fee_in_eth}{' '}
                            {getNativeCoinFromChain(data.poolData.chain_name)}{' '}
                            <span className="font-body font-normal text-[12px] leading-[1.25] text-grey-deep">
                              (${data.poolData.gas_fee_in_usd})
                            </span>
                          </p>
                        </div>
                      </TooltipContainer>
                    </div>
                    <p className="font-bold text-[16px] leading-[1.46] text-grey-dark dark:text-white mt-[26px] whitespace-nowrap">
                      Potential Airdrops
                    </p>
                    <TooltipContainer
                      className="ml-auto"
                      tooltipContent="Coming Soon"
                      tooltipClassName="max-w-[100px]"
                    >
                      <div className="flex gap-[4px] mt-[8px] cursor-pointer">
                        <img
                          src={`/images/assets/Logo-0.png`}
                          alt="Asset"
                          className="w-[18px] h-[18px] rounded-full blur-[3px] opacity-60 transition-all duration-300"
                        />
                        <img
                          src={`/images/assets/Logo-108.png`}
                          alt="Asset"
                          className="w-[18px] h-[18px] rounded-full blur-[3px] opacity-60 transition-all duration-300"
                        />
                        <img
                          src={`/images/assets/Logo-46.png`}
                          alt="Asset"
                          className="w-[18px] h-[18px] rounded-full blur-[3px] opacity-60 transition-all duration-300"
                        />
                        <img
                          src={`/images/assets/Logo-50.png`}
                          alt="Asset"
                          className="w-[18px] h-[18px] rounded-full blur-[3px] opacity-60 transition-all duration-300"
                        />
                      </div>
                    </TooltipContainer>
                  </div>
                )}
              </div>
            </div>
            {detailedView && (
              <PoolChart
                data={chartData}
                poolData={data.poolData}
                poolInfo={data.poolInfo}
                className={`${
                  detailedView ? 'mt-[42px] lg:mt-[158px]' : 'mt-0 lg:mt-0'
                } transition-all duration-1000 animate-fadeIn`}
              />
            )}
            <p className="text-[32px] leading-[1.1] font-caption text-grey-dark dark:text-white mt-[42px] lg:mt-[160px]">
              Similar Pools
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-[20px] gap-y-[20px] lg:gap-y-[48px] mt-[24px] lg:mt-[48px]">
              {similarPools?.map((item, index) => (
                <PoolBox2
                  key={index}
                  pool={item.pool_name.split(' ').slice(1).join(' ')}
                  riskScore={item.pool_average_risk}
                  apy={item.pool_yield}
                  tvl={item.pool_tvl}
                  protocol={item.protocol_name}
                  chain={item.chain_name}
                  url={`/yields/${item.pool_id}`}
                  assetImageIds={item.asset_image_ids} // *
                  protocolImageId={item.protocol_image_id}
                  color={item.protocol_color}
                />
              ))}
            </div>
            <Button
              type={15}
              className="mt-[48px] mx-auto"
              onClick={handleExploreMore}
              disabled={isLoadingSimilarPools}
            >
              {isLoadingSimilarPools ? (
                <ClipLoader color="#1E1E1E" size={20} />
              ) : (
                'Explore more'
              )}
            </Button>
          </div>
        )}
        {!isLoading && (
          <div className="mt-auto pt-[72px]">
            <Footer />
          </div>
        )}
      </PageContainer>
      {modalType === ModalType.ConnectModal && <ConnectModal />}
      {modalType === ModalType.DisconnectModal && (
        <DisconnectModal handleDisconnect={handleDisconnect} />
      )}
    </>
  );
}
