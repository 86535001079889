import LayerZero from "src/assets/img/layer-zero-image.png";
import Polygon from "src/assets/img/polygon.png";
import Binance from "src/assets/img/binance.png";
import USDCIcon from 'src/assets/img/tokens/usdc.png'
import USDTIcon from 'src/assets/img/tokens/usdt.png'

export const byPoolsChartData = {
  chainImages: [(function () {
      const image = new Image();
      image.src = USDTIcon;
      return image;
    })()],
  images: [(function () {
      const image = new Image();
      image.src = USDTIcon;
      return image;
    })()],
  labels:['Stargate USDT'],
  logoImage: (function () {
    const image = new Image();
    image.src = LayerZero;
    return image;
  })(),
  datasets: [{
    label:'# of Interactions',
    data: [100],
    borderColor: ['#FFFFFF'],
    backgroundColor: ['#26A27A']
  }]
}

export const byAssetsChartData = {
  chainImages: [(function () {
      const image = new Image();
      image.src = USDTIcon;
      return image;
    })()],
  images: [(function () {
      const image = new Image();
      image.src = USDTIcon;
      return image;
    })()],
  labels:['USDT'],
  logoImage: (function () {
    const image = new Image();
    image.src = LayerZero;
    return image;
  })(),
  datasets: [{
    label:'# of Interactions',
    data: [100],
    borderColor: ['#FFFFFF'],
    backgroundColor: ['#26A27A']
  }]
}

export const byChainsChartData = {
  chainImages: [(function () {
    const image = new Image();
    image.src = Binance;
    return image;
  })(),
    (function () {
      const image = new Image();
      image.src = Polygon;
      return image;
    })()],
  images: [(function () {
    const image = new Image();
    image.src = Binance;
    return image;
  })(),
    (function () {
      const image = new Image();
      image.src = Polygon;
      return image;
    })()],
  labels:['BNB Smart Chain', 'Polygon Chain'],
  logoImage: (function () {
    const image = new Image();
    image.src = LayerZero;
    return image;
  })(),
  datasets: [{
    label:'# of Interactions',
    data: [50, 50],
    borderColor: ['#FFFFFF', '#FFFFFF'],
    backgroundColor: ['#F0B93A', '#6E459A']
  }]
}