import React from 'react';
import {TabItem} from "./TabItem";

export function ChartsSectionTabs({ activeTab, setActiveTab, tabs}) {
  return (
    <div
      className="flex justify-between md:justify-normal w-full md:w-fit mt-[39px] bg-grey-lightest-15 dark:bg-grey-darker5 p-1 rounded-[8px] font-bold text-[14px] leading-[16.8px] text-grey-dark dark:text-grey-deep lg:mt-[0]"
    >
      {tabs?.map(({title, value}) => (
        <TabItem
          key={value}
          isActive={activeTab === value}
          title={title}
          setActive={setActiveTab}
          value={value}
        />
      ))}
    </div>
  );
}
