import { ethers } from 'ethers';
import OPImage from 'src/assets/img/optimism-big.png';
import USDCIcon from 'src/assets/img/tokens/usdc.png'
import USDTIcon from 'src/assets/img/tokens/usdt.png'
import ETHIcon from 'src/assets/img/tokens/eth.png'
import OPIcon from 'src/assets/img/tokens/op.png'
import ULPIcon from 'src/assets/img/tokens/ulp.png'
import DefaultIcon from 'src/assets/img/tokens/default-icon.png'
import { assetsData } from 'src/constants/allocationChart';
import {dayInMilliseconds, daysInYear, minimumPercentageForChart, optimismPoolAddress} from 'src/constants/optimism';
import {crosschainValutAddressBNB, crosschainValutAddressPolygon, networkChainIds} from "../constants/crosschain";
import {AllocationTabState} from "../constants/enums";
import {byAssetsChartData, byChainsChartData, byPoolsChartData} from "../constants/crosschainAllocationChart";

export function darkenColor(color, v = -40) {
  if (color.length > 6) {
    color = color.substring(1, color.length);
  }
  var rgb = parseInt(color, 16);
  var r = Math.abs(((rgb >> 16) & 0xff) + v);
  if (r > 255) r = r - (r - 255);
  var g = Math.abs(((rgb >> 8) & 0xff) + v);
  if (g > 255) g = g - (g - 255);
  var b = Math.abs((rgb & 0xff) + v);
  if (b > 255) b = b - (b - 255);
  r = Number(r < 0 || isNaN(r)) ? 0 : (r > 255 ? 255 : r).toString(16);
  if (r.length === 1) r = '0' + r;
  g = Number(g < 0 || isNaN(g)) ? 0 : (g > 255 ? 255 : g).toString(16);
  if (g.length === 1) g = '0' + g;
  b = Number(b < 0 || isNaN(b)) ? 0 : (b > 255 ? 255 : b).toString(16);
  if (b.length === 1) b = '0' + b;
  return '#' + r + g + b;
}

export function getGrayscale(hex) {
  hex = hex.slice(1);
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  let grayscale = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  hex = Math.round(grayscale).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return '#' + hex + hex + hex;
}

export function formatAddress(address, preLen = 6, postLen = 4) {
  if (!address) return;
  return (
    address.slice(0, preLen) + '...' + address.slice(address.length - postLen)
  );
}

export function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

export function openNewTab(url) {
  let link = document.getElementById('term-link');
  link.href = url;
  link.target = '_blank';
  link.click();
  setTimeout(() => {
    link.target = 'https://www.oneclick.fi/terms-of-service-defi';
  }, 1000);
}

export function isDarkMode() {
  return (
    document.getElementsByTagName('html')[0].className.indexOf('dark') > -1
  );
}

export function toggleDarkMode() {
  if (isDarkMode()) {
    document.getElementsByTagName('html')[0].className = '';
    localStorage.removeItem('dark');
  } else {
    document.getElementsByTagName('html')[0].className = 'dark';
    localStorage.setItem('dark', 1);
  }
}

export function getTxnLink(chain, txnHash) {
  const chainLinks = {
    Ethereum: 'https://etherscan.io/tx/',
    'BNB Chain': 'https://bscscan.com/tx/',
    Polygon: 'https://polygonscan.com/tx/',
    Arbitrum: 'https://arbiscan.io/tx/',
    Optimism: 'https://optimistic.etherscan.io/tx/',
    Avalanche: 'https://avascan.info/blockchain/dexalot/tx/',
  };
  return chainLinks[chain] + txnHash;
}

export function calculateAverageColor(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;
      let totalRed = 0,
        totalGreen = 0,
        totalBlue = 0;

      for (let i = 0; i < pixels.length; i += 4) {
        totalRed += pixels[i];
        totalGreen += pixels[i + 1];
        totalBlue += pixels[i + 2];
      }

      const pixelCount = pixels.length / 4;
      const averageRed = Math.round(totalRed / pixelCount);
      const averageGreen = Math.round(totalGreen / pixelCount);
      const averageBlue = Math.round(totalBlue / pixelCount);

      const hexString = `#${averageRed
        .toString(16)
        .padStart(2, '0')}${averageGreen
        .toString(16)
        .padStart(2, '0')}${averageBlue.toString(16).padStart(2, '0')}`;
      resolve(hexString + '33');
    };

    img.onerror = () => {
      reject('Failed to load the image.');
    };
  });
}

export function increaseOpacity(hexColor) {
  // Remove the '#' symbol from the hex color string
  hexColor = hexColor.replace('#', '');

  // Extract the alpha value from the hex color string
  let alpha = parseInt(hexColor.slice(6, 8), 16);

  // Increase the alpha value by 3 times
  alpha *= 3;

  // Ensure that the alpha value does not exceed the maximum value (255)
  alpha = Math.min(alpha, 255);

  // Convert the alpha value back to a hexadecimal string
  let newAlpha = alpha.toString(16).padStart(2, '0');

  // Replace the alpha value in the hex color string with the new alpha value
  let newHexColor = hexColor.slice(0, 6) + newAlpha;

  // Add the '#' symbol back to the hex color string
  newHexColor = '#' + newHexColor;

  return newHexColor;
}

export function formatTVLData(data) {
  if (!data) return null;

  const history = data?.fundTvlHistory?.history;

  if (!history) return null;

  const lastIndex = history?.length - 1;
  const tvlData = history?.[lastIndex];

  if (tvlData && tvlData?.totalPoolValue) {
    const formattedValue = ethers.utils.formatEther(tvlData.totalPoolValue);
    const valueToFixedTwo = Number(formattedValue).toFixed(2);
    return Number(valueToFixedTwo);
  }
}

export function numberToLocaleString(number, min = 2) {
  return Number(number).toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: min,
    maximumFractionDigits: 2,
  });
}

export function formatNumberWithSign(number) {
  if (number > 0) return '+' + number;
  return number;
}

export function getNativeCoinFromChain(chain) {
  if (chain === 'Polygon') return 'MATIC';
  if (chain === 'Avalanche') return 'AVAX';
  if (chain === 'Solana') return 'SOL';
  if (chain === 'BNB Chain') return 'BNB';
  if (chain === 'Arbitrum') return 'ARB';
  return 'ETH';
}

export const generateNumbersArray = (loops) => {
  const result = [];

  for (let i = 1; i <= loops; i++) {
    result.push(i);
  }
  return result;
};

export const shortAddress = (str, chars = 3, leading = 3) => {
  return `${str.substring(0, chars)}...${str.substring(str.length - leading)}`;
};

export const formatTvlForChart = (tvlData) => {
  if (!tvlData || !tvlData?.length) {
    return [];
  }

  const result = tvlData?.map((item) => {
    const date = new Date(+item?.timestamp).toISOString().substr(0, 10);
    const value = parseInt(ethers.utils.formatEther(item?.totalPoolValue));

    return { date, value };
  });

  return result;
};

const calculateApy = (item) => {
  const formattedPrice = Number(parseFloat(item?.adjustedTokenPrice))?.toFixed(
    4
  );
  const price = Number(formattedPrice) + 1;
  const a = 1 - price;
  let z;
  if (a >= 1) {
    z = a;
  } else if (a < 1) {
    z = a * -1;
  }
  const b = (1 + price) / 2;
  const c = (z / b) * 100;
  return Number(c?.toFixed(1));
};

export const formatPerformanceForChart = (data) => {
  if (!data || !data?.length) {
    return [];
  }

  const result = data?.map((item) => {
    const date = new Date(+item?.timestamp).toISOString().substr(0, 10);
    const apy = calculateApy(item);

    return { date, value: apy };
  });

  return result;
};

export const calculateApyForOptimism = (data, calculateMinMax) => {
  if (!data?.length) {
    return '--';
  }

  let monthStartApy;
  let monthEndApy;

  if (calculateMinMax) {
    const minValue = Math.min(...data.map((item) => +item.adjustedTokenPrice));

    const minIndex = data.findIndex(
      (el) => +el.adjustedTokenPrice === minValue
    );

    monthStartApy = calculateApy(data[minIndex]);
    monthEndApy = calculateApy(data[data?.length - 1]);
  } else {
    monthStartApy = calculateApy(data[0]);
    monthEndApy = calculateApy(data[data?.length - 1]);
  }

  return {
    value: parseFloat((monthEndApy - monthStartApy).toFixed(1)),
    isUp: monthStartApy < monthEndApy,
  };
};

export const getDaysPass = (allData, initialData) => {
  if (!allData?.length || !initialData) {
    return null
  }

  const startDate = new Date(+initialData)
  const currentDate = Date.now()
  const change = currentDate - startDate?.getTime()

  return Math.trunc(change / dayInMilliseconds)
}

export const getApy = (apyDataAll, tvlData) => {
  const daysPass = getDaysPass(apyDataAll, tvlData)
  if (!daysPass) {
    return null
  }

  const currentPerformanceValue = calculateApy(apyDataAll[apyDataAll?.length - 1])
  const initialPerformanceValue = calculateApy(apyDataAll[0])
  const formattedApy = 1 + (currentPerformanceValue / 100)
  const timeDivided = daysInYear / daysPass
  const powValue = Math.pow(formattedApy, timeDivided)
  const result = ((powValue - 1) * 100).toFixed(2)

  return {
    value: result,
    isUp: currentPerformanceValue > initialPerformanceValue
  }
}

export const getDisplayedApy = (apy) => {
  if (apy !== '--' && apy > 0) {
    return `+${apy}%`;
  } else if (apy !== '--' && apy < 0) {
    return `-${apy * -1}%`;
  } else {
    return '--';
  }
};

export const formatTotalValue = (totalValueData, poolAddress) => {
  if (!totalValueData) {
    return '0.00';
  }
  const data = totalValueData?.allFundsByInvestor?.find(
    (fund) =>
      fund?.fundAddress?.toLowerCase() === poolAddress?.toLowerCase()
  );
  if (!data) {
    return '0.00';
  }
  const formattedValue = ethers.utils.formatUnits(data?.investmentValue, 18);
  const parsedValue = parseFloat(formattedValue);
  return parsedValue?.toFixed(2);
};

export const formatProfitAndLoss = (profitAndLossData, poolAddress) => {
  if (!profitAndLossData) {
    return { roiUsd: 0.0, roiPercent: 0.0 };
  }
  const data = profitAndLossData?.allFundsByInvestor?.find(
    (fund) =>
      fund?.fundAddress?.toLowerCase() === poolAddress?.toLowerCase()
  );
  if (!data) {
    return { roiUsd: 0.0, roiPercent: 0.0 };
  }
  const valuesDecimals = 18;
  const percentDecimals = 2;
  const formattedRoiUsd = ethers.utils.formatUnits(data.roiUsd, valuesDecimals);

  const rawReturnOnInvestment = parseFloat(data?.returnOnInvestment);
  const etalon = parseFloat(10 ** valuesDecimals);
  const rawRoi = (rawReturnOnInvestment - etalon)?.toString();
  const formattedRoiPercent = ethers.utils.formatUnits(
    rawRoi,
    valuesDecimals - percentDecimals
  );

  const roiUsd = parseFloat(formattedRoiUsd)?.toFixed(2);
  const roiPercent = parseFloat(formattedRoiPercent)?.toFixed(2);
  return { roiUsd: roiUsd, roiPercent: roiPercent };
};

export const calculateTvlPercentageChange = (data) => {
  if (!data || !data?.length) return '--';

  const firstValue = parseInt(
    ethers.utils.formatEther(data[0]?.totalPoolValue)
  );
  const lastValue = parseInt(
    ethers.utils.formatEther(data[data?.length - 1]?.totalPoolValue)
  );

  const a = firstValue - lastValue;

  let z;
  if (a >= 0) {
    z = a;
  } else if (a < 0) {
    z = a * -1;
  }
  const b = firstValue;
  const c = (z / b) * 100;

  const isUp = firstValue < lastValue;
  const result = Number(c?.toFixed(2));

  return {
    value: isUp ? result : result * -1,
    isUp,
  };
};

const removeInvalidValues = (chartData) => {
  const invalidIndexes = []

  chartData.datasets[0]?.data.forEach((percentageValue, index) => {
    if (percentageValue < minimumPercentageForChart) {
      invalidIndexes.push(index)
    }
  })

  chartData.labels = chartData.labels.filter((item, index) => !invalidIndexes.includes(index))
  chartData.images = chartData.images.filter((item, index) => !invalidIndexes.includes(index))
  chartData.chainImages = chartData.chainImages.filter((item, index) => !invalidIndexes.includes(index))
  chartData.datasets[0].data = chartData.datasets[0]?.data.filter((item, index) => !invalidIndexes.includes(index))
  chartData.datasets[0].backgroundColor = chartData.datasets[0]?.backgroundColor?.filter((item, index) => !invalidIndexes.includes(index))
  chartData.datasets[0].borderColor = chartData.datasets[0]?.borderColor.filter((item, index) => !invalidIndexes.includes(index))

  return chartData
}

export const formatFilteredPoolsForChart = (filteredPools) => {
  let totalUsdBalance = 0;
  let assetUsdValues = {};

  filteredPools.forEach((item) => {
    const itemDecimals = assetsData[item?.asset]?.decimals || 18;
    const itemRateDecimals = 18;

    const itemBalanceRaw = item?.balance.toString();
    const formattedBalance = ethers.utils.formatUnits(
      itemBalanceRaw,
      itemDecimals
    );
    const balance = parseFloat(formattedBalance);

    const itemRatRaw = item?.rate.toString();
    const formattedRate = ethers.utils.formatUnits(
      itemRatRaw,
      itemRateDecimals
    );
    const rate = parseFloat(formattedRate);

    const balanceUsd = balance * rate;
    assetUsdValues[item?.asset] = balanceUsd;
    totalUsdBalance += balanceUsd;
  });

  const result = {
    labels: filteredPools.map((item) => {
      return assetsData[item?.asset]?.symbol || item?.symbol;
    }),
    images: filteredPools.map((item) => {
      if (assetsData[item?.asset]?.poolAssets) {
        return assetsData[item?.asset].poolAssets.map((poolAsset) => {
          const image = new Image();
          const allocation =
            (Number(assetUsdValues[item.asset]).toFixed(2) * 100) /
            Number(totalUsdBalance?.toFixed(2));
          if (allocation > 1) {
            image.src = poolAsset?.icon || DefaultIcon;
          }
          return image;
        });
      } else {
        const src = assetsData[item?.asset]?.icon || DefaultIcon;
        const image = new Image();
        const allocation =
          (Number(assetUsdValues[item.asset]).toFixed(2) * 100) /
          Number(totalUsdBalance?.toFixed(2));
        if (allocation > 1) {
          image.src = src;
        }
        return [image];
      }
    }),
    chainImages: filteredPools.map((item) => {
      const src = assetsData[item?.asset]?.icon || DefaultIcon;
      const image = new Image();
      image.src = src;
      return image;
    }),
    logoImage: (function () {
      const image = new Image();
      image.src = OPImage;
      return image;
    })(),
    datasets: [
      {
        label: '# of Interactions',
        data: filteredPools.map((item) => {
          const rawValue = (
            (Number(assetUsdValues[item?.asset].toFixed(2)) * 100) /
            Number(totalUsdBalance?.toFixed(2))
          ).toFixed(2);

          return Number(rawValue)
        }),
        backgroundColor: filteredPools.map((item) => {
          return assetsData[item?.asset]?.bgColor || '#F0B93A';
        }),
        borderColor: filteredPools.map((item) => '#FFFFFF'),
      },
    ],
  };

  return removeInvalidValues(result)
};

export const formatFilteredAssetsForChart = (filteredAssets) => {
  let totalUsdBalance = 0;
  let assetUsdValues = {};

  filteredAssets.forEach((item) => {
    const assetData = assetsData[item?.asset];
    if (!assetData) return;

    const itemDecimals = assetData?.decimals || 18;
    const itemBalanceRaw = item?.balance.toString();
    const formattedBalance = ethers.utils.formatUnits(
      itemBalanceRaw,
      itemDecimals
    );
    const balance = parseFloat(formattedBalance);
    const itemRateRaw = item?.rate.toString();
    const formattedRate = ethers.utils.formatUnits(itemRateRaw, 18);
    const rate = parseFloat(formattedRate);
    const balanceUsd = balance * rate;

    if (assetData?.poolAssets && assetData?.poolAssets.length > 0) {
      const sharePerAsset = balanceUsd / assetData.poolAssets.length;
      assetData.poolAssets.forEach((poolAsset) => {
        const poolAssetAddress = poolAsset.assetAddress;
        assetUsdValues[poolAssetAddress] =
          (assetUsdValues[poolAssetAddress] || 0) + sharePerAsset;
      });
    } else {
      assetUsdValues[item.asset] =
        (assetUsdValues[item.asset] || 0) + balanceUsd;
    }

    totalUsdBalance += balanceUsd;
  });

  const filteredAssetsWithoutPools = filteredAssets.filter(
    (item) => !assetsData[item?.asset]?.poolAssets
  );
  const sortedFilteredAssetsWithoutPools = filteredAssetsWithoutPools.sort(
    (a, b) => {
      const aValue = assetUsdValues[a.asset] || 0;
      const bValue = assetUsdValues[b.asset] || 0;
      return bValue - aValue;
    }
  );

  const assets = sortedFilteredAssetsWithoutPools;

  const result = {
    labels: assets.map((item) => {
      return assetsData[item?.asset]?.symbol || item?.symbol;
    }),
    images: assets.map((item) => {
      if (assetsData[item?.asset]?.poolAssets) {
        return assetsData[item?.asset].poolAssets.map((poolAsset) => {
          const image = new Image();
          const allocation =
            (Number(assetUsdValues[item.asset]).toFixed(2) * 100) /
            Number(totalUsdBalance?.toFixed(2));
          if (allocation > 1) {
            image.src = poolAsset.icon;
          }
          return image;
        });
      } else {
        const src = assetsData[item?.asset]?.icon || DefaultIcon;
        const image = new Image();
        const allocation =
          (Number(assetUsdValues[item.asset]).toFixed(2) * 100) /
          Number(totalUsdBalance?.toFixed(2));
        if (allocation > 1) {
          image.src = src;
        }
        return [image];
      }
    }),
    chainImages: assets.map((item) => {
      const src = assetsData[item?.asset]?.icon || DefaultIcon;
      const image = new Image();
      image.src = src;
      return image;
    }),
    logoImage: (function () {
      const image = new Image();
      image.src = OPImage;
      return image;
    })(),
    datasets: [
      {
        label: '# of Interactions',
        data: assets.map((item) => {
          const rawValue = (
            (Number(assetUsdValues[item.asset]).toFixed(2) * 100) /
            Number(totalUsdBalance?.toFixed(2))
          ).toFixed(2);

          return Number(rawValue)
        }),
        backgroundColor: assets.map((item) => {
          return assetsData[item?.asset]?.bgColor || '#F0B93A';
        }),
        borderColor: assets.map((item) => '#FFFFFF'),
      },
    ],
  };

  return removeInvalidValues(result)

};

export function isValidEmail(email) {
  return !!email.match(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );
}

const removeZeroFromEnd = (value) => {
  if (!value.endsWith('0')) return value;

  const res = value.slice(0, -1);
  return removeZeroFromEnd(res);
};

export const toFixed = (value, precision) => {
  if (!value) {
    return '0';
  }
  value = Number(value);
  const fixed = value.toFixed(precision + 2);
  const length = fixed.length;
  const splittedByDot = fixed.substring(0, length - 2).split('.');

  if (+splittedByDot[1] === 0) {
    return splittedByDot[0];
  }
  return removeZeroFromEnd(fixed.substring(0, length - 2));
};


export const generateIcon = (tokenName) => {
  if(tokenName?.toLowerCase().includes('usdc')) {
    return USDCIcon
  } else if(tokenName?.toLowerCase().includes('eth')) {
    return ETHIcon
  } else if(tokenName?.toLowerCase().includes('op')) {
    return OPIcon
  } else if(tokenName?.toLowerCase().includes('usdt')) {
    return USDTIcon
  } else if(tokenName?.toLowerCase().includes('ulp')) {
    return ULPIcon
  }
  return USDCIcon
}

export const generateAsset = (id, balance, rate) => {
  return {
    asset: id,
    balance,
    rate,
    isDeposit: false
  }
}

export const getContractAddress = (chainId) => {
  if(chainId === networkChainIds.binance) {
    return crosschainValutAddressBNB
  } else if (chainId === networkChainIds.poligon) {
    return crosschainValutAddressPolygon
  }

  return ''
}


export const getCrosschainAllocationData = (activeTab) => {
  if (activeTab === AllocationTabState.pools) {
    return byPoolsChartData
  } else if (activeTab === AllocationTabState.assets) {
    return byAssetsChartData
  } else if(activeTab === AllocationTabState.chains) {
    return byChainsChartData
  }
}
