import React, {useEffect} from 'react';
import {Header} from "src/layout/Header";
import {PageContainer} from "src/components/lib";
import {Footer} from "src/layout/Footer";
import {ModalType} from "src/constants/enums";
import {ConnectModal, DisconnectModal} from "src/components/modals";
import {useWallet} from "src/contexts/WalletContext";
import {useModal} from "src/contexts/ModalContext";
import {TermsText} from "./components/TermsText";

export function TermsOfUse ()  {
  const { walletAddress, disconnect } = useWallet();
  const { modalType, closeModal } = useModal();

  const handleDisconnect = () => {
    disconnect();
    closeModal();
  };

  useEffect(() => {
    if (!walletAddress) return;
    closeModal();
  }, [closeModal, walletAddress]);

  return (
    <>
      <PageContainer>
        <Header />
        <div className="flex justify-center mt-[60px] px-[24px] lg:px-[64px] py-[12px] lg:py-[14px]">
         <TermsText />
        </div>
        <div className="mt-auto pt-[72px]">
          <Footer />
        </div>
      </PageContainer>
      {modalType === ModalType.ConnectModal && <ConnectModal />}
      {modalType === ModalType.DisconnectModal && (
        <DisconnectModal handleDisconnect={handleDisconnect} />
      )}
    </>
  );
};
