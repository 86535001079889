import React, {useState} from 'react';
import {ChartsSectionTabs} from "./ChartsSectionTabs";
import {PerformanceChart} from "./PerformanceChart";
import {AllocationTabState, ChartTabState} from "../../../constants/enums";
import {TVLChart} from "./TvlChart";
import {AllocationChart} from "./AllocationChart";
import {PerformanceStableChart} from './PerformanceStableChart'
import {TvlStableChart} from './TvlStableChart'

export const Tabs = [
  { title: 'Performance', value: ChartTabState.performance} ,
  { title: 'TVL', value: ChartTabState.tvl },
  { title: 'Allocation', value: ChartTabState.allocation },
]
export const AllocationTabs = [
  { title: 'By Pools', value: AllocationTabState.pools} ,
  { title: 'By Assets', value: AllocationTabState.assets },
]

export function ChartsSection({ allocationPoolsChartData, allocationAssetsChartData, isStable }) {
  const [activeTab, setActiveTab] = useState(ChartTabState.performance)
  const [activeAllocationTab, setActiveAllocationTab] = useState(AllocationTabState.pools)

  const allocationChartData = activeAllocationTab === AllocationTabState.pools
  ? allocationPoolsChartData
  : allocationAssetsChartData;

  return (
    <div className="w-full max-w-[470px] lg:max-w-[830px]">
      <div className="flex flex-col md:flex-row justify-between ">
        <ChartsSectionTabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={Tabs}/>
        {activeTab === ChartTabState.allocation && (
          <ChartsSectionTabs
            activeTab={activeAllocationTab}
            setActiveTab={setActiveAllocationTab}
            tabs={AllocationTabs}
          />
        )}
      </div>
      {activeTab === ChartTabState.performance && (
        isStable ? <PerformanceStableChart/> : <PerformanceChart />
      )}
      {activeTab === ChartTabState.tvl && (
        isStable ? <TvlStableChart/> : <TVLChart />
      )}
      {activeTab === ChartTabState.allocation && <AllocationChart allocationChartData={allocationChartData}/>}
    </div>
  );
}
