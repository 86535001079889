import React from 'react';
import {OptimismCard} from "./OptimismCard";
import Certik from "src/assets/img/certik.png";
import Iosiro from "src/assets/img/iosiro.png";
import {EXPECTED_CAPACITY} from "../../../../constants/optimism";
import {numberToLocaleString} from "../../../../utils/helpers";


export function VaultCards ({ tvlValue, formattedTvlValue, optimismMonthlyApy, formattedCrosschainTvlValue, crosschainTvlValue }) {
  return (
    // <div className="flex flex-col items-center gap-[40px] w-full max-w-[1320px] mx-auto mt-[70px] lg:flex-row lg:gap-[24px] lg:items-stretch">
    <div className="flex flex-col items-center justify-center gap-[40px] w-full max-w-[854px] mx-auto mt-[70px] lg:flex-row lg:gap-[24px] lg:items-stretch">
      <div className="w-full lg:min-w-[415px] max-w-[415px]">
        <OptimismCard
          title="Optimism Yield Index"
          tvlValue={tvlValue}
          formattedTvlValue={formattedTvlValue}
          optimismMonthlyApy={optimismMonthlyApy}
          isOptimism={true}
          riskValue="Moderate"
          vaultUrl="optimism"
          expectedCapacity={EXPECTED_CAPACITY}
          displayCapacity={numberToLocaleString(EXPECTED_CAPACITY)}
        />
        <div className="flex flex-col items-center mt-[25px]">
          <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">Audited by:</p>
          <div className="flex justify-center gap-[16px] w-fit mt-[8px]">
            <img src={Certik} alt="certik" className="w-[74px] h-[23px]"/>
            <img src={Iosiro} alt="iosiro"  className="w-[74px] h-[23px]"/>
          </div>
        </div>
      </div>
    </div>
  );
};
