import { useQuery } from '@apollo/client';
import { Dhedge, Network, ethers } from '@dhedge/v2-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageContainer } from '../../../components/lib';
import { ConnectModal, DisconnectModal } from '../../../components/modals';
import { OptimismModal } from '../../../components/modals/OptimismModal';
import { TransactionResultModal } from '../../../components/modals/TransactionResultModal';
import { TransactionStatusModal } from '../../../components/modals/TransactionStatusModal';
import { assetsData } from '../../../constants/allocationChart';
import { ModalType } from '../../../constants/enums';
import { erc20abi } from '../../../constants/erc20abi';
import {
  optimismPoolAddress,
  optimismRpc, SNXAddress,
  transactionsLimit, transactionsLimitMobile, USDCAddress, USDTAddress,
  walletPrivateKey,
} from '../../../constants/optimism';
import { useModal } from '../../../contexts/ModalContext';
import {
  OptimismProvider,
} from '../../../contexts/OptimismContext';
import { useWallet } from '../../../contexts/WalletContext';
import useNetworkSelector from '../../../hooks/useNetworkSelector';
import { useOptimismContract } from '../../../hooks/useOptimismContract';
import { usePagination } from '../../../hooks/usePagination';
import { Footer } from '../../../layout/Footer';
import { Header } from '../../../layout/Header';
import {
  formatFilteredAssetsForChart,
  formatFilteredPoolsForChart,
  formatProfitAndLoss,
  formatTotalValue,
  toFixed,
} from '../../../utils/helpers';
import { TokenSelectModal } from '../../SolanaBridgePage/components/TokenSelectModal';
import {
  PROFIT_AND_LOSS_QUERY,
  TOTAL_VALUE_QUERY,
  TRANSACTIONS_HISTORY_QUERY,
} from '../query';
import { ChartsSection } from '../components/ChartsSection';
import { OptimismInfo } from '../components/OptimismInfo';
import { Transactions } from '../components/Transactions';
import {CrosschainVaultStrategy} from "./components/CrosschainVaultStrategy";
import {CrosschainSecurityComponent} from "./components/CrosschainSecurityComponent";
import {
  crosschainDepositTokenData,
  EXPECTED_CROSSCHAIN_CAPACITY,
  networkConfigs
} from "../../../constants/crosschain";
import {useCrosschainVault} from "../../../hooks/useCrosschainVault";
import {CrosschainChartsSection} from "./components/CrosschainChartSection";
import {useCrosschainCapacity} from "../../../hooks/useCrosscahinCapacity";

export function Crosschain() {
  const { provider, walletAddress, disconnect } = useWallet();
  const { modalType, closeModal, showModal } = useModal();
  const { getOptimismFees, getTimeToWithdrawal } = useOptimismContract();
  const { depositToCrosschain, withdrawFromCrosschain, getLpAmount, userBalance } = useCrosschainVault();

  const [tokenBalance, setTokenBalance] = useState('0');
  const [depositAmount, setDepositAmount] = useState('');
  const [chainIndex, setChainIndex] = useState(0);
  const [depositTokenIndex, setDepositTokenIndex] = useState(0);
  const [showingDepositTokenSelectModal, setShowingDepositTokenSelectModal] =
    useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [withdrawalTokenIndex, setWithdrawalTokenIndex] = useState(0);
  const [
    showingWithdrawalTokenSelectModal,
    setShowingWithdrawalTokenSelectModal,
  ] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(1);
  const [transactionWithdrawalStatus, setTransactionWithdrawalStatus] =
    useState(1);
  const [dhedgeSDK, setDhedgeSDK] = useState(null);
  const [feesData, setFeesData] = useState(null);
  const [depositAssetsList, setDepositAssetsList] = useState(null);
  const [allocationAssetsChartData, setAllocationAssetsChartData] =
    useState(null);
  const [allocationPoolsChartData, setAllocationPoolsChartData] =
    useState(null);
  const [isTxSuccess, setIsTxSuccess] = useState(false);
  const [successDepositTxHash, setSuccessDepositTxHash] = useState('');
  const [successWithdrawTxHash, setSuccessWithdrawTxHash] = useState('');
  const [lpTokenBalance, setLpTokenBalance] = useState('0');
  const [lpRawAmount, setLpRawAmount] = useState('0');

  const [termsAgreement, setTermsAgreement] = useState(false)
  const [riskAgreement, setRiskAgreement] = useState(false)

  const {capacity: crosschainCapacity} = useCrosschainCapacity()

  const getPoolInfo = useCallback(async () => {
    if (!dhedgeSDK) return;
    const pool = await dhedgeSDK?.loadPool(optimismPoolAddress);
    const assets = await pool?.getComposition();
    if (!assets || !assets?.length) {
      setDepositAssetsList([]);
      setAllocationPoolsChartData([]);
      setAllocationAssetsChartData([]);
    }
    const filteredDepositAssets = assets.filter((item) => item?.isDeposit);
    setDepositAssetsList(filteredDepositAssets);

    const sortedAssets = assets.sort((a, b) => {
      const aDecimals = assetsData[a?.asset].decimals;
      const bDecimals = assetsData[b?.asset].decimals;
      const aValue = a?.balance
        .mul(a?.rate)
        .div(ethers.BigNumber.from(10).pow(aDecimals + 18));
      const bValue = b?.balance
        .mul(b?.rate)
        .div(ethers.BigNumber.from(10).pow(bDecimals + 18));
      return bValue.sub(aValue).toNumber();
    });

    const filteredPoolsData = sortedAssets.filter((item) => {
      return item?.balance.gt(0)
    });
    const filteredAssetsData = sortedAssets.filter((item) => {
      if(item?.asset === USDTAddress || item?.asset === SNXAddress || item?.asset === USDCAddress) {
        return true
      }
      return item?.balance.gt(0)
    });

    const formattedFilteredPoolsData =
      formatFilteredPoolsForChart(filteredPoolsData);
    const formattedFilteredAssetsData =
      formatFilteredAssetsForChart(filteredAssetsData);
    setAllocationPoolsChartData(formattedFilteredPoolsData);
    setAllocationAssetsChartData(formattedFilteredAssetsData);
  }, [dhedgeSDK]);

  const { data, loading } = useQuery(TRANSACTIONS_HISTORY_QUERY, {
    variables: {
      filter: {
        timeFrom: 0,
        fundAddresses: [optimismPoolAddress],
      },
      limit: 1000
    },
  });

  const txCount = useMemo(() => {
    if(data?.tradeEvents && data?.tradeEvents?.length) {
      return data?.tradeEvents?.length
    }
    return 0
  }, [data])


  const { renderList, totalPages, page, changePage } = usePagination(
    data?.tradeEvents,
    loading,
    transactionsLimit
  );

  const { renderList: mobileRenderList, totalPages: mobileTotalPages, page: mobilePage, changePage: mobileChangePage } = usePagination(
    data?.tradeEvents,
    loading,
    transactionsLimitMobile
  );

  const { data: profitAndLossData } = useQuery(PROFIT_AND_LOSS_QUERY, {
    variables: { investorAddress: walletAddress },
  });

  const profitAndLoss = useMemo(() => {
    return formatProfitAndLoss(profitAndLossData, optimismPoolAddress);
  }, [profitAndLossData]);

  const { getNetworkId, setWalletNetwork } = useNetworkSelector();

  const handleDisconnect = () => {
    disconnect();
    closeModal();
  };

  const handleCloseSelectTokenModal = () => {
    setShowingDepositTokenSelectModal(false);
    showModal(ModalType.DepositModal);
  };

  const handleCloseWithdrawalSelectTokenModal = () => {
    setShowingWithdrawalTokenSelectModal(false);
    showModal(ModalType.WithdrawModal);
  };

  const getFeesData = async () => {
    const data = await getOptimismFees();
    if (data) {
      setFeesData(data);
    }
  };

  const fetchTokenBalance = useCallback(
    async (chainIndex, tokenIndex, tokenAddress) => {
      if (!walletAddress || !provider) {
        return;
      }

      let erc20Contract;
      const tokenInfo = crosschainDepositTokenData[chainIndex].tokens[tokenIndex];

      if (tokenAddress) {
        const {formattedTokenAmount, formattedLpAmount} = await getLpAmount(tokenInfo.tokenAddress)
        setLpTokenBalance(formattedTokenAmount)
        setLpRawAmount(formattedLpAmount)
        return
      } else {
        if (tokenInfo?.tokenSymbol === 'ETH') {
          const ethBalance = await window.ethereum.request({
            method: 'eth_getBalance',
            params: [walletAddress],
          });
          const formattedBalance = ethers.utils.formatEther(ethBalance);
          setTokenBalance(formattedBalance);
          return;
        } else {
          erc20Contract = new ethers.Contract(
            tokenInfo.tokenAddress,
            erc20abi,
            provider
          );
        }
      }
      try {
        const balance = await erc20Contract.balanceOf(walletAddress);
        let walletBalance;
        walletBalance = ethers.utils.formatUnits(balance, tokenInfo.decimals);
        setTokenBalance(toFixed(walletBalance, 6));
      } catch (err) {
        console.error(err);
      }
    },
    [walletAddress, provider]
  );

  const handleDepositTokenSelect = async (chainIndex, tokenIndex) => {
    const networkId = await getNetworkId();
    fetchTokenBalance(chainIndex, tokenIndex);
    if (networkId !== crosschainDepositTokenData[chainIndex].chainId) {
      const status = await setWalletNetwork(
        crosschainDepositTokenData[chainIndex].chainId
      );
      if (status) {
        setChainIndex(chainIndex);
        setDepositTokenIndex(tokenIndex);
        handleCloseSelectTokenModal();
      }
    } else {
      setChainIndex(chainIndex);
      setDepositTokenIndex(tokenIndex);
      handleCloseSelectTokenModal();
    }
  };

  const handleWithdrawalTokenSelect = async (chainIndex, tokenIndex) => {
    const networkId = await getNetworkId();
    if (networkId !== crosschainDepositTokenData[chainIndex].chainId) {
      const status = await setWalletNetwork(
        crosschainDepositTokenData[chainIndex].chainId
      );
      if (status) {
        setChainIndex(chainIndex);
        setWithdrawalTokenIndex(tokenIndex);
        handleCloseWithdrawalSelectTokenModal();
      }
    } else {
      setChainIndex(chainIndex);
      setWithdrawalTokenIndex(tokenIndex);
      handleCloseWithdrawalSelectTokenModal();
    }
  };

  const openSelectTokenModal = () => {
    closeModal();
    setShowingDepositTokenSelectModal(true);
  };

  const openWithdrawalSelectTokenModal = () => {
    closeModal();
    setShowingWithdrawalTokenSelectModal(true);
  };

  const onOpenDepositModal = () => {
    fetchTokenBalance(chainIndex, depositTokenIndex, '');
  };

  const onOpenWithdrawalModal = () => {
    fetchTokenBalance(chainIndex, withdrawalTokenIndex, true);
  };

  const resetTransactionStatus = () => {
    setTransactionStatus(1);
  };

  const resetWithdrawalTransactionStatus = () => {
    setTransactionWithdrawalStatus(1);
  };

  const handleDeposit = useCallback(async () => {
    const depositTokenInfo = crosschainDepositTokenData[chainIndex].tokens[depositTokenIndex];
    const depositAmountRaw = ethers.utils.parseUnits(
      depositAmount,
      depositTokenInfo.decimals
    );

    await depositToCrosschain(
      depositTokenInfo,
      depositAmountRaw,
      setTransactionStatus,
      setIsTxSuccess,
      setSuccessDepositTxHash,
    );
  }, [depositAmount, depositTokenIndex, depositToCrosschain]);

  const openDepositTransactionStatusModal = useCallback(() => {
    if (!depositAmount && !parseFloat(depositAmount)) {
      return;
    }
    handleDeposit();
    closeModal();
    showModal(ModalType.DepositTransactionStatusModal);
  }, [depositAmount]);

  const convertTokenToLp = (tokenAmountInput) => {
    const tokenAmount = parseFloat(tokenAmountInput)
    const maxToken = parseFloat(lpTokenBalance)
    const maxLpToken = parseFloat(lpRawAmount)
    const lpAmount = tokenAmount * maxLpToken / maxToken
    return lpAmount.toFixed(6)
  }

  const handleWithdraw = useCallback(async () => {
    const depositTokenInfo = crosschainDepositTokenData[chainIndex].tokens[withdrawalTokenIndex];
    const withdrawalLpAmount = convertTokenToLp(withdrawalAmount)
    const amount = ethers.utils.parseUnits(withdrawalLpAmount, 6);
    await withdrawFromCrosschain(
      depositTokenInfo,
      amount,
      setTransactionWithdrawalStatus,
      setIsTxSuccess,
      setSuccessWithdrawTxHash,
    );
  }, [withdrawalTokenIndex, chainIndex, withdrawalAmount])

  const openWithdrawTransactionStatusModal = () => {
    handleWithdraw();
    closeModal();
    showModal(ModalType.WithdrawalTransactionStatusModal);
  };

  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(optimismRpc);
    const walletWithProvider = new ethers.Wallet(walletPrivateKey, provider);
    const dhedge = new Dhedge(walletWithProvider, Network.OPTIMISM);
    setDhedgeSDK(dhedge);

    getFeesData();
  }, []);

  useEffect(() => {
    getPoolInfo();
  }, [getPoolInfo]);

  useEffect(() => {
    if (!walletAddress) return;

    if (modalType === ModalType.ConnectModal) {
      closeModal();
    }
  }, [closeModal, walletAddress, modalType]);

  return (
    <OptimismProvider>
      <PageContainer>
        <Header />
        <div className="flex flex-col gap-[20px] max-w-[470px] lg:max-w-[unset] w-full px-[24px] lg:px-[64px] py-[24px] lg:py-[54px] lg:flex-row mx-auto">
          <OptimismInfo
            isOptimism={false}
            feesData={feesData}
            assetsList={depositAssetsList}
            totalValue={userBalance}
            isBalance={true}
            profitAndLoss={profitAndLoss}
            timeLeftToWithdraw={0}
            sttrategyText="Strategy Overview: The Crosschain Yield Index BETA simplifies your investment journey, allowing you to seamlessly stake your USDT into Stargate across Polygon and BNB Smart Chain with One Click."
            tvl={crosschainCapacity}
            networkId={crosschainDepositTokenData[chainIndex].chainId}
            expectedCapacity={EXPECTED_CROSSCHAIN_CAPACITY}
            title="Optimism Yield Index"
          />
          <CrosschainChartsSection />
        </div>
        <div className="flex flex-col gap-[32px] w-full mt-[32px] px-[24px] lg:px-[64px] lg:flex-row ">
          <CrosschainVaultStrategy />
          <CrosschainSecurityComponent />
        </div>
        {/*<Transactions*/}
        {/*  data={renderList}*/}
        {/*  txCount={txCount}*/}
        {/*  page={page}*/}
        {/*  changePage={changePage}*/}
        {/*  totalPages={totalPages}*/}
        {/*  mobileData={mobileRenderList}*/}
        {/*  mobilePage={mobilePage}*/}
        {/*  mobileChangePage={mobileChangePage}*/}
        {/*  mobileTotalPages={mobileTotalPages}*/}
        {/*/>*/}
        <div className="mt-auto pt-[72px]">
          <Footer />
        </div>
      </PageContainer>
      {showingDepositTokenSelectModal && (
        <TokenSelectModal
          title={'Deposit as'}
          data={crosschainDepositTokenData}
          chainIndex={chainIndex}
          tokenIndex={depositTokenIndex}
          onSelect={handleDepositTokenSelect}
          onClose={handleCloseSelectTokenModal}
          className="lg:mt-[180px]"
        />
      )}
      {showingWithdrawalTokenSelectModal && (
        <TokenSelectModal
          title={'Withdrawal as'}
          data={crosschainDepositTokenData}
          chainIndex={chainIndex}
          tokenIndex={withdrawalTokenIndex}
          onSelect={handleWithdrawalTokenSelect}
          onClose={handleCloseWithdrawalSelectTokenModal}
          className="lg:mt-[180px]"
        />
      )}
      {modalType === ModalType.ConnectModal && <ConnectModal />}
      {modalType === ModalType.DepositTransactionStatusModal && (
        <TransactionStatusModal
          type="Deposit"
          step={transactionStatus}
          onModalClose={resetTransactionStatus}
          onBack={() => showModal(ModalType.DepositModal)}
        />
      )}
      {modalType === ModalType.WithdrawalTransactionStatusModal && (
        <TransactionStatusModal
          type="Withdraw"
          step={transactionWithdrawalStatus}
          onModalClose={resetWithdrawalTransactionStatus}
          onBack={() => showModal(ModalType.WithdrawModal)}
        />
      )}
      {modalType === ModalType.DepositModal && (
        <OptimismModal
          title="Deposit"
          buttonText="Deposit"
          label="Buy with"
          feeText="Entry Fee"
          feeValue={feesData?.entry}
          depositAMount={depositAmount}
          setDepositAmount={setDepositAmount}
          tokenData={crosschainDepositTokenData}
          tokenIndex={depositTokenIndex}
          chainIndex={chainIndex}
          tokenBalance={tokenBalance}
          depositAssetsList={depositAssetsList}
          onOpen={onOpenDepositModal}
          openSelectTokenModal={openSelectTokenModal}
          openTransactionStatusModal={openDepositTransactionStatusModal}
          isDeposit={true}
          termsAgreement={termsAgreement}
          riskAgreement={riskAgreement}
          setTermsAgreement={setTermsAgreement}
          setRiskAgreement={setRiskAgreement}
        />
      )}
      {modalType === ModalType.WithdrawModal && (
        <OptimismModal
          title="Withdrawal"
          buttonText="Withdrawal"
          label="Set the amount"
          feeText="Withdrawal Fee"
          depositAMount={withdrawalAmount}
          setDepositAmount={setWithdrawalAmount}
          tokenData={crosschainDepositTokenData}
          tokenIndex={withdrawalTokenIndex}
          chainIndex={chainIndex}
          openSelectTokenModal={openWithdrawalSelectTokenModal}
          openTransactionStatusModal={openWithdrawTransactionStatusModal}
          tokenBalance={lpTokenBalance}
          depositAssetsList={depositAssetsList}
          onOpen={onOpenWithdrawalModal}
          isDeposit={false}
          isLpToken={true}
          // error={true}
        />
      )}
      {modalType === ModalType.DepositResultModal && (
        <TransactionResultModal
          transactionName="Deposit"
          tokenSymbol={
            crosschainDepositTokenData[chainIndex].tokens[depositTokenIndex].tokenSymbol
          }
          tokenImageId={
            crosschainDepositTokenData[chainIndex].tokens[depositTokenIndex].tokenImageId
          }
          chainImageName={crosschainDepositTokenData[chainIndex].chainImageName}
          success={isTxSuccess}
          onBack={() => showModal(ModalType.DepositModal)}
          successTxHash={successDepositTxHash}
          amount={depositAmount}
          network={crosschainDepositTokenData[chainIndex].chainName}
          networkId={crosschainDepositTokenData[chainIndex].chainId}
          scan={networkConfigs[crosschainDepositTokenData[chainIndex].chainId].blockExplorerUrls}
        />
      )}
      {modalType === ModalType.WithdrawalResultModal && (
        <TransactionResultModal
          transactionName="Withdrawal"
          tokenSymbol={
            crosschainDepositTokenData[chainIndex].tokens[withdrawalTokenIndex]
              .tokenSymbol
          }
          tokenImageId={
            crosschainDepositTokenData[chainIndex].tokens[withdrawalTokenIndex]
              .tokenImageId
          }
          chainImageName={crosschainDepositTokenData[chainIndex].chainImageName}
          success={isTxSuccess}
          onBack={() => showModal(ModalType.WithdrawModal)}
          successTxHash={successWithdrawTxHash}
          amount={withdrawalAmount}
          network={crosschainDepositTokenData[chainIndex].chainName}
          networkId={crosschainDepositTokenData[chainIndex].chainId}
          scan={networkConfigs[crosschainDepositTokenData[chainIndex].chainId].blockExplorerUrls}
        />
      )}
      {modalType === ModalType.DisconnectModal && (
        <DisconnectModal handleDisconnect={handleDisconnect} />
      )}
    </OptimismProvider>
  );
}
