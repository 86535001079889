import React from 'react';
import {format, parseISO} from "date-fns";

export function ChartTooltip ({active, payload, label, title, formatter, Icon}) {

  if(active) {
    const percents = formatter(payload)
    const formattedDate = format(parseISO(label), 'eeee d/M/yyyy  hh:mm')

    return (
      <div className="pt-[4px] pr-[8px] pb-[8px] pl-[8px] bg-white dark:bg-grey-darker5 w-fit">
        <p className="text-[12px] leading-[15px] text-grey-black dark:text-white">{formattedDate}</p>
        <div className="flex gap-[4px]">
          <Icon className="w-[14px] h-[14px]"/>
          <p className="text-[12px] leading-[15px] text-grey-deep">{title}</p>
          <p className="text-[12px] leading-[15px] text-grey-black dark:text-white">{percents}</p>
        </div>
      </div>
    )
  }

  return null
}