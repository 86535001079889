import {
  createContext,
  useContext,
  useEffect, useMemo,
  useState,
} from "react";
import {Connection} from "@solana/web3.js";
import { WalletProvider } from '@solana/wallet-adapter-react'
import {PhantomWalletAdapter, SolflareWalletAdapter} from '@solana/wallet-adapter-wallets'
import {SlopeWalletAdapter} from '@solana/wallet-adapter-slope'
import {WalletReadyState} from "@solana/wallet-adapter-base";

const SolanaContext = createContext();
SolanaContext.displayName = "Solana Context";

const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new SlopeWalletAdapter(),
];

function SolanaProvider({ children }) {
  const [connection, setConnection] = useState(null)

  const availableWallets = useMemo(() => {
    return wallets.filter(wallet => [WalletReadyState.Loadable, WalletReadyState.Installed].includes(wallet.readyState));
  }, [wallets]);

  useEffect(() => {
    if (connection !== null) {
      return
    }
    setConnection(new Connection(
      'https://purple-few-pool.solana-mainnet.quiknode.pro/9f5d3ca543922a0b4a0b05b931c4515e667d226e/',
      {
        commitment: 'confirmed',
        wsEndpoint: 'wss://purple-few-pool.solana-mainnet.quiknode.pro/9f5d3ca543922a0b4a0b05b931c4515e667d226e/'
      }
    ))
  }, [connection]);

  if (!connection) {
    return null
  }

  return (
      <WalletProvider wallets={availableWallets}>
        <SolanaContext.Provider value={{connection}}>{children}</SolanaContext.Provider>
      </WalletProvider>
  );
}

const useSolana = () => {
  const value = useContext(SolanaContext);
  if (!value)
    throw new Error("useSolana hook must be used within SolanaProvider");
  return value;
};

export { useSolana, SolanaProvider };
