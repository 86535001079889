import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from 'src/assets/img/icons/logo.svg';
import { ReactComponent as Risk } from 'src/assets/img/icons/risk.svg';
import { ReactComponent as LowRisk } from 'src/assets/img/icons/low-risk.svg';
import { ReactComponent as Line } from 'src/assets/img/icons/vertical-dots.svg';
import { ReactComponent as Optimism } from 'src/assets/img/optimism.svg';
import { ReactComponent as Stable } from 'src/assets/img/stable.svg';
import CrosschaiImage from 'src/assets/img/crosschain.png';
import ChainsImg from 'src/assets/img/chains-polygon-bnb.png';
import {
  EXPECTED_CAPACITY,
  HUNDRED_PERCENTS,
} from '../../../../constants/optimism';
import { getDisplayedApy } from '../../../../utils/helpers';

export function OptimismCard({
  tvlValue,
  formattedTvlValue,
  optimismMonthlyApy,
  isOptimism,
  title,
  riskValue,
  vaultUrl,
  expectedCapacity,
  displayCapacity
}) {
  const navigate = useNavigate();
  const [rangeComponentWidth, setRangeComponentWidth] = useState(0);

  const navigateToVault = () => {
    navigate(`/vaults/${vaultUrl}`);
  };

  const currentCapacity =
    tvlValue && (tvlValue * HUNDRED_PERCENTS) / expectedCapacity;
  const formattedCurrentCapacity = currentCapacity?.toFixed(2);
  const marginLeft = (rangeComponentWidth / HUNDRED_PERCENTS) * currentCapacity;

  const apy = getDisplayedApy(optimismMonthlyApy?.value);

  const refCallback = (element) => {
    if (!element) return;
    const width = element.getBoundingClientRect().width;
    setRangeComponentWidth(width);
  };

  return (
    <div className="w-full max-w-[415px] shadow-xxl rounded-[24px] relative">
      {isOptimism && <Stable className="absolute w-[82px] h-[82px] top-[-12px] right-[12px]"/>}
      {!isOptimism && (
        <div className="absolute w-[82px] h-[82px] top-[-12px] right-[12px] rounded-full overflow-hidden">
         <img src={CrosschaiImage} className="w-full h-full" alt="crosschain"/>
        </div>
      )}
      {!isOptimism && (
        <p
         className="absolute left-[40px] top-[-17px] flex items-center justify-center rounded-t-[4px] w-[52px] h-[22px] bg-yellow-dark-20 text-yellow-dark text-[16px] leading-[15px] font-normal">
         BETA
        </p>
      )}
      <Logo className="absolute w-[30px] h-[32px] top-[41px] right-[14px]" />
      <div className="flex flex-col gap-[16px] w-full px-[24px] pt-[18px] pb-[12px]">
        <h3 className="text-[20px] leading-[22.1px] text-grey-dark dark:text-white">
          {title}
        </h3>
        <div className="flex justify-between w-full max-w-[280px]">
          <div className="flex flex-col gap-[16px]">
            <div>
              <p className="font-medium text-[12px] leading-4 text-grey-deep">
                Chain
              </p>
              <div className="flex mt-[4px]">
                {isOptimism && (
                  <>
                    <Optimism/>
                    <p className="font-medium text-[16px] leading-[17.6px] ml-[8px] text-grey-dark dark:text-white">
                      Optimism
                    </p>
                  </>
                )}
                {!isOptimism && <img src={ChainsImg} className="w-[34px] h-[20px]" alt="chains"/>}
              </div>
            </div>
            <div>
              <p className="font-medium text-[12px] leading-4 text-grey-deep">
                Estimated APY
              </p>
              {isOptimism && (
                <div className="flex mt-[4px]">
                  {optimismMonthlyApy?.isUp ? (
                    <p className="font-caption font-medium text-[20px] leading-[22.8px] text-green-lighter1">
                      {apy}
                    </p>
                  ) : (
                    <p className="font-caption font-medium text-[20px] leading-[22.8px] text-red-light">
                      {apy}
                    </p>
                  )}
                </div>
              )}
              {!isOptimism && (
                <div className="flex mt-[4px]">
                    <p className="font-caption font-medium text-[20px] leading-[22.8px] text-green-lighter1">
                      9,02%
                    </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[16px]">
            <div>
              <p className="font-medium text-[12px] leading-4 text-grey-deep">
                TVL
              </p>
              <div className="flex mt-[4px]">
                <p className="font-medium text-[16px] leading-[17.6px] text-grey-dark dark:text-white">
                  ${formattedTvlValue}
                </p>
              </div>
            </div>
            <div>
              <p className="font-medium text-[12px] leading-4 text-grey-deep">
                Risk
              </p>
              <div className="flex mt-[4px]">
                <div className="ml-[1px]">
                  <LowRisk/>
                </div>
                <p className={`font-medium text-[16px] leading-[17.6px] ml-[6px] text-green-lighter1`}>
                  {riskValue}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
            Capacity
          </p>
          <div>
            <div className="flex justify-between">
              <div className="flex flex-col items-center">
                <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
                  0$
                </p>
                <Line />
              </div>
              <div className="flex flex-col items-end">
                <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
                  ${displayCapacity}
                </p>
                <Line />
              </div>
            </div>
            <div className="relative h-[6px]">
              <div
                ref={refCallback}
                className="w-full h-full rounded-[20px] bg-grey-lightest mt-[2px] opacity-[0.3]"
              />
              <div
                className="h-full rounded-[20px] bg-yellow-inputRange shadow-sm absolute top-0 max-w-[100%]"
                style={{ width: currentCapacity + '%' }}
              />
            </div>
            <div
              className={'flex flex-col items-start w-fit mt-[2px]'}
              style={{ marginLeft: marginLeft + 'px' }}
            >
              <Line />
              <p
                className="font-medium text-[12px] leading-[13.2px] text-grey-deep"
                style={{ marginLeft: marginLeft > 10 ? '-10px' : '' }}
              >
                {formattedCurrentCapacity}%
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={navigateToVault}
        className="flex justify-center items-center py-[12px] bg-grey-lighter5-30 border-t-[1px] border-solid border-grey-lightest-39 cursor-pointer hover:bg-yellow-lightest-50 rounded-b-[24px]"
      >
        <p className="font-caption text-yellow-main font-medium text-[14px] leading-[15.4px]">
          Explore Vault
        </p>
      </div>
    </div>
  );
}
