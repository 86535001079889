import React from 'react';
import {
  EXPECTED_CAPACITY,
  HUNDRED_PERCENTS,
} from 'src/constants/optimism';
import {CapacityComponent} from "../../components/CapacityComponent";

export function OptimismCapacity({ tvlValue }) {
  const currentCapacity =
    tvlValue && (tvlValue * HUNDRED_PERCENTS) / EXPECTED_CAPACITY;

  return (
    <CapacityComponent
      currentCapacity={currentCapacity}
      expectedCapacity={EXPECTED_CAPACITY}
    />
  );
}
