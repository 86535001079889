import USDCIcon from 'src/assets/img/tokens/usdc.png'
import USDTIcon from 'src/assets/img/tokens/usdt.png'
import SNXIcon from 'src/assets/img/tokens/snx.png'
import OPIcon from 'src/assets/img/tokens/op.png'
import ETHIcon from 'src/assets/img/tokens/eth.png'
import DAIIcon from 'src/assets/img/tokens/dai.png'
import ETHyIcon from 'src/assets/img/tokens/ethy.png'
import WBTCIcon from 'src/assets/img/tokens/wbtc.png'
import AAVEIcon from 'src/assets/img/tokens/aave.png'
import LinkIcon from 'src/assets/img/tokens/link.png'
import UniIcon from 'src/assets/img/tokens/ulp.png'
import VelodromeIcon from 'src/assets/img/tokens/velodrome.png'

export const assetsData = {
  "0x7F5c764cBc14f9669B88837ca1490cCa17c31607": {
    symbol: 'USDC.e',
    icon: USDCIcon,
    bgColor: '#2775CA',
    decimals: 6
  },
  "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1": {
    symbol: 'DAI',
    icon: DAIIcon,
    bgColor: '#F0B93A',
    decimals: 18
  },
  "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58": {
    symbol: 'USDT',
    icon: USDTIcon,
    bgColor: '#26A27A',
    decimals: 6
  },
  "0x68f180fcCe6836688e9084f035309E29Bf0A2095": {
    symbol: 'WBTC',
    icon: WBTCIcon,
    bgColor: '#FCC44B',
    decimals: 8
  },
  "0x4200000000000000000000000000000000000006": {
    symbol: 'WETH',
    icon: ETHIcon,
    bgColor: '#627EEA',
    decimals: 18
  },
  "0x4200000000000000000000000000000000000042": {
    symbol: 'OP',
    icon: OPIcon,
    bgColor: '#E31818',
    decimals: 18,
  },
  "0x0df083de449F75691fc5A36477a6f3284C269108": {
    symbol: 'vAMMV2-OP/USDC.e',
    poolAssets: [
      {
        symbol:'OP',
        assetAddress: '0x4200000000000000000000000000000000000042',
        icon: OPIcon,
      },
      {
        symbol: 'USDC.e',
        assetAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        icon: USDCIcon,
      }
    ],
    icon: OPIcon,
    bgColor: '#2775CA',
    decimals: 18,
  },
  "0x0493Bf8b6DBB159Ce2Db2E0E8403E753Abd1235b": {
    symbol: 'vAMMV2-WETH/USDC.e',
    poolAssets: [
      {
        symbol:'WETH',
        assetAddress: '0x4200000000000000000000000000000000000006',
        icon: ETHIcon,
      },
      {
        symbol: 'USDC.e',
        assetAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        icon: USDCIcon,
      }
    ],
    icon: ETHIcon,
    bgColor: '#08AFD9',
    decimals: 18,
  },
  "0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb": {
    symbol: 'wstETH',
    icon: ETHIcon,
    bgColor: '#627EEA',
    decimals: 18,
  },
  "0xb2cFb909e8657C0EC44D3dD898C1053b87804755": {
    symbol: 'ETHy',
    poolAssets: [
      {
        symbol:'WETH',
        assetAddress: '0x4200000000000000000000000000000000000006',
        icon: ETHIcon,
      },
    ],
    icon: ETHyIcon,
    bgColor: '#627EEA',
    decimals: 18,
  },
  "0x794a61358D6845594F94dc1DB02A252b5b4814aD": {
    symbol: 'Lend/Borrow V3',
    poolAssets: [
      {
        symbol:'USDT',
        assetAddress: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        icon: AAVEIcon,
      },
    ],
    icon: AAVEIcon,
    bgColor: '#1DA1F2',
    decimals: 18,
  },
  "0x71d53B5B7141E1ec9A3Fc9Cc48b4766102d14A4A": {
    symbol: 'Velo V2 USDC.e-SNX',
    poolAssets: [
      {
        symbol:'USDC.e',
        assetAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        icon: USDCIcon,
      },
      {
        symbol:'SNX',
        assetAddress: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        icon: SNXIcon,
      },
    ],
    icon: USDCIcon,
    bgColor: '#2488C6',
    decimals: 18,
  },
  "0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4": {
    symbol: 'SNX',
    poolAssets: [
      {
        symbol:'SNX',
        assetAddress: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        icon: SNXIcon,
      },
    ],
    icon: SNXIcon,
    bgColor: '#1DA1F2',
    decimals: 18,
  },
  "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85": {
    symbol: 'SNX',
    icon: SNXIcon,
    bgColor: '#1DA1F2',
    decimals: 6
  },
  "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6": {
    symbol: 'LINK',
    icon: LinkIcon,
    bgColor: '#0C6FD2',
    decimals: 18
  },
  "0xC36442b4a4522E871399CD717aBDD847Ab11FE88": {
    symbol: 'ULP',
    poolAssets: [
      {
        symbol:'LINK',
        assetAddress: '0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6',
        icon: LinkIcon,
      },
      {
        symbol:'USDC.e',
        assetAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        icon: USDCIcon,
      },
    ],
    icon: UniIcon,
    bgColor: '#EC495A',
    decimals: 18
  },
  "0xd25711EdfBf747efCE181442Cc1D8F5F8fc8a0D3": {
    symbol: 'Velo V2 WETH-OP',
    poolAssets: [
      {
        symbol:'WETH',
        assetAddress: '0x4200000000000000000000000000000000000006',
        icon: ETHIcon,
      },
      {
        symbol:'OP',
        assetAddress: '0x4200000000000000000000000000000000000042',
        icon: OPIcon,
      },
    ],
    icon: OPIcon,
    bgColor: '#2775CA',
    decimals: 18,
  },
  "0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db": {
    symbol: 'VelodromeV2',
    poolAssets: [
      {
        symbol:'USDC.e',
        assetAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        icon: USDCIcon,
      },
    ],
    icon: VelodromeIcon,
    bgColor: '#2775CA',
    decimals: 18,
  },
  "481953": {
    symbol: 'Uni v3 USDC.e / OP',
    poolAssets: [
      {
        symbol:'OP',
        assetAddress: '0x4200000000000000000000000000000000000042',
        icon: OPIcon,
      },
      {
        symbol:'USDC.e',
        assetAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        icon: USDCIcon,
      },
    ],
    icon: UniIcon,
    bgColor: '#EC495A',
    decimals: 18
  },
  "481946": {
    symbol: 'Uni v3 DAI / WETH',
    poolAssets: [
      {
        symbol:'WETH',
        assetAddress: '0x4200000000000000000000000000000000000006',
        icon: ETHIcon,
      },
      {
        symbol:'DAI',
        assetAddress: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        icon: DAIIcon,
      },
    ],
    icon: UniIcon,
    bgColor: '#F0B93A',
    decimals: 18
  },
}
