import { ReactComponent as LogoSvg } from "src/assets/img/logo-icon.svg";

export function FullPageLoader({
  title = "Crafting for your amazing stuff...",
  description = "Please wait, working on your request",
  className,
}) {
  return (
    <div
      className={`fixed left-0 top-0 w-full h-full flex flex-col justify-center items-center z-50 ${className}`}
    >
      <LogoSvg className="h-[130px] mx-auto animate-pulse" />
      <h4 className="text-[20px] leading-[1.14] text-center text-grey-dark dark:text-white mt-[32px]">
        {title}
      </h4>
      <p className="text[16px] leading-[1.2] mx-auto text-center text-grey-dark dark:text-white tracing-[-0.5px] font-normal mt-[8px]">
        {description}
      </p>
    </div>
  );
}
