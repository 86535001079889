import React from 'react';
import {ReactComponent as CheckboxActive} from "src/assets/img/icons/chekbox-active.svg";

export function Checkbox({value, setValue}) {
  const changeValue = () => {
    setValue(prev => !prev)
  }

  return (
    <div onClick={changeValue} className="w-[28px] h-[28px] flex justify-center items-center cursor-pointer">
      {!value && <div className="w-[21px] h-[21px] rounded-[4px] border-solid border-2 border-grey-deep" />}
      {value && <CheckboxActive className="w-[21px] h-[21px]" />}
    </div>
  );
}