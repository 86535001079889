import axios from "axios";
import { useState } from "react";
import { ModalType } from "src/constants/enums";
import { useModal } from "src/contexts/ModalContext";
import * as Sentry from "@sentry/react";

export function useJoinWaitlist() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { showModal } = useModal();

  const joinWaitlist = ({
    email,
    discordUsername,
    discordId,
    discordEmail,
    walletAddress,
    referralCode,
    onSuccess,
  }) => {
    setIsLoading(true);
    setError("");
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/waitlist/join-with-discord`, {
        email,
        discordUsername: discordUsername,
        discordId: discordId,
        discordEmail: discordEmail,
        walletAddress: walletAddress,
        referralCode: referralCode,
      })
      .then(({data}) => {
        setIsLoading(false);
        showModal(ModalType.ThanksModal);
        onSuccess?.();
        const storedEmail = data['email'] || ''
        const storedWallet = data['walletAddress'] || ''
        const storedDiscordId = data['discordId'] || ''
        storedEmail
          ? localStorage.setItem("wait_list_email", storedEmail)
          : localStorage.removeItem("wait_list_email")

        storedWallet
          ? localStorage.setItem("wait_list_wallet", storedWallet)
          : localStorage.removeItem("wait_list_wallet")

        storedDiscordId
          ? localStorage.setItem("wait_list_discord", storedDiscordId)
          : localStorage.removeItem("wait_list_discord")

      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(`${error.response.data.message}`);
        Sentry.captureException(error);
      });
  };

  return { joinWaitlist, isLoading, error };
}
