import React, { useState } from "react";

export function Collapsable({ renderHeader, renderBody, className }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={className}>
      {renderHeader({ isCollapsed, handleToggleCollapse })}
      {renderBody({isCollapsed})}
    </div>
  );
}
