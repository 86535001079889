import { ethers } from 'ethers';
import React, {useCallback, useEffect} from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/img/icons/close.svg';
import { ReactComponent as ConnectSvg } from 'src/assets/img/icons/connect.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/img/icons/error.svg';
import { ReactComponent as HelpIcon } from 'src/assets/img/icons/help.svg';
import { useModal } from '../../contexts/ModalContext';
import { AmountInput } from '../../pages/SolanaBridgePage/components/AmountInput';
import { TokenSelectControl } from '../../pages/SolanaBridgePage/components/TokenSelectControl';
import { TooltipContainer } from '../Tooltip';
import { Button } from '../lib';
import {Checkbox} from "../lib/Checkbox";

export function OptimismModal({
  onOpen,
  depositAMount,
  setDepositAmount,
  tokenData,
  chainIndex,
  tokenIndex,
  tokenBalance,
  totalValue,
  convertMaxLPTokenToMaxToken,
  depositAssetsList,
  openSelectTokenModal,
  openTransactionStatusModal,
  title,
  label,
  buttonText,
  feeText,
  error,
  isDeposit,
  feeValue = 0,
  termsAgreement,
  riskAgreement,
  setTermsAgreement,
  setRiskAgreement,
  isLpToken
}) {
  const { closeModal } = useModal();

  useEffect(() => {
    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const handleMaxClick = useCallback(() => {
    if (isDeposit) {
      setDepositAmount(tokenBalance);
    } else {
      if (isLpToken){
        setDepositAmount(tokenBalance);
      } else {
        const tokenAmount = convertMaxLPTokenToMaxToken();
        setDepositAmount(tokenAmount);
      }
    }
  }, [isDeposit, isLpToken, tokenBalance]);

  const disabledButton = () => {
    const isValidAmount = !depositAMount || !parseFloat(depositAMount)
    if (isDeposit) {
      return (
        !termsAgreement ||
        !riskAgreement ||
        isValidAmount ||
        parseFloat(depositAMount) > parseFloat(tokenBalance)
      );
    } else {
      if (isLpToken) {
        return (
          isValidAmount || parseFloat(depositAMount) > parseFloat(tokenBalance)
        )
      } else {
        return (
          isValidAmount || parseFloat(depositAMount) > parseFloat(convertMaxLPTokenToMaxToken())
        );
      }
    }
  };

  const getRate = () => {
    const currentTokenAddress =
      tokenData[chainIndex].tokens[tokenIndex].tokenAddress;

    const currentToken = depositAssetsList?.find(
      (depositToken) => depositToken.asset === currentTokenAddress
    );

    if (!currentToken) {
      return null
    }

    const rate = currentToken.rate;
    return ethers.utils.formatUnits(rate, 18);
  };

  const getTokenBalance = () => {
    const rate = parseFloat(getRate());
    const tokenBalance = totalValue / rate;
    return tokenBalance.toFixed(6);
  };

  return (
    <div className="fixed top-0 left-0 z-20 w-full h-full animate-fadeIn bg-grey-black43">
      <div className="absolute bg-texture bg-cover bg-white dark:bg-grey-dark lg:bg-none w-full lg:w-auto h-full lg:h-auto pt-[81px] lg:pt-[40px] pb-[24px] px-[24px] top-0 lg:top-[171px] left-0 lg:left-1/2 lg:-translate-x-1/2 lg:rounded-[8px]">
        <h3 className="text-[32px] text-grey-dark dark:text-white">{title}</h3>
        <button
          onClick={closeModal}
          className="w-[12px] h-[12px] absolute top-[49px] lg:top-[22px] right-[30px] flex justify-center items-center bg-none border-none"
        >
          <CloseIcon className="stroke-grey-black dark:stroke-grey-deep" />
        </button>
        <div className="flex flex-col gap-[8px] mt-[29px] lg:w-[444px]">
          <p className="font-caption text-[18px] leading-[1.46] text-grey-dark dark:text-white">
            {label}
          </p>
          <div className="flex items-center gap-[8px]">
            <div className="relative">
              <AmountInput
                value={depositAMount}
                setValue={setDepositAmount}
                className="w-[240px] md:w-[253px] h-[54px]"
              />
              <button
                onClick={handleMaxClick}
                className="absolute top-[15px] right-[8px] flex justify-center items-center w-[46px] h-[24px] bg-yellow-mild rounded-[4px] font-medium text-[14px] text-yellow-dark dark:bg-grey-darker5"
              >
                Max
              </button>
            </div>
            <TokenSelectControl
              className="w-[80px] md:w-[184px] h-[54px] dark:hover:bg-grey-dark dark:hover:border-yellow-dark"
              hideText={true}
              tokenSymbol={tokenData[chainIndex].tokens[tokenIndex].tokenSymbol}
              tokenImageId={
                tokenData[chainIndex].tokens[tokenIndex].tokenImageId
              }
              chainName={tokenData[chainIndex].chainName}
              chainImageName={tokenData[chainIndex].chainImageName}
              onClick={openSelectTokenModal}
            />
          </div>
          {error && (
            <div className="flex items-center gap-[4px]">
              <ErrorIcon className="w-[15px] h-[15px]" />
              <p className="font-caption text-[12px] leading-[15px] uppercase text-red-light">
                Error Message
              </p>
            </div>
          )}
          <div className="flex justify-between text-[12px] leading-[15px] text-grey-deep">
            {isDeposit ? (
              <p>
                You have {tokenBalance}{' '}
                {tokenData[chainIndex].tokens[tokenIndex].tokenSymbol}
              </p>
            ) : (
              <p>
                You have {isLpToken ? tokenBalance : getTokenBalance()}{' '}
                {tokenData[chainIndex].tokens[tokenIndex].tokenSymbol}
              </p>
            )}
            <p>
              1 {tokenData[chainIndex].tokens[tokenIndex].tokenSymbol} ={' '}
              {isLpToken ? '1' : getRate()} USD
            </p>
          </div>
          {isDeposit && (
            <div className="w-full mt-[32px] flex flex-col gap-[8px]">
              <div className="flex items-center gap-[10px]">
                <Checkbox value={termsAgreement} setValue={setTermsAgreement}/>
                <p className="font-normal text-[16px] leading-[19.2px] text-grey-deep">
                  By depositing into the vault, I agree to One Click Crypto
                  <a className="text-yellow-deep cursor-pointer" href="/vault-terms-of-use" target="_blank">{" "}Terms of Use</a>
                </p>
              </div>
              <div className="flex items-center gap-[10px]">
                <Checkbox value={riskAgreement} setValue={setRiskAgreement}/>
                <p className="font-normal text-[16px] leading-[19.2px] text-grey-deep">
                  I acknowledge the risks related to DeFi and cryptocurrencies, such as smart contract risks, volatility
                  risks, and liquidity risks. Read the full risk disclosure in
                  <a className="text-yellow-deep cursor-pointer" href="/vault-terms-of-use" target="_blank">{" "}Terms of Use</a>
                </p>
              </div>
            </div>
          )}
          <div className="mt-[32px] w-full">
            <Button
              type={3}
              className="mx-auto w-full disabled:bg-grey-lightest disabled:pointer-events-none disabled:border-grey-deep"
              disabled={disabledButton()}
              onClick={openTransactionStatusModal}
            >
              <ConnectSvg />
              {buttonText}
            </Button>

            <div className="flex justify-between mt-[16px] text-[12px] leading-[15px] text-grey-deep">
              <div className="flex w-fit items-center">
                <p>{feeText}</p>
                {feeText === 'Withdrawal Fee' && (
                  <TooltipContainer
                    tooltipContent={`Approximate amount of withdrawal assets. Slippage Tolerance is up to 1%. Minimum received ${
                      depositAMount * (1 - 0.01)
                    } ${tokenData[chainIndex].tokens[tokenIndex].tokenSymbol}`}
                    tooltipClassName=" !top-[25px]"
                  >
                    <HelpIcon className="w-[13px] h-[13px] ml-[4px] cursor-pointer" />
                  </TooltipContainer>
                )}
              </div>

              <p className="text-grey-black dark:text-white">{feeValue}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
