import React from 'react';
import { ReactComponent as Logo } from 'src/assets/img/icons/logo.svg';
import { ReactComponent as Risk } from 'src/assets/img/icons/risk.svg';
import { ReactComponent as LowRisk } from 'src/assets/img/icons/low-risk.svg';
import { ReactComponent as Optimism } from 'src/assets/img/optimism.svg';
import { ReactComponent as Stable } from 'src/assets/img/stable.svg';
import CrosschainLogo from 'src/assets/img/crosschain.png'
import { numberToLocaleString } from 'src/utils/helpers';

export function TitleSection({ tvlValue, isOptimism, title, isLowRisk, isStable }) {
  return (
    <div className="flex gap-[14px] relative">
      <div className="w-[56px] h-[56px] relative">
        {isOptimism && !isStable && <Optimism className="w-full h-full"/>}
        {isStable && <Stable className="w-full h-full"/>}
        {!isOptimism && <img className="w-[56px] h-[56px] rounded-full" src={CrosschainLogo} alt="logo"/>}
        <Logo className="absolute top-[34px] right-[2px] w-[23px] h-[25px]" />
      </div>
      <div className="flex flex-col gap-[8px]">
        {isOptimism && <h3 className="font-medium text-[32px] leading-[35.2px] text-grey-dark dark:text-white">
          {title}
        </h3>}
        {!isOptimism && (
          <div className="flex">
            <h3 className="font-medium text-[32px] leading-[35.2px] text-grey-dark dark:text-white max-w-[80%]">
              Crosschain Yield Index
            </h3>
            <p className="absolute right-[40px] top-[10px] flex items-center justify-center rounded-[4px] w-[52px] h-[22px] bg-yellow-dark-20 text-yellow-dark text-[16px] leading-[15px] font-normal">
              BETA
            </p>
          </div>
        )}
        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <p className="font-medium text-[14px] leading-[15.4px] mt-[2px] text-grey-deep">
              TVL
            </p>
            {tvlValue && (
              <p className="font-caption font-medium text-[14px] leading-[20.44px] text-grey-dark dark:text-white">
                ${numberToLocaleString(tvlValue)}
              </p>
            )}
          </div>
          <div className="flex items-center gap-[6px]">
            <p className="font-medium text-[12px] leading-[13.2px] text-grey-deep">
              Risk
            </p>
            {
              isLowRisk ? (
                <>
                  <LowRisk/>
                  <p className="font-medium text-[16px] leading-[17.6px] text-green-lighter1">
                    Lowest
                  </p>
                </>
              ) : (
                <>
                  <Risk/>
                  <p className="font-medium text-[16px] leading-[17.6px] text-yellow-deep">
                    Moderate
                  </p>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
