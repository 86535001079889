import {useEffect} from 'react'
import { JoinModal, ThanksModal } from "src/components/modals";
import {ModalType} from 'src/constants/enums'
import {useModal} from 'src/contexts/ModalContext'

export function JoinPage() {
  const { modalType, showModal } = useModal()

  useEffect(() => {
    showModal(ModalType.JoinModal)
  }, [])

  return (
    <>
      { modalType === ModalType.ThanksModal && <ThanksModal isJoinPage /> }
      { modalType === ModalType.JoinModal && <JoinModal /> }
    </>
  );
}
