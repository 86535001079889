import { TooltipContainer } from "src/components/Tooltip";
import { ExponentialToolTip } from "./ExponentialToolTip";
import { RatingToolTip } from "./RatingToolTip";
import {ratingScaleRedefine} from "../../../constants/ratingScaleRedefine";

export function RatingTag({
  provider,
  score,
  rating,
  link,
  entity = "pool",
  showProviderName = false,
  tooltipClassName = "",
  tooltipBeforeClassName = "",
}) {
  // status: high, medium, low
  const status =
    score / provider.max > 0.6
      ? "high"
      : score / provider.max > 0.4
      ? "medium"
      : "low";

  const isExponential = provider.name === "Exponential";
  const isRedefine = provider.name === "Redefine";
  score = isRedefine ? Math.round(score) : score
  const redefineParams = isRedefine && score in ratingScaleRedefine ? ratingScaleRedefine[score] : null
  if (isRedefine && !redefineParams) {
    return null
  }

  const content = (
    <div
      className={`flex items-center rounded-[8px] p-[4px] gap-[3px] transition-all duration-300 cursor-pointer ${
        status === "high"
          ? "bg-green-lighter2 bg-opacity-[0.15] hover:bg-opacity-[0.7] text-green-lighter1"
          : status === "medium"
          ? "bg-yellow-lighter3 bg-opacity-[0.3] hover:bg-opacity-[0.7] text-yellow-deep"
          : "bg-red-light bg-opacity-[0.1] hover:bg-opacity-[0.35] text-red-light"
      }`}
    >
      <img
        src={`/images/indexers/Logo-${provider.logoId}.png`}
        alt="Indexer"
        className="w-[20px] h-[20px] border-[0.59px] border-grey-lightest border-opacity-50 rounded-full object-cover"
      />
      {showProviderName && (
        <p className="text-[10px] leading-[1.46] text-grey-dark dark:text-white">
          {provider.name}
        </p>
      )}
      <div className="min-w-[29px] h-[22px] font-normal leading-[15px] flex items-center justify-center">
        {isRedefine ? redefineParams.title : rating || score}
      </div>
    </div>
  );
  return isExponential ? (
    <TooltipContainer
      tooltipClassName={tooltipClassName}
      tooltipBeforeClassName={tooltipBeforeClassName}
      tooltipContent={
        <ExponentialToolTip
          provider={provider}
          rating={rating}
          score={score}
          link={link}
          entity={entity}
        />
      }
    >
      {content}
    </TooltipContainer>
  ) : (
    <TooltipContainer
      tooltipContent={
        <RatingToolTip
          entity={entity}
          provider={provider}
          score={score}
          link={link}
        />
      }
    >
      {content}
    </TooltipContainer>
  );
}
