import axios from "axios";
import { useEffect, useState } from "react";

export function useSimilarPools({ poolId, initialPools, offset = 0 }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialPools) setData(initialPools);
  }, [initialPools]);

  useEffect(() => {
    if (!offset) return;

    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/pool/similar/${poolId}?offset=${offset}`
      )
      .then((response) => {
        setData((prev) => [...prev, ...response.data]);
      })
      .finally((e) => {
        setIsLoading(false);
      });
  }, [offset, poolId]);

  return { data, isLoading };
}
