import { useMemo } from "react";
import { DeFiChart } from "./DeFiChart";

export function RiskScoreChart({
  score,
  max = 10,
  small = false,
  label = "",
  bgNone = false,
  disableAnimation = false,
  className = "",
  scoreClassName = "",
  providerLogoIds = [],
  style,
  provider = {},
}) {
  const isRedefine = provider.name === "Redefine";

  const bgRedefine = score / max >= 0.6 ? "#E31818" : score / max >= 0.4 ? "#F0B93A" : "#16D07B60";
  const bg = score / max >= 0.6 ? "#16D07B60" : score / max >= 0.4 ? "#F0B93A" : "#E31818";
  const bgColor = isRedefine ? bgRedefine : bg;

  const colorRedefine = score / max >= 0.6 ? "#E31818" : score / max >= 0.4 ? "#F0B93A" : "#32CC86";
  const color = score / max >= 0.6 ? "#32CC86" : score / max >= 0.4 ? "#F0B93A" : "#E31818";
  const textColor = isRedefine ? colorRedefine : color;

  const chartData = useMemo(() => {
    return {
      labels: ["score", ""],
      datasets: [
        {
          label: "Risk Score",
          data: [score, max - score],
          backgroundColor: [bgColor, "#00000000"],
        },
      ],
    };
  }, [score, max, bgColor]);

  return (
    <div
      className={`relative rounded-full transition-all duration-300 ${className}`}
      style={style}
    >
      <div
        className={`w-full h-full ${
          bgNone ? "" : "bg-white dark:bg-grey-white5"
        } rounded-full`}
      >
        <DeFiChart
          data={chartData}
          cutout="83%"
          radius="97%"
          borderRadius={5}
          borderWidth={0}
          gap={0}
          darkenOnHover={!small}
          disableAnimation={disableAnimation}
        />
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col">
        <p
          className={`font-caption ${small ? "text-[16px]" : "text-[84px]"} ${
            !small && label ? "mt-[-10px]" : ""
          } leading-[1.1] tracking-[-0.085em] text-center ${scoreClassName}`}
          style={{
            color: textColor,
          }}
        >
          {score}
        </p>
        {label && (
          <p className="mt-[7px] font-normal text-[12px] leading-[1.25] text-center text-grey-deep">
            {label}
          </p>
        )}
        {!small && !label && (
          <>
            <p className="font-normal text-[12px] leading-[1.25] text-center text-grey-deep mt-[2px]">
              Rated By
            </p>
            <div className="flex items-center gap-[4px] mx-auto mt-[4px]">
              {providerLogoIds.map((logoId) => (
                <img
                  src={`/images/indexers/Logo-${logoId}.png`}
                  alt="Indexer"
                  className="w-[24px] h-[24px] object-cover"
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
