import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { PeriodTabsState } from 'src/constants/enums';
import {optimismPoolAddress} from 'src/constants/optimism';
import {
  PERFORMANCE_QUERY,
  TOTAL_POOL_VALUE_QUERY,
} from 'src/pages/Vault/query';
import {
  calculateApyForOptimism,
  calculateTvlPercentageChange,
  formatTVLData,
} from 'src/utils/helpers';

const OptimismContext = createContext();
OptimismContext.displayName = 'Optimism Context';

export function OptimismProvider({ children }) {
  const [tvlPeriod, setTvlPeriod] = useState(PeriodTabsState.all);
  const [performancePeriod, setPerformancePeriod] = useState(
    PeriodTabsState.all
  );

  const startOfYear = new Date(
    Date.UTC(new Date().getFullYear(), 0, 1)
  ).getTime();

  const { data: tvlData } = useQuery(TOTAL_POOL_VALUE_QUERY, {
    variables: { fundAddress: optimismPoolAddress, period: tvlPeriod },
  });

  const { data: performanceData } = useQuery(PERFORMANCE_QUERY, {
    variables: { fundAddress: optimismPoolAddress, period: performancePeriod },
  });

  const { data: performanceOneDayData } = useQuery(PERFORMANCE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.oneDay,
    },
  });
  const { data: performanceOneWeekData } = useQuery(PERFORMANCE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.sevenDays,
    },
  });
  const { data: performanceOneMonthData } = useQuery(PERFORMANCE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.oneMonth,
    },
  });
  const { data: performanceThreeMonthData } = useQuery(PERFORMANCE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.threeMonths,
    },
  });
  const { data: performanceOneYearData } = useQuery(PERFORMANCE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.oneYear,
    },
  });

  const { data: performanceAllData } = useQuery(PERFORMANCE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.all,
    },
  });

  const { data: tvlOneDayData } = useQuery(TOTAL_POOL_VALUE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.oneDay,
    },
  });
  const { data: tvlOneWeekData } = useQuery(TOTAL_POOL_VALUE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.sevenDays,
    },
  });
  const { data: tvlOneMonthData } = useQuery(TOTAL_POOL_VALUE_QUERY, {
    variables: {
      fundAddress: optimismPoolAddress,
      period: PeriodTabsState.oneMonth,
    },
  });

  const performanceApyChangeData = useMemo(() => {
    return [
      {
        period: '1D',
        value: calculateApyForOptimism(
          performanceOneDayData?.tokenPriceHistory?.history || []
        ),
      },
      {
        period: '7D',
        value: calculateApyForOptimism(
          performanceOneWeekData?.tokenPriceHistory?.history || []
        ),
      },
      {
        period: '30D',
        value: calculateApyForOptimism(
          performanceOneMonthData?.tokenPriceHistory?.history || []
        ),
      },
      {
        period: '90D',
        value: calculateApyForOptimism(performanceThreeMonthData?.tokenPriceHistory?.history || [])
      },
      {
        period: 'YTD',
        value: calculateApyForOptimism(
          performanceOneYearData?.tokenPriceHistory?.history.filter(
            (el) => +el?.timestamp > startOfYear
          )
        ) ?? []
      },
      {
        period: 'ALL',
        value: calculateApyForOptimism(
          performanceAllData?.tokenPriceHistory?.history || [],
        ),
      },
    ];
  }, [
    performanceOneDayData,
    performanceOneWeekData,
    performanceOneMonthData,
    performanceThreeMonthData,
    performanceOneYearData,
    performanceAllData,
    startOfYear
  ]);

  const tvlPercentageChangeData = useMemo(() => {
    return [
      {
        period: '24H',
        value: calculateTvlPercentageChange(
          tvlOneDayData?.fundTvlHistory?.history || []
        ),
      },
      {
        period: '7D',
        value: calculateTvlPercentageChange(
          tvlOneWeekData?.fundTvlHistory?.history || []
        ),
      },
      {
        period: '30D',
        value: calculateTvlPercentageChange(
          tvlOneMonthData?.fundTvlHistory?.history || []
        ),
      },
    ];
  }, [tvlOneDayData, tvlOneWeekData, tvlOneMonthData]);

  const tvlValue = formatTVLData(tvlData);

  const performance = useMemo(() => {
    if (performancePeriod === PeriodTabsState.oneYear) {
      return performanceOneYearData?.tokenPriceHistory?.history.filter(
        (el) => +el?.timestamp > startOfYear
      )
    }

    return performanceData?.tokenPriceHistory?.history
  }, [performanceData, performanceOneYearData, performancePeriod, startOfYear])

  const value = {
    tvlPeriod,
    setTvlPeriod,
    performancePeriod,
    setPerformancePeriod,
    tvlData: tvlData?.fundTvlHistory?.history || [],
    performanceData: performance || [],
    tvlValue,
    performanceApyChangeData,
    tvlPercentageChangeData,
  };

  return (
    <OptimismContext.Provider value={value}>
      {children}
    </OptimismContext.Provider>
  );
}

export const useOptimismValues = () => {
  return useContext(OptimismContext);
};
