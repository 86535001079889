export const ratingScaleExponential = {
    A: {
        title: 'Lowest risk',
        description: 'Lowest risk of losing your investment with the highest security and risk management standards in DeFi.',
        color: 'text-green-lighter1',
        opacityColor: 'text-green-lighter1-50%',
    },
    B: {
        title: 'Low risk',
        description: 'Low risk of losing your investment with good security or risk management practices in place.',
        color: 'text-green-lighter1',
        opacityColor: 'text-green-lighter1-50%',
    },
    C: {
        title: 'Moderate risk',
        description: 'Moderate risk of losing your investment due to potential security and risk management issues.',
        color: 'text-yellow-dark',
        opacityColor: 'text-yellow-dark-50%',

    },
    D: {
        title: 'High risk',
        description: 'High risk of losing your investment due to poor security and risk management.',
        color: 'text-red-light',
        opacityColor: 'text-red-light-50%',
    },
    F: {
        title: 'Very high risk',
        description: 'The highest risk of losing your investment. Major gaps in security or risk management identified.',
        color: 'text-red-light',
        opacityColor: 'text-red-light-50%',
    },
}
