import React from 'react';

export function TabItem({title, value, isActive, setActive}) {
  return (
    <p
      onClick={() => setActive(value)}
      className={`py-[7px] px-[8px] rounded-[8px] cursor-pointer transition-all duration-300 ${isActive ? "bg-white dark:bg-grey-dark dark:text-yellow-dark" : "dark:text-white"}`}
    >
      {title}
    </p>
  );
}
