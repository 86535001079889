import {ratingScaleExponential} from "src/constants/ratingScaleExponential"

export function ExponentialToolTip({
  provider,
  score,
  rating,
  link,
  entity
}) {
  const ratingParams = rating in ratingScaleExponential ? ratingScaleExponential[rating] : null;
  if (!ratingParams) {
    return null
  }

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex items-center">
        <img
          src={`/images/indexers/Logo-${provider.logoId}.png`}
          alt="Indexer"
          className="w-[20px] h-[20px] border-[0.59px] border-grey-lightest border-opacity-50 rounded-full object-cover"
        />
        <p className="text-[10px] ml-[2px] leading-[1.46] text-grey-dark dark:text-white">
          {provider.name}
        </p>
        <div
          className={`ml-auto font-normal leading-[15px] flex items-center justify-center ${ratingParams.color}`}
        >
          {rating || score}
        </div>
      </div>
      <p className="font-normal text-[12px] leading-[1.25]">
        This {entity} was rated by Exponential and received a "{rating}" score from
        <span className="text-green-lighter1 ml-[2px]">A</span>
        <span className="text-green-lighter1 ml-[2px]">B</span>
        <span className="text-yellow-dark ml-[2px]">C</span>
        <span className="text-red-light ml-[2px]">D</span>
        <span className="text-red-light ml-[2px]">F</span>.
        According to Exponential, it means "{ratingParams.title}".
      </p>
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="font-normal text-[12px] leading-[1.25] text-yellow-dark hover:text-yellow-deep underline"
        >
          Assessment link
        </a>
      )}
    </div>
  );
}
