export const optimismPoolAddress = '0x087d22d2a040c422932b86e8fd30084ecef8aa89';
export const optimismStablePoolAddress = '0x597457006ab0bebf296436c40b04705d165ac4ce';
export const routerAddress = '0x3988513793bce39f0167064a9f7fc3617faf35ab';

export const wbtcAddress = '0x68f180fcCe6836688e9084f035309E29Bf0A2095';
export const USDTAddress = '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58'
export const USDCAddress = '0x7F5c764cBc14f9669B88837ca1490cCa17c31607'
export const ETHAddress = '0x4200000000000000000000000000000000000006'
export const SNXAddress = '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85'
export const WETHAddress = '0x4200000000000000000000000000000000000006'
export const ULPAddress = '0xC36442b4a4522E871399CD717aBDD847Ab11FE88'
export const DAIAddress = '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'

export const zapperUrl = 'https://api.zapper.xyz/v2/apps/uniswap-v3/balances?api_key=c79a70c9-825b-4fda-9038-701bafc72417&addresses[]=0x087d22d2a040c422932b86e8fd30084ecef8aa89&network=optimism'
export const zapperStableUrl = 'https://api.zapper.xyz/v2/apps/uniswap-v3/balances?api_key=c79a70c9-825b-4fda-9038-701bafc72417&addresses[]=0x597457006ab0bebf296436c40b04705d165ac4ce&network=optimism'
export const UsdcOpId = '481953'
export const DaiWethId = '481946'

export const transactionsLimit = 10;
export const transactionsLimitMobile = 3;

export const EXPECTED_CAPACITY = 100000;

export const HUNDRED_PERCENTS = 100;

export const optimismChainId = 10;

export const optimismRpc = 'https://mainnet.optimism.io';

export const walletPrivateKey =
  '9a62a3b1a67923b8ee7786ccb7cb912a254a0c0563f0f056397a46294f0c13b1';

export const optimismScanLink = 'https://optimistic.etherscan.io/';

export const optimismConfig = {
  chainId: optimismChainId,
  chainName: 'OP Mainnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: [optimismRpc],
  blockExplorerUrls: [optimismScanLink],
};

export const dayInMilliseconds = 86_400_000;
export const daysInYear = 365
export const minimumPercentageForChart = 1.5
export const depositTokenData = [
  {
    chainName: 'Optimism',
    chainSymbol: 'OPT',
    chainId: 10,
    chainImageName: 'Optimism',
    tokens: [
      {
        tokenSymbol: 'USDT',
        tokenAddress: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        tokenImageId: '27',
        decimals: 6,
      },
      {
        tokenSymbol: 'USDC.e',
        tokenAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        tokenImageId: '15',
        decimals: 6,
      },
      {
        tokenSymbol: 'ETH',
        tokenAddress: '0x4200000000000000000000000000000000000006',
        tokenImageId: '87',
        decimals: 18,
      },
    ],
  },
];

export const optimismStabledepositTokenData = [
  {
    chainName: 'Optimism',
    chainSymbol: 'OPT',
    chainId: 10,
    chainImageName: 'Optimism',
    tokens: [
      {
        tokenSymbol: 'USDC.e',
        tokenAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        tokenImageId: '15',
        decimals: 6,
      }
    ],
  },
];

export const optimismStableDepositTokensAddresses = ['0x7F5c764cBc14f9669B88837ca1490cCa17c31607']
